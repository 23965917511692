export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: number; output: number; }
  bytea: { input: any; output: any; }
  citext: { input: any; output: any; }
  date: { input: string; output: string; }
  jsonb: { input: any; output: any; }
  numeric: { input: number; output: number; }
  timestamp: { input: string; output: string; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequests = {
  __typename?: 'authProviderRequests';
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
};


/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequestsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate = {
  __typename?: 'authProviderRequests_aggregate';
  aggregate?: Maybe<AuthProviderRequests_Aggregate_Fields>;
  nodes: Array<AuthProviderRequests>;
};

/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate_Fields = {
  __typename?: 'authProviderRequests_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviderRequests_Max_Fields>;
  min?: Maybe<AuthProviderRequests_Min_Fields>;
};


/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequests_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
export type AuthProviderRequests_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProviderRequests_Bool_Exp>>;
  _not?: InputMaybe<AuthProviderRequests_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProviderRequests_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.provider_requests" */
export enum AuthProviderRequests_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProviderRequestsPkey = 'provider_requests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthProviderRequests_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthProviderRequests_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthProviderRequests_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.provider_requests" */
export type AuthProviderRequests_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type AuthProviderRequests_Max_Fields = {
  __typename?: 'authProviderRequests_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthProviderRequests_Min_Fields = {
  __typename?: 'authProviderRequests_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.provider_requests" */
export type AuthProviderRequests_Mutation_Response = {
  __typename?: 'authProviderRequests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviderRequests>;
};

/** on_conflict condition type for table "auth.provider_requests" */
export type AuthProviderRequests_On_Conflict = {
  constraint: AuthProviderRequests_Constraint;
  update_columns?: Array<AuthProviderRequests_Update_Column>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.provider_requests". */
export type AuthProviderRequests_Order_By = {
  id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.provider_requests */
export type AuthProviderRequests_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequests_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.provider_requests" */
export enum AuthProviderRequests_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

/** input type for updating data in table "auth.provider_requests" */
export type AuthProviderRequests_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "authProviderRequests" */
export type AuthProviderRequests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProviderRequests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviderRequests_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "auth.provider_requests" */
export enum AuthProviderRequests_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

export type AuthProviderRequests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProviderRequests_Bool_Exp;
};

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviders = {
  __typename?: 'authProviders';
  id: Scalars['String']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProviders_Aggregate;
};


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type AuthProviders_Aggregate = {
  __typename?: 'authProviders_aggregate';
  aggregate?: Maybe<AuthProviders_Aggregate_Fields>;
  nodes: Array<AuthProviders>;
};

/** aggregate fields of "auth.providers" */
export type AuthProviders_Aggregate_Fields = {
  __typename?: 'authProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviders_Max_Fields>;
  min?: Maybe<AuthProviders_Min_Fields>;
};


/** aggregate fields of "auth.providers" */
export type AuthProviders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type AuthProviders_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProviders_Bool_Exp>>;
  _not?: InputMaybe<AuthProviders_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProviders_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  userProviders?: InputMaybe<AuthUserProviders_Bool_Exp>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProviders_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type AuthProviders_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<AuthUserProviders_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AuthProviders_Max_Fields = {
  __typename?: 'authProviders_max_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthProviders_Min_Fields = {
  __typename?: 'authProviders_min_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.providers" */
export type AuthProviders_Mutation_Response = {
  __typename?: 'authProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviders>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type AuthProviders_Obj_Rel_Insert_Input = {
  data: AuthProviders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};

/** on_conflict condition type for table "auth.providers" */
export type AuthProviders_On_Conflict = {
  constraint: AuthProviders_Constraint;
  update_columns?: Array<AuthProviders_Update_Column>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type AuthProviders_Order_By = {
  id?: InputMaybe<Order_By>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.providers */
export type AuthProviders_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "auth.providers" */
export enum AuthProviders_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.providers" */
export type AuthProviders_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authProviders" */
export type AuthProviders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProviders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviders_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.providers" */
export enum AuthProviders_Update_Column {
  /** column name */
  Id = 'id'
}

export type AuthProviders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviders_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProviders_Bool_Exp;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes = {
  __typename?: 'authRefreshTokenTypes';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokens_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** aggregated selection of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate = {
  __typename?: 'authRefreshTokenTypes_aggregate';
  aggregate?: Maybe<AuthRefreshTokenTypes_Aggregate_Fields>;
  nodes: Array<AuthRefreshTokenTypes>;
};

/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate_Fields = {
  __typename?: 'authRefreshTokenTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokenTypes_Max_Fields>;
  min?: Maybe<AuthRefreshTokenTypes_Min_Fields>;
};


/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokenTypes_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokenTypes_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokenTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey'
}

export enum AuthRefreshTokenTypes_Enum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular'
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export type AuthRefreshTokenTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _in?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _nin?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
};

/** input type for inserting data into table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokenTypes_Max_Fields = {
  __typename?: 'authRefreshTokenTypes_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRefreshTokenTypes_Min_Fields = {
  __typename?: 'authRefreshTokenTypes_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Mutation_Response = {
  __typename?: 'authRefreshTokenTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokenTypes>;
};

/** on_conflict condition type for table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_On_Conflict = {
  constraint: AuthRefreshTokenTypes_Constraint;
  update_columns?: Array<AuthRefreshTokenTypes_Update_Column>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_token_types". */
export type AuthRefreshTokenTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_token_types */
export type AuthRefreshTokenTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRefreshTokenTypes" */
export type AuthRefreshTokenTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokenTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokenTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type AuthRefreshTokenTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokenTypes_Bool_Exp;
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokens = {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  type: AuthRefreshTokenTypes_Enum;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};


/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate = {
  __typename?: 'authRefreshTokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokens_Aggregate_Fields>;
  nodes: Array<AuthRefreshTokens>;
};

export type AuthRefreshTokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp_Count>;
};

export type AuthRefreshTokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Fields = {
  __typename?: 'authRefreshTokens_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokens_Max_Fields>;
  min?: Maybe<AuthRefreshTokens_Min_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthRefreshTokens_Max_Order_By>;
  min?: InputMaybe<AuthRefreshTokens_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokens_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type AuthRefreshTokens_Arr_Rel_Insert_Input = {
  data: Array<AuthRefreshTokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokens_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  refreshTokenHash?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthRefreshTokens_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthRefreshTokens_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthRefreshTokens_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.refresh_tokens" */
export type AuthRefreshTokens_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokens_Max_Fields = {
  __typename?: 'authRefreshTokens_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthRefreshTokens_Min_Fields = {
  __typename?: 'authRefreshTokens_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokens_Mutation_Response = {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
};

/** on_conflict condition type for table "auth.refresh_tokens" */
export type AuthRefreshTokens_On_Conflict = {
  constraint: AuthRefreshTokens_Constraint;
  update_columns?: Array<AuthRefreshTokens_Update_Column>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type AuthRefreshTokens_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_tokens */
export type AuthRefreshTokens_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokens_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type AuthRefreshTokens_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authRefreshTokens" */
export type AuthRefreshTokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokens_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type AuthRefreshTokens_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokens_Bool_Exp;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRoles = {
  __typename?: 'authRoles';
  role: Scalars['String']['output'];
  /** An array relationship */
  userRoles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  userRoles_aggregate: AuthUserRoles_Aggregate;
  /** An array relationship */
  usersByDefaultRole: Array<Users>;
  /** An aggregate relationship */
  usersByDefaultRole_aggregate: Users_Aggregate;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRoleArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type AuthRoles_Aggregate = {
  __typename?: 'authRoles_aggregate';
  aggregate?: Maybe<AuthRoles_Aggregate_Fields>;
  nodes: Array<AuthRoles>;
};

/** aggregate fields of "auth.roles" */
export type AuthRoles_Aggregate_Fields = {
  __typename?: 'authRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRoles_Max_Fields>;
  min?: Maybe<AuthRoles_Min_Fields>;
};


/** aggregate fields of "auth.roles" */
export type AuthRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type AuthRoles_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRoles_Bool_Exp>>;
  _not?: InputMaybe<AuthRoles_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRoles_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
  userRoles?: InputMaybe<AuthUserRoles_Bool_Exp>;
  userRoles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp>;
  usersByDefaultRole?: InputMaybe<Users_Bool_Exp>;
  usersByDefaultRole_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRoles_Constraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type AuthRoles_Insert_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<AuthUserRoles_Arr_Rel_Insert_Input>;
  usersByDefaultRole?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AuthRoles_Max_Fields = {
  __typename?: 'authRoles_max_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRoles_Min_Fields = {
  __typename?: 'authRoles_min_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.roles" */
export type AuthRoles_Mutation_Response = {
  __typename?: 'authRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRoles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type AuthRoles_Obj_Rel_Insert_Input = {
  data: AuthRoles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};

/** on_conflict condition type for table "auth.roles" */
export type AuthRoles_On_Conflict = {
  constraint: AuthRoles_Constraint;
  update_columns?: Array<AuthRoles_Update_Column>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type AuthRoles_Order_By = {
  role?: InputMaybe<Order_By>;
  userRoles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Order_By>;
  usersByDefaultRole_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.roles */
export type AuthRoles_Pk_Columns_Input = {
  role: Scalars['String']['input'];
};

/** select columns of table "auth.roles" */
export enum AuthRoles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type AuthRoles_Set_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRoles" */
export type AuthRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRoles_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.roles" */
export enum AuthRoles_Update_Column {
  /** column name */
  Role = 'role'
}

export type AuthRoles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRoles_Bool_Exp;
};

/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserProviders = {
  __typename?: 'authUserProviders';
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  provider: AuthProviders;
  providerId: Scalars['String']['output'];
  providerUserId: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_providers" */
export type AuthUserProviders_Aggregate = {
  __typename?: 'authUserProviders_aggregate';
  aggregate?: Maybe<AuthUserProviders_Aggregate_Fields>;
  nodes: Array<AuthUserProviders>;
};

export type AuthUserProviders_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp_Count>;
};

export type AuthUserProviders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserProviders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_providers" */
export type AuthUserProviders_Aggregate_Fields = {
  __typename?: 'authUserProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserProviders_Max_Fields>;
  min?: Maybe<AuthUserProviders_Min_Fields>;
};


/** aggregate fields of "auth.user_providers" */
export type AuthUserProviders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_providers" */
export type AuthUserProviders_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserProviders_Max_Order_By>;
  min?: InputMaybe<AuthUserProviders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_providers" */
export type AuthUserProviders_Arr_Rel_Insert_Input = {
  data: Array<AuthUserProviders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
export type AuthUserProviders_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserProviders_Bool_Exp>>;
  _not?: InputMaybe<AuthUserProviders_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserProviders_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<AuthProviders_Bool_Exp>;
  providerId?: InputMaybe<String_Comparison_Exp>;
  providerUserId?: InputMaybe<String_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_providers" */
export enum AuthUserProviders_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_id", "provider_user_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "provider_id", "user_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key'
}

/** input type for inserting data into table "auth.user_providers" */
export type AuthUserProviders_Insert_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<AuthProviders_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserProviders_Max_Fields = {
  __typename?: 'authUserProviders_max_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_providers" */
export type AuthUserProviders_Max_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserProviders_Min_Fields = {
  __typename?: 'authUserProviders_min_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_providers" */
export type AuthUserProviders_Min_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_providers" */
export type AuthUserProviders_Mutation_Response = {
  __typename?: 'authUserProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserProviders>;
};

/** on_conflict condition type for table "auth.user_providers" */
export type AuthUserProviders_On_Conflict = {
  constraint: AuthUserProviders_Constraint;
  update_columns?: Array<AuthUserProviders_Update_Column>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_providers". */
export type AuthUserProviders_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<AuthProviders_Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_providers */
export type AuthUserProviders_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_providers" */
export enum AuthUserProviders_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_providers" */
export type AuthUserProviders_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserProviders" */
export type AuthUserProviders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserProviders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserProviders_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_providers" */
export enum AuthUserProviders_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type AuthUserProviders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserProviders_Bool_Exp;
};

/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserRoles = {
  __typename?: 'authUserRoles';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  role: Scalars['String']['output'];
  /** An object relationship */
  roleByRole: AuthRoles;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_roles" */
export type AuthUserRoles_Aggregate = {
  __typename?: 'authUserRoles_aggregate';
  aggregate?: Maybe<AuthUserRoles_Aggregate_Fields>;
  nodes: Array<AuthUserRoles>;
};

export type AuthUserRoles_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp_Count>;
};

export type AuthUserRoles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserRoles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_roles" */
export type AuthUserRoles_Aggregate_Fields = {
  __typename?: 'authUserRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserRoles_Max_Fields>;
  min?: Maybe<AuthUserRoles_Min_Fields>;
};


/** aggregate fields of "auth.user_roles" */
export type AuthUserRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_roles" */
export type AuthUserRoles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserRoles_Max_Order_By>;
  min?: InputMaybe<AuthUserRoles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_roles" */
export type AuthUserRoles_Arr_Rel_Insert_Input = {
  data: Array<AuthUserRoles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
export type AuthUserRoles_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserRoles_Bool_Exp>>;
  _not?: InputMaybe<AuthUserRoles_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserRoles_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleByRole?: InputMaybe<AuthRoles_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_roles" */
export enum AuthUserRoles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key'
}

/** input type for inserting data into table "auth.user_roles" */
export type AuthUserRoles_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  roleByRole?: InputMaybe<AuthRoles_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserRoles_Max_Fields = {
  __typename?: 'authUserRoles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_roles" */
export type AuthUserRoles_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserRoles_Min_Fields = {
  __typename?: 'authUserRoles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_roles" */
export type AuthUserRoles_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_roles" */
export type AuthUserRoles_Mutation_Response = {
  __typename?: 'authUserRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserRoles>;
};

/** on_conflict condition type for table "auth.user_roles" */
export type AuthUserRoles_On_Conflict = {
  constraint: AuthUserRoles_Constraint;
  update_columns?: Array<AuthUserRoles_Update_Column>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_roles". */
export type AuthUserRoles_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<AuthRoles_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_roles */
export type AuthUserRoles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_roles" */
export enum AuthUserRoles_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_roles" */
export type AuthUserRoles_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserRoles" */
export type AuthUserRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserRoles_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_roles" */
export enum AuthUserRoles_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export type AuthUserRoles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserRoles_Bool_Exp;
};

/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserSecurityKeys = {
  __typename?: 'authUserSecurityKeys';
  counter: Scalars['bigint']['output'];
  credentialId: Scalars['String']['output'];
  credentialPublicKey?: Maybe<Scalars['bytea']['output']>;
  id: Scalars['uuid']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  transports: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate = {
  __typename?: 'authUserSecurityKeys_aggregate';
  aggregate?: Maybe<AuthUserSecurityKeys_Aggregate_Fields>;
  nodes: Array<AuthUserSecurityKeys>;
};

export type AuthUserSecurityKeys_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserSecurityKeys_Aggregate_Bool_Exp_Count>;
};

export type AuthUserSecurityKeys_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_Fields = {
  __typename?: 'authUserSecurityKeys_aggregate_fields';
  avg?: Maybe<AuthUserSecurityKeys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserSecurityKeys_Max_Fields>;
  min?: Maybe<AuthUserSecurityKeys_Min_Fields>;
  stddev?: Maybe<AuthUserSecurityKeys_Stddev_Fields>;
  stddev_pop?: Maybe<AuthUserSecurityKeys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuthUserSecurityKeys_Stddev_Samp_Fields>;
  sum?: Maybe<AuthUserSecurityKeys_Sum_Fields>;
  var_pop?: Maybe<AuthUserSecurityKeys_Var_Pop_Fields>;
  var_samp?: Maybe<AuthUserSecurityKeys_Var_Samp_Fields>;
  variance?: Maybe<AuthUserSecurityKeys_Variance_Fields>;
};


/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_Order_By = {
  avg?: InputMaybe<AuthUserSecurityKeys_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserSecurityKeys_Max_Order_By>;
  min?: InputMaybe<AuthUserSecurityKeys_Min_Order_By>;
  stddev?: InputMaybe<AuthUserSecurityKeys_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AuthUserSecurityKeys_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AuthUserSecurityKeys_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AuthUserSecurityKeys_Sum_Order_By>;
  var_pop?: InputMaybe<AuthUserSecurityKeys_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AuthUserSecurityKeys_Var_Samp_Order_By>;
  variance?: InputMaybe<AuthUserSecurityKeys_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Arr_Rel_Insert_Input = {
  data: Array<AuthUserSecurityKeys_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};

/** aggregate avg on columns */
export type AuthUserSecurityKeys_Avg_Fields = {
  __typename?: 'authUserSecurityKeys_avg_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Avg_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
export type AuthUserSecurityKeys_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserSecurityKeys_Bool_Exp>>;
  _not?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserSecurityKeys_Bool_Exp>>;
  counter?: InputMaybe<Bigint_Comparison_Exp>;
  credentialId?: InputMaybe<String_Comparison_Exp>;
  credentialPublicKey?: InputMaybe<Bytea_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  transports?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Constraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey'
}

/** input type for incrementing numeric columns in table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Inc_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Insert_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserSecurityKeys_Max_Fields = {
  __typename?: 'authUserSecurityKeys_max_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Max_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserSecurityKeys_Min_Fields = {
  __typename?: 'authUserSecurityKeys_min_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Min_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Mutation_Response = {
  __typename?: 'authUserSecurityKeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserSecurityKeys>;
};

/** on_conflict condition type for table "auth.user_security_keys" */
export type AuthUserSecurityKeys_On_Conflict = {
  constraint: AuthUserSecurityKeys_Constraint;
  update_columns?: Array<AuthUserSecurityKeys_Update_Column>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_security_keys". */
export type AuthUserSecurityKeys_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  credentialPublicKey?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_security_keys */
export type AuthUserSecurityKeys_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Select_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Set_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type AuthUserSecurityKeys_Stddev_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AuthUserSecurityKeys_Stddev_Pop_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Pop_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AuthUserSecurityKeys_Stddev_Samp_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Samp_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "authUserSecurityKeys" */
export type AuthUserSecurityKeys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserSecurityKeys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserSecurityKeys_Stream_Cursor_Value_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type AuthUserSecurityKeys_Sum_Fields = {
  __typename?: 'authUserSecurityKeys_sum_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Sum_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** update columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Update_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId'
}

export type AuthUserSecurityKeys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserSecurityKeys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuthUserSecurityKeys_Var_Pop_Fields = {
  __typename?: 'authUserSecurityKeys_var_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Var_Pop_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AuthUserSecurityKeys_Var_Samp_Fields = {
  __typename?: 'authUserSecurityKeys_var_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Var_Samp_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AuthUserSecurityKeys_Variance_Fields = {
  __typename?: 'authUserSecurityKeys_variance_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Variance_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** columns and relationships of "base_category" */
export type Base_Category = {
  __typename?: 'base_category';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "base_category" */
export type Base_Category_Aggregate = {
  __typename?: 'base_category_aggregate';
  aggregate?: Maybe<Base_Category_Aggregate_Fields>;
  nodes: Array<Base_Category>;
};

/** aggregate fields of "base_category" */
export type Base_Category_Aggregate_Fields = {
  __typename?: 'base_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Base_Category_Max_Fields>;
  min?: Maybe<Base_Category_Min_Fields>;
};


/** aggregate fields of "base_category" */
export type Base_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Base_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "base_category". All fields are combined with a logical 'AND'. */
export type Base_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Base_Category_Bool_Exp>>;
  _not?: InputMaybe<Base_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Base_Category_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_category" */
export enum Base_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  BaseCategoryPkey = 'base_category_pkey'
}

export enum Base_Category_Enum {
  Car = 'car',
  Transporter = 'transporter'
}

/** Boolean expression to compare columns of type "base_category_enum". All fields are combined with logical 'AND'. */
export type Base_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Base_Category_Enum>;
  _in?: InputMaybe<Array<Base_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Base_Category_Enum>;
  _nin?: InputMaybe<Array<Base_Category_Enum>>;
};

/** input type for inserting data into table "base_category" */
export type Base_Category_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Base_Category_Max_Fields = {
  __typename?: 'base_category_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Base_Category_Min_Fields = {
  __typename?: 'base_category_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "base_category" */
export type Base_Category_Mutation_Response = {
  __typename?: 'base_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Category>;
};

/** on_conflict condition type for table "base_category" */
export type Base_Category_On_Conflict = {
  constraint: Base_Category_Constraint;
  update_columns?: Array<Base_Category_Update_Column>;
  where?: InputMaybe<Base_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "base_category". */
export type Base_Category_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: base_category */
export type Base_Category_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "base_category" */
export enum Base_Category_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "base_category" */
export type Base_Category_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "base_category" */
export type Base_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Category_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "base_category" */
export enum Base_Category_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Base_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Base_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Category_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "storage.buckets" */
export type Buckets = {
  __typename?: 'buckets';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  downloadExpiration: Scalars['Int']['output'];
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  id: Scalars['String']['output'];
  maxUploadFileSize: Scalars['Int']['output'];
  minUploadFileSize: Scalars['Int']['output'];
  presignedUrlsEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "storage.buckets" */
export type BucketsFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "storage.buckets" */
export type BucketsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "storage.buckets" */
export type Buckets_Aggregate = {
  __typename?: 'buckets_aggregate';
  aggregate?: Maybe<Buckets_Aggregate_Fields>;
  nodes: Array<Buckets>;
};

/** aggregate fields of "storage.buckets" */
export type Buckets_Aggregate_Fields = {
  __typename?: 'buckets_aggregate_fields';
  avg?: Maybe<Buckets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Buckets_Max_Fields>;
  min?: Maybe<Buckets_Min_Fields>;
  stddev?: Maybe<Buckets_Stddev_Fields>;
  stddev_pop?: Maybe<Buckets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Buckets_Stddev_Samp_Fields>;
  sum?: Maybe<Buckets_Sum_Fields>;
  var_pop?: Maybe<Buckets_Var_Pop_Fields>;
  var_samp?: Maybe<Buckets_Var_Samp_Fields>;
  variance?: Maybe<Buckets_Variance_Fields>;
};


/** aggregate fields of "storage.buckets" */
export type Buckets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buckets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Buckets_Avg_Fields = {
  __typename?: 'buckets_avg_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
export type Buckets_Bool_Exp = {
  _and?: InputMaybe<Array<Buckets_Bool_Exp>>;
  _not?: InputMaybe<Buckets_Bool_Exp>;
  _or?: InputMaybe<Array<Buckets_Bool_Exp>>;
  cacheControl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  downloadExpiration?: InputMaybe<Int_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  maxUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  minUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  presignedUrlsEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage.buckets" */
export enum Buckets_Constraint {
  /** unique or primary key constraint on columns "id" */
  BucketsPkey = 'buckets_pkey'
}

/** input type for incrementing numeric columns in table "storage.buckets" */
export type Buckets_Inc_Input = {
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.buckets" */
export type Buckets_Insert_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Buckets_Max_Fields = {
  __typename?: 'buckets_max_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Buckets_Min_Fields = {
  __typename?: 'buckets_min_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "storage.buckets" */
export type Buckets_Mutation_Response = {
  __typename?: 'buckets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buckets>;
};

/** input type for inserting object relation for remote table "storage.buckets" */
export type Buckets_Obj_Rel_Insert_Input = {
  data: Buckets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};

/** on_conflict condition type for table "storage.buckets" */
export type Buckets_On_Conflict = {
  constraint: Buckets_Constraint;
  update_columns?: Array<Buckets_Update_Column>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.buckets". */
export type Buckets_Order_By = {
  cacheControl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadExpiration?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  maxUploadFileSize?: InputMaybe<Order_By>;
  minUploadFileSize?: InputMaybe<Order_By>;
  presignedUrlsEnabled?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage.buckets */
export type Buckets_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "storage.buckets" */
export enum Buckets_Select_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "storage.buckets" */
export type Buckets_Set_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Buckets_Stddev_Fields = {
  __typename?: 'buckets_stddev_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Buckets_Stddev_Pop_Fields = {
  __typename?: 'buckets_stddev_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Buckets_Stddev_Samp_Fields = {
  __typename?: 'buckets_stddev_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "buckets" */
export type Buckets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Buckets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buckets_Stream_Cursor_Value_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Buckets_Sum_Fields = {
  __typename?: 'buckets_sum_fields';
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "storage.buckets" */
export enum Buckets_Update_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Buckets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Buckets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buckets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buckets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Buckets_Var_Pop_Fields = {
  __typename?: 'buckets_var_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Buckets_Var_Samp_Fields = {
  __typename?: 'buckets_var_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Buckets_Variance_Fields = {
  __typename?: 'buckets_variance_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** columns and relationships of "contact" */
export type Contact = {
  __typename?: 'contact';
  address_city?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  address_country?: Maybe<Country>;
  address_country_id?: Maybe<Scalars['uuid']['output']>;
  address_street?: Maybe<Scalars['String']['output']>;
  address_zipcode?: Maybe<Scalars['String']['output']>;
  banned_at?: Maybe<Scalars['timestamptz']['output']>;
  banned_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  banned_by_user?: Maybe<Users>;
  banned_reason?: Maybe<Scalars['String']['output']>;
  contact_number?: Maybe<Scalars['String']['output']>;
  contact_type: Contact_Type_Enum;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  driver_license?: Maybe<Files>;
  email?: Maybe<Scalars['String']['output']>;
  from_booking_portal?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  is_banned?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  organization_commercial_register_number?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  organization_vat?: Maybe<Scalars['String']['output']>;
  person_dateofbirth?: Maybe<Scalars['date']['output']>;
  person_driver_license_file_id?: Maybe<Scalars['uuid']['output']>;
  person_driver_license_is_valid?: Maybe<Scalars['Boolean']['output']>;
  person_driver_license_issued_by?: Maybe<Scalars['String']['output']>;
  person_driver_license_issued_on?: Maybe<Scalars['date']['output']>;
  person_driver_license_number?: Maybe<Scalars['String']['output']>;
  person_driver_license_validated_at?: Maybe<Scalars['timestamptz']['output']>;
  person_driver_license_validated_by?: Maybe<Scalars['String']['output']>;
  person_firstname?: Maybe<Scalars['String']['output']>;
  person_lastname?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "contact_person_name" */
  person_name?: Maybe<Scalars['String']['output']>;
  person_placeofbirth?: Maybe<Scalars['String']['output']>;
  person_registration_certificate_file_id?: Maybe<Scalars['uuid']['output']>;
  person_registration_certificate_is_valid?: Maybe<Scalars['Boolean']['output']>;
  person_registration_certificate_validated_at?: Maybe<Scalars['timestamptz']['output']>;
  person_registration_certificate_validated_by?: Maybe<Scalars['String']['output']>;
  person_title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  registration_certificate?: Maybe<Files>;
  /** An array relationship */
  rental_contracts_primary: Array<Rental_Contract>;
  /** An aggregate relationship */
  rental_contracts_primary_aggregate: Rental_Contract_Aggregate;
  /** An array relationship */
  rental_contracts_secondary: Array<Rental_Contract>;
  /** An aggregate relationship */
  rental_contracts_secondary_aggregate: Rental_Contract_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "contact" */
export type ContactInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactRental_Contracts_PrimaryArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactRental_Contracts_Primary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactRental_Contracts_SecondaryArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


/** columns and relationships of "contact" */
export type ContactRental_Contracts_Secondary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};

/** aggregated selection of "contact" */
export type Contact_Aggregate = {
  __typename?: 'contact_aggregate';
  aggregate?: Maybe<Contact_Aggregate_Fields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type Contact_Aggregate_Fields = {
  __typename?: 'contact_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Max_Fields>;
  min?: Maybe<Contact_Min_Fields>;
};


/** aggregate fields of "contact" */
export type Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Bool_Exp>>;
  _not?: InputMaybe<Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Bool_Exp>>;
  address_city?: InputMaybe<String_Comparison_Exp>;
  address_country?: InputMaybe<Country_Bool_Exp>;
  address_country_id?: InputMaybe<Uuid_Comparison_Exp>;
  address_street?: InputMaybe<String_Comparison_Exp>;
  address_zipcode?: InputMaybe<String_Comparison_Exp>;
  banned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  banned_by?: InputMaybe<Uuid_Comparison_Exp>;
  banned_by_user?: InputMaybe<Users_Bool_Exp>;
  banned_reason?: InputMaybe<String_Comparison_Exp>;
  contact_number?: InputMaybe<String_Comparison_Exp>;
  contact_type?: InputMaybe<Contact_Type_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  driver_license?: InputMaybe<Files_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  from_booking_portal?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoices?: InputMaybe<Invoice_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  is_banned?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  newsletter?: InputMaybe<Boolean_Comparison_Exp>;
  organization_commercial_register_number?: InputMaybe<String_Comparison_Exp>;
  organization_name?: InputMaybe<String_Comparison_Exp>;
  organization_vat?: InputMaybe<String_Comparison_Exp>;
  person_dateofbirth?: InputMaybe<Date_Comparison_Exp>;
  person_driver_license_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  person_driver_license_is_valid?: InputMaybe<Boolean_Comparison_Exp>;
  person_driver_license_issued_by?: InputMaybe<String_Comparison_Exp>;
  person_driver_license_issued_on?: InputMaybe<Date_Comparison_Exp>;
  person_driver_license_number?: InputMaybe<String_Comparison_Exp>;
  person_driver_license_validated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  person_driver_license_validated_by?: InputMaybe<String_Comparison_Exp>;
  person_firstname?: InputMaybe<String_Comparison_Exp>;
  person_lastname?: InputMaybe<String_Comparison_Exp>;
  person_name?: InputMaybe<String_Comparison_Exp>;
  person_placeofbirth?: InputMaybe<String_Comparison_Exp>;
  person_registration_certificate_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  person_registration_certificate_is_valid?: InputMaybe<Boolean_Comparison_Exp>;
  person_registration_certificate_validated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  person_registration_certificate_validated_by?: InputMaybe<String_Comparison_Exp>;
  person_title?: InputMaybe<String_Comparison_Exp>;
  registration_certificate?: InputMaybe<Files_Bool_Exp>;
  rental_contracts_primary?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contracts_primary_aggregate?: InputMaybe<Rental_Contract_Aggregate_Bool_Exp>;
  rental_contracts_secondary?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contracts_secondary_aggregate?: InputMaybe<Rental_Contract_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact" */
export enum Contact_Constraint {
  /** unique or primary key constraint on columns "legacy_id" */
  ContactLegacyIdKey = 'contact_legacy_id_key',
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'contact_pkey'
}

/** input type for inserting data into table "contact" */
export type Contact_Insert_Input = {
  address_city?: InputMaybe<Scalars['String']['input']>;
  address_country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  address_country_id?: InputMaybe<Scalars['uuid']['input']>;
  address_street?: InputMaybe<Scalars['String']['input']>;
  address_zipcode?: InputMaybe<Scalars['String']['input']>;
  banned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  banned_by?: InputMaybe<Scalars['uuid']['input']>;
  banned_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  banned_reason?: InputMaybe<Scalars['String']['input']>;
  contact_number?: InputMaybe<Scalars['String']['input']>;
  contact_type?: InputMaybe<Contact_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  driver_license?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']['input']>;
  from_booking_portal?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  is_banned?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  organization_commercial_register_number?: InputMaybe<Scalars['String']['input']>;
  organization_name?: InputMaybe<Scalars['String']['input']>;
  organization_vat?: InputMaybe<Scalars['String']['input']>;
  person_dateofbirth?: InputMaybe<Scalars['date']['input']>;
  person_driver_license_file_id?: InputMaybe<Scalars['uuid']['input']>;
  person_driver_license_is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  person_driver_license_issued_by?: InputMaybe<Scalars['String']['input']>;
  person_driver_license_issued_on?: InputMaybe<Scalars['date']['input']>;
  person_driver_license_number?: InputMaybe<Scalars['String']['input']>;
  person_driver_license_validated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  person_driver_license_validated_by?: InputMaybe<Scalars['String']['input']>;
  person_firstname?: InputMaybe<Scalars['String']['input']>;
  person_lastname?: InputMaybe<Scalars['String']['input']>;
  person_placeofbirth?: InputMaybe<Scalars['String']['input']>;
  person_registration_certificate_file_id?: InputMaybe<Scalars['uuid']['input']>;
  person_registration_certificate_is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  person_registration_certificate_validated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  person_registration_certificate_validated_by?: InputMaybe<Scalars['String']['input']>;
  person_title?: InputMaybe<Scalars['String']['input']>;
  registration_certificate?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  rental_contracts_primary?: InputMaybe<Rental_Contract_Arr_Rel_Insert_Input>;
  rental_contracts_secondary?: InputMaybe<Rental_Contract_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contact_Max_Fields = {
  __typename?: 'contact_max_fields';
  address_city?: Maybe<Scalars['String']['output']>;
  address_country_id?: Maybe<Scalars['uuid']['output']>;
  address_street?: Maybe<Scalars['String']['output']>;
  address_zipcode?: Maybe<Scalars['String']['output']>;
  banned_at?: Maybe<Scalars['timestamptz']['output']>;
  banned_by?: Maybe<Scalars['uuid']['output']>;
  banned_reason?: Maybe<Scalars['String']['output']>;
  contact_number?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  organization_commercial_register_number?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  organization_vat?: Maybe<Scalars['String']['output']>;
  person_dateofbirth?: Maybe<Scalars['date']['output']>;
  person_driver_license_file_id?: Maybe<Scalars['uuid']['output']>;
  person_driver_license_issued_by?: Maybe<Scalars['String']['output']>;
  person_driver_license_issued_on?: Maybe<Scalars['date']['output']>;
  person_driver_license_number?: Maybe<Scalars['String']['output']>;
  person_driver_license_validated_at?: Maybe<Scalars['timestamptz']['output']>;
  person_driver_license_validated_by?: Maybe<Scalars['String']['output']>;
  person_firstname?: Maybe<Scalars['String']['output']>;
  person_lastname?: Maybe<Scalars['String']['output']>;
  person_placeofbirth?: Maybe<Scalars['String']['output']>;
  person_registration_certificate_file_id?: Maybe<Scalars['uuid']['output']>;
  person_registration_certificate_validated_at?: Maybe<Scalars['timestamptz']['output']>;
  person_registration_certificate_validated_by?: Maybe<Scalars['String']['output']>;
  person_title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Contact_Min_Fields = {
  __typename?: 'contact_min_fields';
  address_city?: Maybe<Scalars['String']['output']>;
  address_country_id?: Maybe<Scalars['uuid']['output']>;
  address_street?: Maybe<Scalars['String']['output']>;
  address_zipcode?: Maybe<Scalars['String']['output']>;
  banned_at?: Maybe<Scalars['timestamptz']['output']>;
  banned_by?: Maybe<Scalars['uuid']['output']>;
  banned_reason?: Maybe<Scalars['String']['output']>;
  contact_number?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  organization_commercial_register_number?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  organization_vat?: Maybe<Scalars['String']['output']>;
  person_dateofbirth?: Maybe<Scalars['date']['output']>;
  person_driver_license_file_id?: Maybe<Scalars['uuid']['output']>;
  person_driver_license_issued_by?: Maybe<Scalars['String']['output']>;
  person_driver_license_issued_on?: Maybe<Scalars['date']['output']>;
  person_driver_license_number?: Maybe<Scalars['String']['output']>;
  person_driver_license_validated_at?: Maybe<Scalars['timestamptz']['output']>;
  person_driver_license_validated_by?: Maybe<Scalars['String']['output']>;
  person_firstname?: Maybe<Scalars['String']['output']>;
  person_lastname?: Maybe<Scalars['String']['output']>;
  person_placeofbirth?: Maybe<Scalars['String']['output']>;
  person_registration_certificate_file_id?: Maybe<Scalars['uuid']['output']>;
  person_registration_certificate_validated_at?: Maybe<Scalars['timestamptz']['output']>;
  person_registration_certificate_validated_by?: Maybe<Scalars['String']['output']>;
  person_title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "contact" */
export type Contact_Mutation_Response = {
  __typename?: 'contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact>;
};

/** input type for inserting object relation for remote table "contact" */
export type Contact_Obj_Rel_Insert_Input = {
  data: Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** on_conflict condition type for table "contact" */
export type Contact_On_Conflict = {
  constraint: Contact_Constraint;
  update_columns?: Array<Contact_Update_Column>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "contact". */
export type Contact_Order_By = {
  address_city?: InputMaybe<Order_By>;
  address_country?: InputMaybe<Country_Order_By>;
  address_country_id?: InputMaybe<Order_By>;
  address_street?: InputMaybe<Order_By>;
  address_zipcode?: InputMaybe<Order_By>;
  banned_at?: InputMaybe<Order_By>;
  banned_by?: InputMaybe<Order_By>;
  banned_by_user?: InputMaybe<Users_Order_By>;
  banned_reason?: InputMaybe<Order_By>;
  contact_number?: InputMaybe<Order_By>;
  contact_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  driver_license?: InputMaybe<Files_Order_By>;
  email?: InputMaybe<Order_By>;
  from_booking_portal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  is_banned?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  newsletter?: InputMaybe<Order_By>;
  organization_commercial_register_number?: InputMaybe<Order_By>;
  organization_name?: InputMaybe<Order_By>;
  organization_vat?: InputMaybe<Order_By>;
  person_dateofbirth?: InputMaybe<Order_By>;
  person_driver_license_file_id?: InputMaybe<Order_By>;
  person_driver_license_is_valid?: InputMaybe<Order_By>;
  person_driver_license_issued_by?: InputMaybe<Order_By>;
  person_driver_license_issued_on?: InputMaybe<Order_By>;
  person_driver_license_number?: InputMaybe<Order_By>;
  person_driver_license_validated_at?: InputMaybe<Order_By>;
  person_driver_license_validated_by?: InputMaybe<Order_By>;
  person_firstname?: InputMaybe<Order_By>;
  person_lastname?: InputMaybe<Order_By>;
  person_name?: InputMaybe<Order_By>;
  person_placeofbirth?: InputMaybe<Order_By>;
  person_registration_certificate_file_id?: InputMaybe<Order_By>;
  person_registration_certificate_is_valid?: InputMaybe<Order_By>;
  person_registration_certificate_validated_at?: InputMaybe<Order_By>;
  person_registration_certificate_validated_by?: InputMaybe<Order_By>;
  person_title?: InputMaybe<Order_By>;
  registration_certificate?: InputMaybe<Files_Order_By>;
  rental_contracts_primary_aggregate?: InputMaybe<Rental_Contract_Aggregate_Order_By>;
  rental_contracts_secondary_aggregate?: InputMaybe<Rental_Contract_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact */
export type Contact_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contact" */
export enum Contact_Select_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountryId = 'address_country_id',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  AddressZipcode = 'address_zipcode',
  /** column name */
  BannedAt = 'banned_at',
  /** column name */
  BannedBy = 'banned_by',
  /** column name */
  BannedReason = 'banned_reason',
  /** column name */
  ContactNumber = 'contact_number',
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Email = 'email',
  /** column name */
  FromBookingPortal = 'from_booking_portal',
  /** column name */
  Id = 'id',
  /** column name */
  IsBanned = 'is_banned',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Newsletter = 'newsletter',
  /** column name */
  OrganizationCommercialRegisterNumber = 'organization_commercial_register_number',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  OrganizationVat = 'organization_vat',
  /** column name */
  PersonDateofbirth = 'person_dateofbirth',
  /** column name */
  PersonDriverLicenseFileId = 'person_driver_license_file_id',
  /** column name */
  PersonDriverLicenseIsValid = 'person_driver_license_is_valid',
  /** column name */
  PersonDriverLicenseIssuedBy = 'person_driver_license_issued_by',
  /** column name */
  PersonDriverLicenseIssuedOn = 'person_driver_license_issued_on',
  /** column name */
  PersonDriverLicenseNumber = 'person_driver_license_number',
  /** column name */
  PersonDriverLicenseValidatedAt = 'person_driver_license_validated_at',
  /** column name */
  PersonDriverLicenseValidatedBy = 'person_driver_license_validated_by',
  /** column name */
  PersonFirstname = 'person_firstname',
  /** column name */
  PersonLastname = 'person_lastname',
  /** column name */
  PersonPlaceofbirth = 'person_placeofbirth',
  /** column name */
  PersonRegistrationCertificateFileId = 'person_registration_certificate_file_id',
  /** column name */
  PersonRegistrationCertificateIsValid = 'person_registration_certificate_is_valid',
  /** column name */
  PersonRegistrationCertificateValidatedAt = 'person_registration_certificate_validated_at',
  /** column name */
  PersonRegistrationCertificateValidatedBy = 'person_registration_certificate_validated_by',
  /** column name */
  PersonTitle = 'person_title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "contact" */
export type Contact_Set_Input = {
  address_city?: InputMaybe<Scalars['String']['input']>;
  address_country_id?: InputMaybe<Scalars['uuid']['input']>;
  address_street?: InputMaybe<Scalars['String']['input']>;
  address_zipcode?: InputMaybe<Scalars['String']['input']>;
  banned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  banned_by?: InputMaybe<Scalars['uuid']['input']>;
  banned_reason?: InputMaybe<Scalars['String']['input']>;
  contact_number?: InputMaybe<Scalars['String']['input']>;
  contact_type?: InputMaybe<Contact_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  from_booking_portal?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_banned?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  organization_commercial_register_number?: InputMaybe<Scalars['String']['input']>;
  organization_name?: InputMaybe<Scalars['String']['input']>;
  organization_vat?: InputMaybe<Scalars['String']['input']>;
  person_dateofbirth?: InputMaybe<Scalars['date']['input']>;
  person_driver_license_file_id?: InputMaybe<Scalars['uuid']['input']>;
  person_driver_license_is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  person_driver_license_issued_by?: InputMaybe<Scalars['String']['input']>;
  person_driver_license_issued_on?: InputMaybe<Scalars['date']['input']>;
  person_driver_license_number?: InputMaybe<Scalars['String']['input']>;
  person_driver_license_validated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  person_driver_license_validated_by?: InputMaybe<Scalars['String']['input']>;
  person_firstname?: InputMaybe<Scalars['String']['input']>;
  person_lastname?: InputMaybe<Scalars['String']['input']>;
  person_placeofbirth?: InputMaybe<Scalars['String']['input']>;
  person_registration_certificate_file_id?: InputMaybe<Scalars['uuid']['input']>;
  person_registration_certificate_is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  person_registration_certificate_validated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  person_registration_certificate_validated_by?: InputMaybe<Scalars['String']['input']>;
  person_title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contact" */
export type Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Stream_Cursor_Value_Input = {
  address_city?: InputMaybe<Scalars['String']['input']>;
  address_country_id?: InputMaybe<Scalars['uuid']['input']>;
  address_street?: InputMaybe<Scalars['String']['input']>;
  address_zipcode?: InputMaybe<Scalars['String']['input']>;
  banned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  banned_by?: InputMaybe<Scalars['uuid']['input']>;
  banned_reason?: InputMaybe<Scalars['String']['input']>;
  contact_number?: InputMaybe<Scalars['String']['input']>;
  contact_type?: InputMaybe<Contact_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  from_booking_portal?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_banned?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  organization_commercial_register_number?: InputMaybe<Scalars['String']['input']>;
  organization_name?: InputMaybe<Scalars['String']['input']>;
  organization_vat?: InputMaybe<Scalars['String']['input']>;
  person_dateofbirth?: InputMaybe<Scalars['date']['input']>;
  person_driver_license_file_id?: InputMaybe<Scalars['uuid']['input']>;
  person_driver_license_is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  person_driver_license_issued_by?: InputMaybe<Scalars['String']['input']>;
  person_driver_license_issued_on?: InputMaybe<Scalars['date']['input']>;
  person_driver_license_number?: InputMaybe<Scalars['String']['input']>;
  person_driver_license_validated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  person_driver_license_validated_by?: InputMaybe<Scalars['String']['input']>;
  person_firstname?: InputMaybe<Scalars['String']['input']>;
  person_lastname?: InputMaybe<Scalars['String']['input']>;
  person_placeofbirth?: InputMaybe<Scalars['String']['input']>;
  person_registration_certificate_file_id?: InputMaybe<Scalars['uuid']['input']>;
  person_registration_certificate_is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  person_registration_certificate_validated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  person_registration_certificate_validated_by?: InputMaybe<Scalars['String']['input']>;
  person_title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "contact_type" */
export type Contact_Type = {
  __typename?: 'contact_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "contact_type" */
export type Contact_Type_Aggregate = {
  __typename?: 'contact_type_aggregate';
  aggregate?: Maybe<Contact_Type_Aggregate_Fields>;
  nodes: Array<Contact_Type>;
};

/** aggregate fields of "contact_type" */
export type Contact_Type_Aggregate_Fields = {
  __typename?: 'contact_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Type_Max_Fields>;
  min?: Maybe<Contact_Type_Min_Fields>;
};


/** aggregate fields of "contact_type" */
export type Contact_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "contact_type". All fields are combined with a logical 'AND'. */
export type Contact_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Type_Bool_Exp>>;
  _not?: InputMaybe<Contact_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_type" */
export enum Contact_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContactTypePkey = 'contact_type_pkey'
}

export enum Contact_Type_Enum {
  Organization = 'organization',
  Person = 'person'
}

/** Boolean expression to compare columns of type "contact_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contact_Type_Enum>;
  _in?: InputMaybe<Array<Contact_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Contact_Type_Enum>;
  _nin?: InputMaybe<Array<Contact_Type_Enum>>;
};

/** input type for inserting data into table "contact_type" */
export type Contact_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contact_Type_Max_Fields = {
  __typename?: 'contact_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Contact_Type_Min_Fields = {
  __typename?: 'contact_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "contact_type" */
export type Contact_Type_Mutation_Response = {
  __typename?: 'contact_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Type>;
};

/** on_conflict condition type for table "contact_type" */
export type Contact_Type_On_Conflict = {
  constraint: Contact_Type_Constraint;
  update_columns?: Array<Contact_Type_Update_Column>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_type". */
export type Contact_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_type */
export type Contact_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "contact_type" */
export enum Contact_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "contact_type" */
export type Contact_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contact_type" */
export type Contact_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "contact_type" */
export enum Contact_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Contact_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Type_Bool_Exp;
};

/** update columns of table "contact" */
export enum Contact_Update_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountryId = 'address_country_id',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  AddressZipcode = 'address_zipcode',
  /** column name */
  BannedAt = 'banned_at',
  /** column name */
  BannedBy = 'banned_by',
  /** column name */
  BannedReason = 'banned_reason',
  /** column name */
  ContactNumber = 'contact_number',
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Email = 'email',
  /** column name */
  FromBookingPortal = 'from_booking_portal',
  /** column name */
  Id = 'id',
  /** column name */
  IsBanned = 'is_banned',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Newsletter = 'newsletter',
  /** column name */
  OrganizationCommercialRegisterNumber = 'organization_commercial_register_number',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  OrganizationVat = 'organization_vat',
  /** column name */
  PersonDateofbirth = 'person_dateofbirth',
  /** column name */
  PersonDriverLicenseFileId = 'person_driver_license_file_id',
  /** column name */
  PersonDriverLicenseIsValid = 'person_driver_license_is_valid',
  /** column name */
  PersonDriverLicenseIssuedBy = 'person_driver_license_issued_by',
  /** column name */
  PersonDriverLicenseIssuedOn = 'person_driver_license_issued_on',
  /** column name */
  PersonDriverLicenseNumber = 'person_driver_license_number',
  /** column name */
  PersonDriverLicenseValidatedAt = 'person_driver_license_validated_at',
  /** column name */
  PersonDriverLicenseValidatedBy = 'person_driver_license_validated_by',
  /** column name */
  PersonFirstname = 'person_firstname',
  /** column name */
  PersonLastname = 'person_lastname',
  /** column name */
  PersonPlaceofbirth = 'person_placeofbirth',
  /** column name */
  PersonRegistrationCertificateFileId = 'person_registration_certificate_file_id',
  /** column name */
  PersonRegistrationCertificateIsValid = 'person_registration_certificate_is_valid',
  /** column name */
  PersonRegistrationCertificateValidatedAt = 'person_registration_certificate_validated_at',
  /** column name */
  PersonRegistrationCertificateValidatedBy = 'person_registration_certificate_validated_by',
  /** column name */
  PersonTitle = 'person_title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Website = 'website'
}

export type Contact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Bool_Exp;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  iso2code: Scalars['String']['output'];
  iso3code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numericcode: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  avg?: Maybe<Country_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
  stddev?: Maybe<Country_Stddev_Fields>;
  stddev_pop?: Maybe<Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Country_Stddev_Samp_Fields>;
  sum?: Maybe<Country_Sum_Fields>;
  var_pop?: Maybe<Country_Var_Pop_Fields>;
  var_samp?: Maybe<Country_Var_Samp_Fields>;
  variance?: Maybe<Country_Variance_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Country_Avg_Fields = {
  __typename?: 'country_avg_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  iso2code?: InputMaybe<String_Comparison_Exp>;
  iso3code?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  numericcode?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "iso3code" */
  CountryIso3codeKey = 'country_iso3code_key',
  /** unique or primary key constraint on columns "iso2code" */
  CountryIso_2AbbreviationKey = 'country_iso_2_abbreviation_key',
  /** unique or primary key constraint on columns "name" */
  CountryNameKey = 'country_name_key',
  /** unique or primary key constraint on columns "numericcode" */
  CountryNumericcodeKey = 'country_numericcode_key',
  /** unique or primary key constraint on columns "id" */
  CountryPkey = 'country_pkey'
}

/** input type for incrementing numeric columns in table "country" */
export type Country_Inc_Input = {
  numericcode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iso2code?: InputMaybe<Scalars['String']['input']>;
  iso3code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numericcode?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  iso2code?: Maybe<Scalars['String']['output']>;
  iso3code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numericcode?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  iso2code?: Maybe<Scalars['String']['output']>;
  iso3code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numericcode?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iso2code?: InputMaybe<Order_By>;
  iso3code?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  numericcode?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Iso2code = 'iso2code',
  /** column name */
  Iso3code = 'iso3code',
  /** column name */
  Name = 'name',
  /** column name */
  Numericcode = 'numericcode',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iso2code?: InputMaybe<Scalars['String']['input']>;
  iso3code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numericcode?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Country_Stddev_Fields = {
  __typename?: 'country_stddev_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Country_Stddev_Pop_Fields = {
  __typename?: 'country_stddev_pop_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Country_Stddev_Samp_Fields = {
  __typename?: 'country_stddev_samp_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iso2code?: InputMaybe<Scalars['String']['input']>;
  iso3code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numericcode?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Country_Sum_Fields = {
  __typename?: 'country_sum_fields';
  numericcode?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Iso2code = 'iso2code',
  /** column name */
  Iso3code = 'iso3code',
  /** column name */
  Name = 'name',
  /** column name */
  Numericcode = 'numericcode',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Country_Var_Pop_Fields = {
  __typename?: 'country_var_pop_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Country_Var_Samp_Fields = {
  __typename?: 'country_var_samp_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Country_Variance_Fields = {
  __typename?: 'country_variance_fields';
  numericcode?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "entity_type" */
export type Entity_Type = {
  __typename?: 'entity_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "entity_type" */
export type Entity_Type_Aggregate = {
  __typename?: 'entity_type_aggregate';
  aggregate?: Maybe<Entity_Type_Aggregate_Fields>;
  nodes: Array<Entity_Type>;
};

/** aggregate fields of "entity_type" */
export type Entity_Type_Aggregate_Fields = {
  __typename?: 'entity_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Entity_Type_Max_Fields>;
  min?: Maybe<Entity_Type_Min_Fields>;
};


/** aggregate fields of "entity_type" */
export type Entity_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "entity_type". All fields are combined with a logical 'AND'. */
export type Entity_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Type_Bool_Exp>>;
  _not?: InputMaybe<Entity_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_type" */
export enum Entity_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EntityTypePkey = 'entity_type_pkey'
}

export enum Entity_Type_Enum {
  Contact = 'contact',
  /** Rechnung */
  Invoice = 'invoice',
  Rentalcontract = 'rentalcontract',
  /** Reservierungsanfrage */
  Request = 'request'
}

/** Boolean expression to compare columns of type "entity_type_enum". All fields are combined with logical 'AND'. */
export type Entity_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Entity_Type_Enum>;
  _in?: InputMaybe<Array<Entity_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Entity_Type_Enum>;
  _nin?: InputMaybe<Array<Entity_Type_Enum>>;
};

/** input type for inserting data into table "entity_type" */
export type Entity_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Entity_Type_Max_Fields = {
  __typename?: 'entity_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Entity_Type_Min_Fields = {
  __typename?: 'entity_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entity_type" */
export type Entity_Type_Mutation_Response = {
  __typename?: 'entity_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Type>;
};

/** on_conflict condition type for table "entity_type" */
export type Entity_Type_On_Conflict = {
  constraint: Entity_Type_Constraint;
  update_columns?: Array<Entity_Type_Update_Column>;
  where?: InputMaybe<Entity_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_type". */
export type Entity_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_type */
export type Entity_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "entity_type" */
export enum Entity_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "entity_type" */
export type Entity_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entity_type" */
export type Entity_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entity_type" */
export enum Entity_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Entity_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_Type_Bool_Exp;
};

/** columns and relationships of "storage.files" */
export type Files = {
  __typename?: 'files';
  /** An object relationship */
  bucket: Buckets;
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "storage.files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage.files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<Files_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
  stddev?: Maybe<Files_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Sum_Fields>;
  var_pop?: Maybe<Files_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Var_Samp_Fields>;
  variance?: Maybe<Files_Variance_Fields>;
};


/** aggregate fields of "storage.files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage.files" */
export type Files_Aggregate_Order_By = {
  avg?: InputMaybe<Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
  stddev?: InputMaybe<Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Files_Sum_Order_By>;
  var_pop?: InputMaybe<Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Files_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "storage.files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Avg_Fields = {
  __typename?: 'files_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "storage.files" */
export type Files_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  bucket?: InputMaybe<Buckets_Bool_Exp>;
  bucketId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  etag?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isUploaded?: InputMaybe<Boolean_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage.files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for incrementing numeric columns in table "storage.files" */
export type Files_Inc_Input = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.files" */
export type Files_Insert_Input = {
  bucket?: InputMaybe<Buckets_Obj_Rel_Insert_Input>;
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "storage.files" */
export type Files_Max_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "storage.files" */
export type Files_Min_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage.files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage.files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "storage.files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.files". */
export type Files_Order_By = {
  bucket?: InputMaybe<Buckets_Order_By>;
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isUploaded?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage.files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "storage.files" */
export enum Files_Select_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId'
}

/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded'
}

/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded'
}

/** input type for updating data in table "storage.files" */
export type Files_Set_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Files_Stddev_Fields = {
  __typename?: 'files_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "storage.files" */
export type Files_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Stddev_Pop_Fields = {
  __typename?: 'files_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "storage.files" */
export type Files_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Stddev_Samp_Fields = {
  __typename?: 'files_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "storage.files" */
export type Files_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Files_Sum_Fields = {
  __typename?: 'files_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "storage.files" */
export type Files_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** update columns of table "storage.files" */
export enum Files_Update_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId'
}

export type Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Var_Pop_Fields = {
  __typename?: 'files_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "storage.files" */
export type Files_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Var_Samp_Fields = {
  __typename?: 'files_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "storage.files" */
export type Files_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Variance_Fields = {
  __typename?: 'files_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "storage.files" */
export type Files_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "fuel" */
export type Fuel = {
  __typename?: 'fuel';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "fuel" */
export type Fuel_Aggregate = {
  __typename?: 'fuel_aggregate';
  aggregate?: Maybe<Fuel_Aggregate_Fields>;
  nodes: Array<Fuel>;
};

/** aggregate fields of "fuel" */
export type Fuel_Aggregate_Fields = {
  __typename?: 'fuel_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Fuel_Max_Fields>;
  min?: Maybe<Fuel_Min_Fields>;
};


/** aggregate fields of "fuel" */
export type Fuel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fuel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "fuel". All fields are combined with a logical 'AND'. */
export type Fuel_Bool_Exp = {
  _and?: InputMaybe<Array<Fuel_Bool_Exp>>;
  _not?: InputMaybe<Fuel_Bool_Exp>;
  _or?: InputMaybe<Array<Fuel_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fuel" */
export enum Fuel_Constraint {
  /** unique or primary key constraint on columns "value" */
  FuelPkey = 'fuel_pkey'
}

export enum Fuel_Enum {
  Diesel = 'diesel',
  Electricity = 'electricity',
  Petrol = 'petrol'
}

/** Boolean expression to compare columns of type "fuel_enum". All fields are combined with logical 'AND'. */
export type Fuel_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Fuel_Enum>;
  _in?: InputMaybe<Array<Fuel_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Fuel_Enum>;
  _nin?: InputMaybe<Array<Fuel_Enum>>;
};

/** input type for inserting data into table "fuel" */
export type Fuel_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Fuel_Max_Fields = {
  __typename?: 'fuel_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Fuel_Min_Fields = {
  __typename?: 'fuel_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "fuel" */
export type Fuel_Mutation_Response = {
  __typename?: 'fuel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Fuel>;
};

/** on_conflict condition type for table "fuel" */
export type Fuel_On_Conflict = {
  constraint: Fuel_Constraint;
  update_columns?: Array<Fuel_Update_Column>;
  where?: InputMaybe<Fuel_Bool_Exp>;
};

/** Ordering options when selecting data from "fuel". */
export type Fuel_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fuel */
export type Fuel_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "fuel" */
export enum Fuel_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "fuel" */
export type Fuel_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "fuel" */
export type Fuel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fuel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fuel_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "fuel" */
export enum Fuel_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Fuel_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fuel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fuel_Bool_Exp;
};

/** columns and relationships of "gearshift" */
export type Gearshift = {
  __typename?: 'gearshift';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "gearshift" */
export type Gearshift_Aggregate = {
  __typename?: 'gearshift_aggregate';
  aggregate?: Maybe<Gearshift_Aggregate_Fields>;
  nodes: Array<Gearshift>;
};

/** aggregate fields of "gearshift" */
export type Gearshift_Aggregate_Fields = {
  __typename?: 'gearshift_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Gearshift_Max_Fields>;
  min?: Maybe<Gearshift_Min_Fields>;
};


/** aggregate fields of "gearshift" */
export type Gearshift_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gearshift_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "gearshift". All fields are combined with a logical 'AND'. */
export type Gearshift_Bool_Exp = {
  _and?: InputMaybe<Array<Gearshift_Bool_Exp>>;
  _not?: InputMaybe<Gearshift_Bool_Exp>;
  _or?: InputMaybe<Array<Gearshift_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gearshift" */
export enum Gearshift_Constraint {
  /** unique or primary key constraint on columns "value" */
  GearshiftPkey = 'gearshift_pkey'
}

export enum Gearshift_Enum {
  Automatic = 'automatic',
  Manual = 'manual'
}

/** Boolean expression to compare columns of type "gearshift_enum". All fields are combined with logical 'AND'. */
export type Gearshift_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Gearshift_Enum>;
  _in?: InputMaybe<Array<Gearshift_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Gearshift_Enum>;
  _nin?: InputMaybe<Array<Gearshift_Enum>>;
};

/** input type for inserting data into table "gearshift" */
export type Gearshift_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Gearshift_Max_Fields = {
  __typename?: 'gearshift_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Gearshift_Min_Fields = {
  __typename?: 'gearshift_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "gearshift" */
export type Gearshift_Mutation_Response = {
  __typename?: 'gearshift_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Gearshift>;
};

/** on_conflict condition type for table "gearshift" */
export type Gearshift_On_Conflict = {
  constraint: Gearshift_Constraint;
  update_columns?: Array<Gearshift_Update_Column>;
  where?: InputMaybe<Gearshift_Bool_Exp>;
};

/** Ordering options when selecting data from "gearshift". */
export type Gearshift_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gearshift */
export type Gearshift_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "gearshift" */
export enum Gearshift_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gearshift" */
export type Gearshift_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "gearshift" */
export type Gearshift_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gearshift_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gearshift_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "gearshift" */
export enum Gearshift_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Gearshift_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gearshift_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gearshift_Bool_Exp;
};

/** columns and relationships of "invoice" */
export type Invoice = {
  __typename?: 'invoice';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  internal_reference_number?: Maybe<Scalars['String']['output']>;
  invoice_number?: Maybe<Scalars['String']['output']>;
  is_paid?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  items: Array<Invoice_Item>;
  /** An aggregate relationship */
  items_aggregate: Invoice_Item_Aggregate;
  legacy_id?: Maybe<Scalars['String']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  payment_target?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  recipient?: Maybe<Contact>;
  recipient_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  rental_contract: Rental_Contract;
  rental_contract_id: Scalars['uuid']['output'];
  result_contract_fee?: Maybe<Scalars['numeric']['output']>;
  result_gross?: Maybe<Scalars['numeric']['output']>;
  result_net?: Maybe<Scalars['numeric']['output']>;
  result_tax?: Maybe<Scalars['numeric']['output']>;
  result_total?: Maybe<Scalars['numeric']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "invoice" */
export type InvoiceItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};


/** columns and relationships of "invoice" */
export type InvoiceItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate';
  aggregate?: Maybe<Invoice_Aggregate_Fields>;
  nodes: Array<Invoice>;
};

export type Invoice_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoice_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoice_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoice_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_fields';
  avg?: Maybe<Invoice_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Invoice_Max_Fields>;
  min?: Maybe<Invoice_Min_Fields>;
  stddev?: Maybe<Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Variance_Fields>;
};


/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "invoice" */
export type Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Max_Order_By>;
  min?: InputMaybe<Invoice_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice" */
export type Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  __typename?: 'invoice_avg_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "invoice" */
export type Invoice_Avg_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_reference_number?: InputMaybe<String_Comparison_Exp>;
  invoice_number?: InputMaybe<String_Comparison_Exp>;
  is_paid?: InputMaybe<Boolean_Comparison_Exp>;
  items?: InputMaybe<Invoice_Item_Bool_Exp>;
  items_aggregate?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  paid_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  payment_target?: InputMaybe<Timestamptz_Comparison_Exp>;
  recipient?: InputMaybe<Contact_Bool_Exp>;
  recipient_id?: InputMaybe<Uuid_Comparison_Exp>;
  rental_contract?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  result_contract_fee?: InputMaybe<Numeric_Comparison_Exp>;
  result_gross?: InputMaybe<Numeric_Comparison_Exp>;
  result_net?: InputMaybe<Numeric_Comparison_Exp>;
  result_tax?: InputMaybe<Numeric_Comparison_Exp>;
  result_total?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "invoice_number" */
  InvoiceInvoiceNumberKey = 'invoice_invoice_number_key',
  /** unique or primary key constraint on columns "legacy_id" */
  InvoiceLegacyIdKey = 'invoice_legacy_id_key',
  /** unique or primary key constraint on columns "id" */
  InvoicePkey = 'invoice_pkey'
}

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  result_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  result_gross?: InputMaybe<Scalars['numeric']['input']>;
  result_net?: InputMaybe<Scalars['numeric']['input']>;
  result_tax?: InputMaybe<Scalars['numeric']['input']>;
  result_total?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_reference_number?: InputMaybe<Scalars['String']['input']>;
  invoice_number?: InputMaybe<Scalars['String']['input']>;
  is_paid?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Invoice_Item_Arr_Rel_Insert_Input>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  payment_target?: InputMaybe<Scalars['timestamptz']['input']>;
  recipient?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  recipient_id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract?: InputMaybe<Rental_Contract_Obj_Rel_Insert_Input>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  result_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  result_gross?: InputMaybe<Scalars['numeric']['input']>;
  result_net?: InputMaybe<Scalars['numeric']['input']>;
  result_tax?: InputMaybe<Scalars['numeric']['input']>;
  result_total?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "invoice_item" */
export type Invoice_Item = {
  __typename?: 'invoice_item';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  discount_total_value?: Maybe<Scalars['numeric']['output']>;
  discount_value?: Maybe<Scalars['numeric']['output']>;
  has_discount?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  invoice: Invoice;
  invoice_id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  total_value?: Maybe<Scalars['numeric']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  value_per_unit?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "invoice_item" */
export type Invoice_Item_Aggregate = {
  __typename?: 'invoice_item_aggregate';
  aggregate?: Maybe<Invoice_Item_Aggregate_Fields>;
  nodes: Array<Invoice_Item>;
};

export type Invoice_Item_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invoice_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invoice_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invoice_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice_item" */
export type Invoice_Item_Aggregate_Fields = {
  __typename?: 'invoice_item_aggregate_fields';
  avg?: Maybe<Invoice_Item_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Invoice_Item_Max_Fields>;
  min?: Maybe<Invoice_Item_Min_Fields>;
  stddev?: Maybe<Invoice_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Item_Sum_Fields>;
  var_pop?: Maybe<Invoice_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Item_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Item_Variance_Fields>;
};


/** aggregate fields of "invoice_item" */
export type Invoice_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "invoice_item" */
export type Invoice_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Item_Max_Order_By>;
  min?: InputMaybe<Invoice_Item_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_item" */
export type Invoice_Item_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Item_Avg_Fields = {
  __typename?: 'invoice_item_avg_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "invoice_item" */
export type Invoice_Item_Avg_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_item". All fields are combined with a logical 'AND'. */
export type Invoice_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Item_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  discount_total_value?: InputMaybe<Numeric_Comparison_Exp>;
  discount_value?: InputMaybe<Numeric_Comparison_Exp>;
  has_discount?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  total_value?: InputMaybe<Numeric_Comparison_Exp>;
  unit?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value_per_unit?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_item" */
export enum Invoice_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceItemPkey = 'invoice_item_pkey'
}

/** input type for incrementing numeric columns in table "invoice_item" */
export type Invoice_Item_Inc_Input = {
  discount_total_value?: InputMaybe<Scalars['numeric']['input']>;
  discount_value?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  total_value?: InputMaybe<Scalars['numeric']['input']>;
  value_per_unit?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "invoice_item" */
export type Invoice_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  discount_total_value?: InputMaybe<Scalars['numeric']['input']>;
  discount_value?: InputMaybe<Scalars['numeric']['input']>;
  has_discount?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  total_value?: InputMaybe<Scalars['numeric']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value_per_unit?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Invoice_Item_Max_Fields = {
  __typename?: 'invoice_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  discount_total_value?: Maybe<Scalars['numeric']['output']>;
  discount_value?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoice_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  total_value?: Maybe<Scalars['numeric']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  value_per_unit?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "invoice_item" */
export type Invoice_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Item_Min_Fields = {
  __typename?: 'invoice_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  discount_total_value?: Maybe<Scalars['numeric']['output']>;
  discount_value?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invoice_id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  total_value?: Maybe<Scalars['numeric']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  value_per_unit?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "invoice_item" */
export type Invoice_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice_item" */
export type Invoice_Item_Mutation_Response = {
  __typename?: 'invoice_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Item>;
};

/** on_conflict condition type for table "invoice_item" */
export type Invoice_Item_On_Conflict = {
  constraint: Invoice_Item_Constraint;
  update_columns?: Array<Invoice_Item_Update_Column>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_item". */
export type Invoice_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  has_discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice_item */
export type Invoice_Item_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "invoice_item" */
export enum Invoice_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountTotalValue = 'discount_total_value',
  /** column name */
  DiscountValue = 'discount_value',
  /** column name */
  HasDiscount = 'has_discount',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  TotalValue = 'total_value',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValuePerUnit = 'value_per_unit'
}

/** select "invoice_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice_item" */
export enum Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasDiscount = 'has_discount'
}

/** select "invoice_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice_item" */
export enum Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasDiscount = 'has_discount'
}

/** input type for updating data in table "invoice_item" */
export type Invoice_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  discount_total_value?: InputMaybe<Scalars['numeric']['input']>;
  discount_value?: InputMaybe<Scalars['numeric']['input']>;
  has_discount?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  total_value?: InputMaybe<Scalars['numeric']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value_per_unit?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Invoice_Item_Stddev_Fields = {
  __typename?: 'invoice_item_stddev_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "invoice_item" */
export type Invoice_Item_Stddev_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Item_Stddev_Pop_Fields = {
  __typename?: 'invoice_item_stddev_pop_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "invoice_item" */
export type Invoice_Item_Stddev_Pop_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Item_Stddev_Samp_Fields = {
  __typename?: 'invoice_item_stddev_samp_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "invoice_item" */
export type Invoice_Item_Stddev_Samp_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice_item" */
export type Invoice_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  discount_total_value?: InputMaybe<Scalars['numeric']['input']>;
  discount_value?: InputMaybe<Scalars['numeric']['input']>;
  has_discount?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invoice_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  total_value?: InputMaybe<Scalars['numeric']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value_per_unit?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Invoice_Item_Sum_Fields = {
  __typename?: 'invoice_item_sum_fields';
  discount_total_value?: Maybe<Scalars['numeric']['output']>;
  discount_value?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  total_value?: Maybe<Scalars['numeric']['output']>;
  value_per_unit?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "invoice_item" */
export type Invoice_Item_Sum_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** update columns of table "invoice_item" */
export enum Invoice_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountTotalValue = 'discount_total_value',
  /** column name */
  DiscountValue = 'discount_value',
  /** column name */
  HasDiscount = 'has_discount',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  TotalValue = 'total_value',
  /** column name */
  Unit = 'unit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValuePerUnit = 'value_per_unit'
}

export type Invoice_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Item_Var_Pop_Fields = {
  __typename?: 'invoice_item_var_pop_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "invoice_item" */
export type Invoice_Item_Var_Pop_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Item_Var_Samp_Fields = {
  __typename?: 'invoice_item_var_samp_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "invoice_item" */
export type Invoice_Item_Var_Samp_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Item_Variance_Fields = {
  __typename?: 'invoice_item_variance_fields';
  discount_total_value?: Maybe<Scalars['Float']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  total_value?: Maybe<Scalars['Float']['output']>;
  value_per_unit?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "invoice_item" */
export type Invoice_Item_Variance_Order_By = {
  discount_total_value?: InputMaybe<Order_By>;
  discount_value?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  total_value?: InputMaybe<Order_By>;
  value_per_unit?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  __typename?: 'invoice_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_reference_number?: Maybe<Scalars['String']['output']>;
  invoice_number?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  payment_target?: Maybe<Scalars['timestamptz']['output']>;
  recipient_id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  result_contract_fee?: Maybe<Scalars['numeric']['output']>;
  result_gross?: Maybe<Scalars['numeric']['output']>;
  result_net?: Maybe<Scalars['numeric']['output']>;
  result_tax?: Maybe<Scalars['numeric']['output']>;
  result_total?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "invoice" */
export type Invoice_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_reference_number?: InputMaybe<Order_By>;
  invoice_number?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  payment_target?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  __typename?: 'invoice_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_reference_number?: Maybe<Scalars['String']['output']>;
  invoice_number?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  payment_target?: Maybe<Scalars['timestamptz']['output']>;
  recipient_id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  result_contract_fee?: Maybe<Scalars['numeric']['output']>;
  result_gross?: Maybe<Scalars['numeric']['output']>;
  result_net?: Maybe<Scalars['numeric']['output']>;
  result_tax?: Maybe<Scalars['numeric']['output']>;
  result_total?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "invoice" */
export type Invoice_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_reference_number?: InputMaybe<Order_By>;
  invoice_number?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  payment_target?: InputMaybe<Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  __typename?: 'invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_reference_number?: InputMaybe<Order_By>;
  invoice_number?: InputMaybe<Order_By>;
  is_paid?: InputMaybe<Order_By>;
  items_aggregate?: InputMaybe<Invoice_Item_Aggregate_Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  payment_target?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Contact_Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  rental_contract?: InputMaybe<Rental_Contract_Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  InternalReferenceNumber = 'internal_reference_number',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  IsPaid = 'is_paid',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PaymentTarget = 'payment_target',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  ResultContractFee = 'result_contract_fee',
  /** column name */
  ResultGross = 'result_gross',
  /** column name */
  ResultNet = 'result_net',
  /** column name */
  ResultTax = 'result_tax',
  /** column name */
  ResultTotal = 'result_total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "invoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice" */
export enum Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPaid = 'is_paid'
}

/** select "invoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice" */
export enum Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPaid = 'is_paid'
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_reference_number?: InputMaybe<Scalars['String']['input']>;
  invoice_number?: InputMaybe<Scalars['String']['input']>;
  is_paid?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  payment_target?: InputMaybe<Scalars['timestamptz']['input']>;
  recipient_id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  result_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  result_gross?: InputMaybe<Scalars['numeric']['input']>;
  result_net?: InputMaybe<Scalars['numeric']['input']>;
  result_tax?: InputMaybe<Scalars['numeric']['input']>;
  result_total?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  __typename?: 'invoice_stddev_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "invoice" */
export type Invoice_Stddev_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoice_stddev_pop_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "invoice" */
export type Invoice_Stddev_Pop_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoice_stddev_samp_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "invoice" */
export type Invoice_Stddev_Samp_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_reference_number?: InputMaybe<Scalars['String']['input']>;
  invoice_number?: InputMaybe<Scalars['String']['input']>;
  is_paid?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  payment_target?: InputMaybe<Scalars['timestamptz']['input']>;
  recipient_id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  result_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  result_gross?: InputMaybe<Scalars['numeric']['input']>;
  result_net?: InputMaybe<Scalars['numeric']['input']>;
  result_tax?: InputMaybe<Scalars['numeric']['input']>;
  result_total?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  __typename?: 'invoice_sum_fields';
  result_contract_fee?: Maybe<Scalars['numeric']['output']>;
  result_gross?: Maybe<Scalars['numeric']['output']>;
  result_net?: Maybe<Scalars['numeric']['output']>;
  result_tax?: Maybe<Scalars['numeric']['output']>;
  result_total?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "invoice" */
export type Invoice_Sum_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  InternalReferenceNumber = 'internal_reference_number',
  /** column name */
  InvoiceNumber = 'invoice_number',
  /** column name */
  IsPaid = 'is_paid',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PaymentTarget = 'payment_target',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  ResultContractFee = 'result_contract_fee',
  /** column name */
  ResultGross = 'result_gross',
  /** column name */
  ResultNet = 'result_net',
  /** column name */
  ResultTax = 'result_tax',
  /** column name */
  ResultTotal = 'result_total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  __typename?: 'invoice_var_pop_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "invoice" */
export type Invoice_Var_Pop_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  __typename?: 'invoice_var_samp_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "invoice" */
export type Invoice_Var_Samp_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  __typename?: 'invoice_variance_fields';
  result_contract_fee?: Maybe<Scalars['Float']['output']>;
  result_gross?: Maybe<Scalars['Float']['output']>;
  result_net?: Maybe<Scalars['Float']['output']>;
  result_tax?: Maybe<Scalars['Float']['output']>;
  result_total?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "invoice" */
export type Invoice_Variance_Order_By = {
  result_contract_fee?: InputMaybe<Order_By>;
  result_gross?: InputMaybe<Order_By>;
  result_net?: InputMaybe<Order_By>;
  result_tax?: InputMaybe<Order_By>;
  result_total?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "kilometer_fee" */
export type Kilometer_Fee = {
  __typename?: 'kilometer_fee';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  entries: Array<Kilometer_Fee_Entry>;
  /** An aggregate relationship */
  entries_aggregate: Kilometer_Fee_Entry_Aggregate;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sort_order: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  vehicle_types: Array<Kilometer_Fee_Vehicle_Type>;
  /** An aggregate relationship */
  vehicle_types_aggregate: Kilometer_Fee_Vehicle_Type_Aggregate;
};


/** columns and relationships of "kilometer_fee" */
export type Kilometer_FeeEntriesArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Entry_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


/** columns and relationships of "kilometer_fee" */
export type Kilometer_FeeEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Entry_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


/** columns and relationships of "kilometer_fee" */
export type Kilometer_FeeVehicle_TypesArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


/** columns and relationships of "kilometer_fee" */
export type Kilometer_FeeVehicle_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};

/** aggregated selection of "kilometer_fee" */
export type Kilometer_Fee_Aggregate = {
  __typename?: 'kilometer_fee_aggregate';
  aggregate?: Maybe<Kilometer_Fee_Aggregate_Fields>;
  nodes: Array<Kilometer_Fee>;
};

/** aggregate fields of "kilometer_fee" */
export type Kilometer_Fee_Aggregate_Fields = {
  __typename?: 'kilometer_fee_aggregate_fields';
  avg?: Maybe<Kilometer_Fee_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Kilometer_Fee_Max_Fields>;
  min?: Maybe<Kilometer_Fee_Min_Fields>;
  stddev?: Maybe<Kilometer_Fee_Stddev_Fields>;
  stddev_pop?: Maybe<Kilometer_Fee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kilometer_Fee_Stddev_Samp_Fields>;
  sum?: Maybe<Kilometer_Fee_Sum_Fields>;
  var_pop?: Maybe<Kilometer_Fee_Var_Pop_Fields>;
  var_samp?: Maybe<Kilometer_Fee_Var_Samp_Fields>;
  variance?: Maybe<Kilometer_Fee_Variance_Fields>;
};


/** aggregate fields of "kilometer_fee" */
export type Kilometer_Fee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kilometer_Fee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Kilometer_Fee_Avg_Fields = {
  __typename?: 'kilometer_fee_avg_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "kilometer_fee". All fields are combined with a logical 'AND'. */
export type Kilometer_Fee_Bool_Exp = {
  _and?: InputMaybe<Array<Kilometer_Fee_Bool_Exp>>;
  _not?: InputMaybe<Kilometer_Fee_Bool_Exp>;
  _or?: InputMaybe<Array<Kilometer_Fee_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  entries?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
  entries_aggregate?: InputMaybe<Kilometer_Fee_Entry_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  valid_from?: InputMaybe<Date_Comparison_Exp>;
  valid_to?: InputMaybe<Date_Comparison_Exp>;
  vehicle_types?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
  vehicle_types_aggregate?: InputMaybe<Kilometer_Fee_Vehicle_Type_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "kilometer_fee" */
export enum Kilometer_Fee_Constraint {
  /** unique or primary key constraint on columns "id" */
  KilometerFeePkey = 'kilometer_fee_pkey'
}

/** columns and relationships of "kilometer_fee_entry" */
export type Kilometer_Fee_Entry = {
  __typename?: 'kilometer_fee_entry';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  kilometer: Scalars['numeric']['output'];
  /** An object relationship */
  kilometer_fee: Kilometer_Fee;
  kilometer_fee_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  value: Scalars['numeric']['output'];
};

/** aggregated selection of "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Aggregate = {
  __typename?: 'kilometer_fee_entry_aggregate';
  aggregate?: Maybe<Kilometer_Fee_Entry_Aggregate_Fields>;
  nodes: Array<Kilometer_Fee_Entry>;
};

export type Kilometer_Fee_Entry_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kilometer_Fee_Entry_Aggregate_Bool_Exp_Count>;
};

export type Kilometer_Fee_Entry_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Aggregate_Fields = {
  __typename?: 'kilometer_fee_entry_aggregate_fields';
  avg?: Maybe<Kilometer_Fee_Entry_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Kilometer_Fee_Entry_Max_Fields>;
  min?: Maybe<Kilometer_Fee_Entry_Min_Fields>;
  stddev?: Maybe<Kilometer_Fee_Entry_Stddev_Fields>;
  stddev_pop?: Maybe<Kilometer_Fee_Entry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kilometer_Fee_Entry_Stddev_Samp_Fields>;
  sum?: Maybe<Kilometer_Fee_Entry_Sum_Fields>;
  var_pop?: Maybe<Kilometer_Fee_Entry_Var_Pop_Fields>;
  var_samp?: Maybe<Kilometer_Fee_Entry_Var_Samp_Fields>;
  variance?: Maybe<Kilometer_Fee_Entry_Variance_Fields>;
};


/** aggregate fields of "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Aggregate_Order_By = {
  avg?: InputMaybe<Kilometer_Fee_Entry_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kilometer_Fee_Entry_Max_Order_By>;
  min?: InputMaybe<Kilometer_Fee_Entry_Min_Order_By>;
  stddev?: InputMaybe<Kilometer_Fee_Entry_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Kilometer_Fee_Entry_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Kilometer_Fee_Entry_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Kilometer_Fee_Entry_Sum_Order_By>;
  var_pop?: InputMaybe<Kilometer_Fee_Entry_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Kilometer_Fee_Entry_Var_Samp_Order_By>;
  variance?: InputMaybe<Kilometer_Fee_Entry_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Arr_Rel_Insert_Input = {
  data: Array<Kilometer_Fee_Entry_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kilometer_Fee_Entry_On_Conflict>;
};

/** aggregate avg on columns */
export type Kilometer_Fee_Entry_Avg_Fields = {
  __typename?: 'kilometer_fee_entry_avg_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Avg_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "kilometer_fee_entry". All fields are combined with a logical 'AND'. */
export type Kilometer_Fee_Entry_Bool_Exp = {
  _and?: InputMaybe<Array<Kilometer_Fee_Entry_Bool_Exp>>;
  _not?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
  _or?: InputMaybe<Array<Kilometer_Fee_Entry_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kilometer?: InputMaybe<Numeric_Comparison_Exp>;
  kilometer_fee?: InputMaybe<Kilometer_Fee_Bool_Exp>;
  kilometer_fee_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "kilometer_fee_entry" */
export enum Kilometer_Fee_Entry_Constraint {
  /** unique or primary key constraint on columns "id" */
  KilometerFeeEntryPkey = 'kilometer_fee_entry_pkey'
}

/** input type for incrementing numeric columns in table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Inc_Input = {
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  kilometer_fee?: InputMaybe<Kilometer_Fee_Obj_Rel_Insert_Input>;
  kilometer_fee_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Kilometer_Fee_Entry_Max_Fields = {
  __typename?: 'kilometer_fee_entry_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kilometer?: Maybe<Scalars['numeric']['output']>;
  kilometer_fee_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer?: InputMaybe<Order_By>;
  kilometer_fee_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kilometer_Fee_Entry_Min_Fields = {
  __typename?: 'kilometer_fee_entry_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kilometer?: Maybe<Scalars['numeric']['output']>;
  kilometer_fee_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer?: InputMaybe<Order_By>;
  kilometer_fee_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Mutation_Response = {
  __typename?: 'kilometer_fee_entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Kilometer_Fee_Entry>;
};

/** on_conflict condition type for table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_On_Conflict = {
  constraint: Kilometer_Fee_Entry_Constraint;
  update_columns?: Array<Kilometer_Fee_Entry_Update_Column>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};

/** Ordering options when selecting data from "kilometer_fee_entry". */
export type Kilometer_Fee_Entry_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer?: InputMaybe<Order_By>;
  kilometer_fee?: InputMaybe<Kilometer_Fee_Order_By>;
  kilometer_fee_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kilometer_fee_entry */
export type Kilometer_Fee_Entry_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "kilometer_fee_entry" */
export enum Kilometer_Fee_Entry_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Kilometer = 'kilometer',
  /** column name */
  KilometerFeeId = 'kilometer_fee_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  kilometer_fee_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Kilometer_Fee_Entry_Stddev_Fields = {
  __typename?: 'kilometer_fee_entry_stddev_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Stddev_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Kilometer_Fee_Entry_Stddev_Pop_Fields = {
  __typename?: 'kilometer_fee_entry_stddev_pop_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Stddev_Pop_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Kilometer_Fee_Entry_Stddev_Samp_Fields = {
  __typename?: 'kilometer_fee_entry_stddev_samp_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Stddev_Samp_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kilometer_Fee_Entry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kilometer_Fee_Entry_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  kilometer_fee_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Kilometer_Fee_Entry_Sum_Fields = {
  __typename?: 'kilometer_fee_entry_sum_fields';
  kilometer?: Maybe<Scalars['numeric']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Sum_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "kilometer_fee_entry" */
export enum Kilometer_Fee_Entry_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Kilometer = 'kilometer',
  /** column name */
  KilometerFeeId = 'kilometer_fee_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type Kilometer_Fee_Entry_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Kilometer_Fee_Entry_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kilometer_Fee_Entry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kilometer_Fee_Entry_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Kilometer_Fee_Entry_Var_Pop_Fields = {
  __typename?: 'kilometer_fee_entry_var_pop_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Var_Pop_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Kilometer_Fee_Entry_Var_Samp_Fields = {
  __typename?: 'kilometer_fee_entry_var_samp_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Var_Samp_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Kilometer_Fee_Entry_Variance_Fields = {
  __typename?: 'kilometer_fee_entry_variance_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "kilometer_fee_entry" */
export type Kilometer_Fee_Entry_Variance_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "kilometer_fee" */
export type Kilometer_Fee_Inc_Input = {
  sort_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "kilometer_fee" */
export type Kilometer_Fee_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  entries?: InputMaybe<Kilometer_Fee_Entry_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
  vehicle_types?: InputMaybe<Kilometer_Fee_Vehicle_Type_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Kilometer_Fee_Max_Fields = {
  __typename?: 'kilometer_fee_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
};

/** aggregate min on columns */
export type Kilometer_Fee_Min_Fields = {
  __typename?: 'kilometer_fee_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
};

/** response of any mutation on the table "kilometer_fee" */
export type Kilometer_Fee_Mutation_Response = {
  __typename?: 'kilometer_fee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Kilometer_Fee>;
};

/** input type for inserting object relation for remote table "kilometer_fee" */
export type Kilometer_Fee_Obj_Rel_Insert_Input = {
  data: Kilometer_Fee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kilometer_Fee_On_Conflict>;
};

/** on_conflict condition type for table "kilometer_fee" */
export type Kilometer_Fee_On_Conflict = {
  constraint: Kilometer_Fee_Constraint;
  update_columns?: Array<Kilometer_Fee_Update_Column>;
  where?: InputMaybe<Kilometer_Fee_Bool_Exp>;
};

/** Ordering options when selecting data from "kilometer_fee". */
export type Kilometer_Fee_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  entries_aggregate?: InputMaybe<Kilometer_Fee_Entry_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  vehicle_types_aggregate?: InputMaybe<Kilometer_Fee_Vehicle_Type_Aggregate_Order_By>;
};

/** primary key columns input for table: kilometer_fee */
export type Kilometer_Fee_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "kilometer_fee" */
export enum Kilometer_Fee_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

/** input type for updating data in table "kilometer_fee" */
export type Kilometer_Fee_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate stddev on columns */
export type Kilometer_Fee_Stddev_Fields = {
  __typename?: 'kilometer_fee_stddev_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Kilometer_Fee_Stddev_Pop_Fields = {
  __typename?: 'kilometer_fee_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Kilometer_Fee_Stddev_Samp_Fields = {
  __typename?: 'kilometer_fee_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "kilometer_fee" */
export type Kilometer_Fee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kilometer_Fee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kilometer_Fee_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate sum on columns */
export type Kilometer_Fee_Sum_Fields = {
  __typename?: 'kilometer_fee_sum_fields';
  sort_order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "kilometer_fee" */
export enum Kilometer_Fee_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

export type Kilometer_Fee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Kilometer_Fee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kilometer_Fee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kilometer_Fee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Kilometer_Fee_Var_Pop_Fields = {
  __typename?: 'kilometer_fee_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Kilometer_Fee_Var_Samp_Fields = {
  __typename?: 'kilometer_fee_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Kilometer_Fee_Variance_Fields = {
  __typename?: 'kilometer_fee_variance_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type = {
  __typename?: 'kilometer_fee_vehicle_type';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  kilometer_fee: Kilometer_Fee;
  kilometer_fee_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vehicle_type: Vehicle_Type;
  vehicle_type_id: Scalars['uuid']['output'];
};

/** aggregated selection of "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Aggregate = {
  __typename?: 'kilometer_fee_vehicle_type_aggregate';
  aggregate?: Maybe<Kilometer_Fee_Vehicle_Type_Aggregate_Fields>;
  nodes: Array<Kilometer_Fee_Vehicle_Type>;
};

export type Kilometer_Fee_Vehicle_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kilometer_Fee_Vehicle_Type_Aggregate_Bool_Exp_Count>;
};

export type Kilometer_Fee_Vehicle_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Aggregate_Fields = {
  __typename?: 'kilometer_fee_vehicle_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Kilometer_Fee_Vehicle_Type_Max_Fields>;
  min?: Maybe<Kilometer_Fee_Vehicle_Type_Min_Fields>;
};


/** aggregate fields of "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kilometer_Fee_Vehicle_Type_Max_Order_By>;
  min?: InputMaybe<Kilometer_Fee_Vehicle_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Arr_Rel_Insert_Input = {
  data: Array<Kilometer_Fee_Vehicle_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kilometer_Fee_Vehicle_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kilometer_fee_vehicle_type". All fields are combined with a logical 'AND'. */
export type Kilometer_Fee_Vehicle_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Bool_Exp>>;
  _not?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kilometer_fee?: InputMaybe<Kilometer_Fee_Bool_Exp>;
  kilometer_fee_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle_type?: InputMaybe<Vehicle_Type_Bool_Exp>;
  vehicle_type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "kilometer_fee_vehicle_type" */
export enum Kilometer_Fee_Vehicle_Type_Constraint {
  /** unique or primary key constraint on columns "vehicle_type_id", "kilometer_fee_id" */
  KilometerFeeVehicleTypeKilometerFeeIdVehicleTypeIdKey = 'kilometer_fee_vehicle_type_kilometer_fee_id_vehicle_type_id_key',
  /** unique or primary key constraint on columns "id" */
  KilometerFeeVehicleTypePkey = 'kilometer_fee_vehicle_type_pkey'
}

/** input type for inserting data into table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kilometer_fee?: InputMaybe<Kilometer_Fee_Obj_Rel_Insert_Input>;
  kilometer_fee_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Vehicle_Type_Obj_Rel_Insert_Input>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Kilometer_Fee_Vehicle_Type_Max_Fields = {
  __typename?: 'kilometer_fee_vehicle_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kilometer_fee_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer_fee_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kilometer_Fee_Vehicle_Type_Min_Fields = {
  __typename?: 'kilometer_fee_vehicle_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kilometer_fee_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer_fee_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Mutation_Response = {
  __typename?: 'kilometer_fee_vehicle_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Kilometer_Fee_Vehicle_Type>;
};

/** on_conflict condition type for table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_On_Conflict = {
  constraint: Kilometer_Fee_Vehicle_Type_Constraint;
  update_columns?: Array<Kilometer_Fee_Vehicle_Type_Update_Column>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "kilometer_fee_vehicle_type". */
export type Kilometer_Fee_Vehicle_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer_fee?: InputMaybe<Kilometer_Fee_Order_By>;
  kilometer_fee_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type?: InputMaybe<Vehicle_Type_Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kilometer_fee_vehicle_type */
export type Kilometer_Fee_Vehicle_Type_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "kilometer_fee_vehicle_type" */
export enum Kilometer_Fee_Vehicle_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  KilometerFeeId = 'kilometer_fee_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

/** input type for updating data in table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kilometer_fee_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "kilometer_fee_vehicle_type" */
export type Kilometer_Fee_Vehicle_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kilometer_Fee_Vehicle_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kilometer_Fee_Vehicle_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  kilometer_fee_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "kilometer_fee_vehicle_type" */
export enum Kilometer_Fee_Vehicle_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  KilometerFeeId = 'kilometer_fee_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

export type Kilometer_Fee_Vehicle_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kilometer_Fee_Vehicle_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kilometer_Fee_Vehicle_Type_Bool_Exp;
};

/** columns and relationships of "log_book" */
export type Log_Book = {
  __typename?: 'log_book';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_company?: Maybe<Scalars['Boolean']['output']>;
  kilometer?: Maybe<Scalars['numeric']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start: Scalars['numeric']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid']['output'];
};

/** aggregated selection of "log_book" */
export type Log_Book_Aggregate = {
  __typename?: 'log_book_aggregate';
  aggregate?: Maybe<Log_Book_Aggregate_Fields>;
  nodes: Array<Log_Book>;
};

export type Log_Book_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Log_Book_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Log_Book_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Log_Book_Aggregate_Bool_Exp_Count>;
};

export type Log_Book_Aggregate_Bool_Exp_Bool_And = {
  arguments: Log_Book_Select_Column_Log_Book_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Log_Book_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Log_Book_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Log_Book_Select_Column_Log_Book_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Log_Book_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Log_Book_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Log_Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Log_Book_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "log_book" */
export type Log_Book_Aggregate_Fields = {
  __typename?: 'log_book_aggregate_fields';
  avg?: Maybe<Log_Book_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Log_Book_Max_Fields>;
  min?: Maybe<Log_Book_Min_Fields>;
  stddev?: Maybe<Log_Book_Stddev_Fields>;
  stddev_pop?: Maybe<Log_Book_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Log_Book_Stddev_Samp_Fields>;
  sum?: Maybe<Log_Book_Sum_Fields>;
  var_pop?: Maybe<Log_Book_Var_Pop_Fields>;
  var_samp?: Maybe<Log_Book_Var_Samp_Fields>;
  variance?: Maybe<Log_Book_Variance_Fields>;
};


/** aggregate fields of "log_book" */
export type Log_Book_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Log_Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "log_book" */
export type Log_Book_Aggregate_Order_By = {
  avg?: InputMaybe<Log_Book_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Log_Book_Max_Order_By>;
  min?: InputMaybe<Log_Book_Min_Order_By>;
  stddev?: InputMaybe<Log_Book_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Log_Book_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Log_Book_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Log_Book_Sum_Order_By>;
  var_pop?: InputMaybe<Log_Book_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Log_Book_Var_Samp_Order_By>;
  variance?: InputMaybe<Log_Book_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "log_book" */
export type Log_Book_Arr_Rel_Insert_Input = {
  data: Array<Log_Book_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Log_Book_On_Conflict>;
};

/** aggregate avg on columns */
export type Log_Book_Avg_Fields = {
  __typename?: 'log_book_avg_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "log_book" */
export type Log_Book_Avg_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "log_book". All fields are combined with a logical 'AND'. */
export type Log_Book_Bool_Exp = {
  _and?: InputMaybe<Array<Log_Book_Bool_Exp>>;
  _not?: InputMaybe<Log_Book_Bool_Exp>;
  _or?: InputMaybe<Array<Log_Book_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_company?: InputMaybe<Boolean_Comparison_Exp>;
  kilometer?: InputMaybe<Numeric_Comparison_Exp>;
  mileage_end?: InputMaybe<Numeric_Comparison_Exp>;
  mileage_start?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_book" */
export enum Log_Book_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogBookPkey = 'log_book_pkey'
}

/** input type for incrementing numeric columns in table "log_book" */
export type Log_Book_Inc_Input = {
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "log_book" */
export type Log_Book_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_company?: InputMaybe<Scalars['Boolean']['input']>;
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Log_Book_Max_Fields = {
  __typename?: 'log_book_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kilometer?: Maybe<Scalars['numeric']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "log_book" */
export type Log_Book_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Log_Book_Min_Fields = {
  __typename?: 'log_book_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  kilometer?: Maybe<Scalars['numeric']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "log_book" */
export type Log_Book_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "log_book" */
export type Log_Book_Mutation_Response = {
  __typename?: 'log_book_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Log_Book>;
};

/** on_conflict condition type for table "log_book" */
export type Log_Book_On_Conflict = {
  constraint: Log_Book_Constraint;
  update_columns?: Array<Log_Book_Update_Column>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};

/** Ordering options when selecting data from "log_book". */
export type Log_Book_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_company?: InputMaybe<Order_By>;
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: log_book */
export type Log_Book_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "log_book" */
export enum Log_Book_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompany = 'is_company',
  /** column name */
  Kilometer = 'kilometer',
  /** column name */
  MileageEnd = 'mileage_end',
  /** column name */
  MileageStart = 'mileage_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** select "log_book_aggregate_bool_exp_bool_and_arguments_columns" columns of table "log_book" */
export enum Log_Book_Select_Column_Log_Book_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompany = 'is_company'
}

/** select "log_book_aggregate_bool_exp_bool_or_arguments_columns" columns of table "log_book" */
export enum Log_Book_Select_Column_Log_Book_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompany = 'is_company'
}

/** input type for updating data in table "log_book" */
export type Log_Book_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_company?: InputMaybe<Scalars['Boolean']['input']>;
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Log_Book_Stddev_Fields = {
  __typename?: 'log_book_stddev_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "log_book" */
export type Log_Book_Stddev_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Log_Book_Stddev_Pop_Fields = {
  __typename?: 'log_book_stddev_pop_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "log_book" */
export type Log_Book_Stddev_Pop_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Log_Book_Stddev_Samp_Fields = {
  __typename?: 'log_book_stddev_samp_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "log_book" */
export type Log_Book_Stddev_Samp_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "log_book" */
export type Log_Book_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Log_Book_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Log_Book_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_company?: InputMaybe<Scalars['Boolean']['input']>;
  kilometer?: InputMaybe<Scalars['numeric']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Log_Book_Sum_Fields = {
  __typename?: 'log_book_sum_fields';
  kilometer?: Maybe<Scalars['numeric']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "log_book" */
export type Log_Book_Sum_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** update columns of table "log_book" */
export enum Log_Book_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsCompany = 'is_company',
  /** column name */
  Kilometer = 'kilometer',
  /** column name */
  MileageEnd = 'mileage_end',
  /** column name */
  MileageStart = 'mileage_start',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleId = 'vehicle_id'
}

export type Log_Book_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Log_Book_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Log_Book_Set_Input>;
  /** filter the rows which have to be updated */
  where: Log_Book_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Log_Book_Var_Pop_Fields = {
  __typename?: 'log_book_var_pop_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "log_book" */
export type Log_Book_Var_Pop_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Log_Book_Var_Samp_Fields = {
  __typename?: 'log_book_var_samp_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "log_book" */
export type Log_Book_Var_Samp_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Log_Book_Variance_Fields = {
  __typename?: 'log_book_variance_fields';
  kilometer?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "log_book" */
export type Log_Book_Variance_Order_By = {
  kilometer?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete single row from the table: "auth.providers" */
  deleteAuthProvider?: Maybe<AuthProviders>;
  /** delete single row from the table: "auth.provider_requests" */
  deleteAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** delete data from the table: "auth.provider_requests" */
  deleteAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** delete data from the table: "auth.providers" */
  deleteAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** delete single row from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** delete data from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  deleteAuthRole?: Maybe<AuthRoles>;
  /** delete data from the table: "auth.roles" */
  deleteAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** delete single row from the table: "auth.user_providers" */
  deleteAuthUserProvider?: Maybe<AuthUserProviders>;
  /** delete data from the table: "auth.user_providers" */
  deleteAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** delete single row from the table: "auth.user_roles" */
  deleteAuthUserRole?: Maybe<AuthUserRoles>;
  /** delete data from the table: "auth.user_roles" */
  deleteAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** delete single row from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** delete data from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** delete single row from the table: "storage.buckets" */
  deleteBucket?: Maybe<Buckets>;
  /** delete data from the table: "storage.buckets" */
  deleteBuckets?: Maybe<Buckets_Mutation_Response>;
  /** delete single row from the table: "storage.files" */
  deleteFile?: Maybe<Files>;
  /** delete data from the table: "storage.files" */
  deleteFiles?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "auth.users" */
  deleteUser?: Maybe<Users>;
  /** delete data from the table: "auth.users" */
  deleteUsers?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "base_category" */
  delete_base_category?: Maybe<Base_Category_Mutation_Response>;
  /** delete single row from the table: "base_category" */
  delete_base_category_by_pk?: Maybe<Base_Category>;
  /** delete data from the table: "contact" */
  delete_contact?: Maybe<Contact_Mutation_Response>;
  /** delete single row from the table: "contact" */
  delete_contact_by_pk?: Maybe<Contact>;
  /** delete data from the table: "contact_type" */
  delete_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** delete single row from the table: "contact_type" */
  delete_contact_type_by_pk?: Maybe<Contact_Type>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "entity_type" */
  delete_entity_type?: Maybe<Entity_Type_Mutation_Response>;
  /** delete single row from the table: "entity_type" */
  delete_entity_type_by_pk?: Maybe<Entity_Type>;
  /** delete data from the table: "fuel" */
  delete_fuel?: Maybe<Fuel_Mutation_Response>;
  /** delete single row from the table: "fuel" */
  delete_fuel_by_pk?: Maybe<Fuel>;
  /** delete data from the table: "gearshift" */
  delete_gearshift?: Maybe<Gearshift_Mutation_Response>;
  /** delete single row from the table: "gearshift" */
  delete_gearshift_by_pk?: Maybe<Gearshift>;
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>;
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>;
  /** delete data from the table: "invoice_item" */
  delete_invoice_item?: Maybe<Invoice_Item_Mutation_Response>;
  /** delete single row from the table: "invoice_item" */
  delete_invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** delete data from the table: "kilometer_fee" */
  delete_kilometer_fee?: Maybe<Kilometer_Fee_Mutation_Response>;
  /** delete single row from the table: "kilometer_fee" */
  delete_kilometer_fee_by_pk?: Maybe<Kilometer_Fee>;
  /** delete data from the table: "kilometer_fee_entry" */
  delete_kilometer_fee_entry?: Maybe<Kilometer_Fee_Entry_Mutation_Response>;
  /** delete single row from the table: "kilometer_fee_entry" */
  delete_kilometer_fee_entry_by_pk?: Maybe<Kilometer_Fee_Entry>;
  /** delete data from the table: "kilometer_fee_vehicle_type" */
  delete_kilometer_fee_vehicle_type?: Maybe<Kilometer_Fee_Vehicle_Type_Mutation_Response>;
  /** delete single row from the table: "kilometer_fee_vehicle_type" */
  delete_kilometer_fee_vehicle_type_by_pk?: Maybe<Kilometer_Fee_Vehicle_Type>;
  /** delete data from the table: "log_book" */
  delete_log_book?: Maybe<Log_Book_Mutation_Response>;
  /** delete single row from the table: "log_book" */
  delete_log_book_by_pk?: Maybe<Log_Book>;
  /** delete data from the table: "note" */
  delete_note?: Maybe<Note_Mutation_Response>;
  /** delete single row from the table: "note" */
  delete_note_by_pk?: Maybe<Note>;
  /** delete data from the table: "payment_medium" */
  delete_payment_medium?: Maybe<Payment_Medium_Mutation_Response>;
  /** delete single row from the table: "payment_medium" */
  delete_payment_medium_by_pk?: Maybe<Payment_Medium>;
  /** delete data from the table: "price_definition" */
  delete_price_definition?: Maybe<Price_Definition_Mutation_Response>;
  /** delete single row from the table: "price_definition" */
  delete_price_definition_by_pk?: Maybe<Price_Definition>;
  /** delete data from the table: "price_definition_entry" */
  delete_price_definition_entry?: Maybe<Price_Definition_Entry_Mutation_Response>;
  /** delete single row from the table: "price_definition_entry" */
  delete_price_definition_entry_by_pk?: Maybe<Price_Definition_Entry>;
  /** delete data from the table: "price_definition_vehicle_type" */
  delete_price_definition_vehicle_type?: Maybe<Price_Definition_Vehicle_Type_Mutation_Response>;
  /** delete single row from the table: "price_definition_vehicle_type" */
  delete_price_definition_vehicle_type_by_pk?: Maybe<Price_Definition_Vehicle_Type>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_eligible_for" */
  delete_product_eligible_for?: Maybe<Product_Eligible_For_Mutation_Response>;
  /** delete single row from the table: "product_eligible_for" */
  delete_product_eligible_for_by_pk?: Maybe<Product_Eligible_For>;
  /** delete data from the table: "product_type" */
  delete_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** delete single row from the table: "product_type" */
  delete_product_type_by_pk?: Maybe<Product_Type>;
  /** delete data from the table: "rental_contract" */
  delete_rental_contract?: Maybe<Rental_Contract_Mutation_Response>;
  /** delete data from the table: "rental_contract_accessory" */
  delete_rental_contract_accessory?: Maybe<Rental_Contract_Accessory_Mutation_Response>;
  /** delete single row from the table: "rental_contract_accessory" */
  delete_rental_contract_accessory_by_pk?: Maybe<Rental_Contract_Accessory>;
  /** delete single row from the table: "rental_contract" */
  delete_rental_contract_by_pk?: Maybe<Rental_Contract>;
  /** delete data from the table: "rental_contract_deposit" */
  delete_rental_contract_deposit?: Maybe<Rental_Contract_Deposit_Mutation_Response>;
  /** delete single row from the table: "rental_contract_deposit" */
  delete_rental_contract_deposit_by_pk?: Maybe<Rental_Contract_Deposit>;
  /** delete data from the table: "rental_contract_driver" */
  delete_rental_contract_driver?: Maybe<Rental_Contract_Driver_Mutation_Response>;
  /** delete single row from the table: "rental_contract_driver" */
  delete_rental_contract_driver_by_pk?: Maybe<Rental_Contract_Driver>;
  /** delete data from the table: "rental_contract_file" */
  delete_rental_contract_file?: Maybe<Rental_Contract_File_Mutation_Response>;
  /** delete single row from the table: "rental_contract_file" */
  delete_rental_contract_file_by_pk?: Maybe<Rental_Contract_File>;
  /** delete data from the table: "rental_contract_payment" */
  delete_rental_contract_payment?: Maybe<Rental_Contract_Payment_Mutation_Response>;
  /** delete single row from the table: "rental_contract_payment" */
  delete_rental_contract_payment_by_pk?: Maybe<Rental_Contract_Payment>;
  /** delete data from the table: "rental_state" */
  delete_rental_state?: Maybe<Rental_State_Mutation_Response>;
  /** delete single row from the table: "rental_state" */
  delete_rental_state_by_pk?: Maybe<Rental_State>;
  /** delete data from the table: "sequence" */
  delete_sequence?: Maybe<Sequence_Mutation_Response>;
  /** delete single row from the table: "sequence" */
  delete_sequence_by_pk?: Maybe<Sequence>;
  /** delete data from the table: "sequence_type" */
  delete_sequence_type?: Maybe<Sequence_Type_Mutation_Response>;
  /** delete single row from the table: "sequence_type" */
  delete_sequence_type_by_pk?: Maybe<Sequence_Type>;
  /** delete data from the table: "template_document" */
  delete_template_document?: Maybe<Template_Document_Mutation_Response>;
  /** delete single row from the table: "template_document" */
  delete_template_document_by_pk?: Maybe<Template_Document>;
  /** delete data from the table: "template_email" */
  delete_template_email?: Maybe<Template_Email_Mutation_Response>;
  /** delete single row from the table: "template_email" */
  delete_template_email_by_pk?: Maybe<Template_Email>;
  /** delete data from the table: "template_group" */
  delete_template_group?: Maybe<Template_Group_Mutation_Response>;
  /** delete single row from the table: "template_group" */
  delete_template_group_by_pk?: Maybe<Template_Group>;
  /** delete data from the table: "unit" */
  delete_unit?: Maybe<Unit_Mutation_Response>;
  /** delete single row from the table: "unit" */
  delete_unit_by_pk?: Maybe<Unit>;
  /** delete data from the table: "value_type" */
  delete_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** delete single row from the table: "value_type" */
  delete_value_type_by_pk?: Maybe<Value_Type>;
  /** delete data from the table: "vehicle" */
  delete_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** delete single row from the table: "vehicle" */
  delete_vehicle_by_pk?: Maybe<Vehicle>;
  /** delete data from the table: "vehicle_category" */
  delete_vehicle_category?: Maybe<Vehicle_Category_Mutation_Response>;
  /** delete single row from the table: "vehicle_category" */
  delete_vehicle_category_by_pk?: Maybe<Vehicle_Category>;
  /** delete data from the table: "vehicle_type" */
  delete_vehicle_type?: Maybe<Vehicle_Type_Mutation_Response>;
  /** delete single row from the table: "vehicle_type" */
  delete_vehicle_type_by_pk?: Maybe<Vehicle_Type>;
  /** delete data from the table: "vehicle_type_categories" */
  delete_vehicle_type_categories?: Maybe<Vehicle_Type_Categories_Mutation_Response>;
  /** delete single row from the table: "vehicle_type_categories" */
  delete_vehicle_type_categories_by_pk?: Maybe<Vehicle_Type_Categories>;
  /** insert a single row into the table: "auth.providers" */
  insertAuthProvider?: Maybe<AuthProviders>;
  /** insert a single row into the table: "auth.provider_requests" */
  insertAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** insert data into the table: "auth.provider_requests" */
  insertAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** insert data into the table: "auth.providers" */
  insertAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insertAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** insert a single row into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** insert data into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** insert data into the table: "auth.refresh_tokens" */
  insertAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insertAuthRole?: Maybe<AuthRoles>;
  /** insert data into the table: "auth.roles" */
  insertAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** insert a single row into the table: "auth.user_providers" */
  insertAuthUserProvider?: Maybe<AuthUserProviders>;
  /** insert data into the table: "auth.user_providers" */
  insertAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** insert a single row into the table: "auth.user_roles" */
  insertAuthUserRole?: Maybe<AuthUserRoles>;
  /** insert data into the table: "auth.user_roles" */
  insertAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** insert a single row into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** insert data into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** insert a single row into the table: "storage.buckets" */
  insertBucket?: Maybe<Buckets>;
  /** insert data into the table: "storage.buckets" */
  insertBuckets?: Maybe<Buckets_Mutation_Response>;
  /** insert a single row into the table: "storage.files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage.files" */
  insertFiles?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "auth.users" */
  insertUser?: Maybe<Users>;
  /** insert data into the table: "auth.users" */
  insertUsers?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "base_category" */
  insert_base_category?: Maybe<Base_Category_Mutation_Response>;
  /** insert a single row into the table: "base_category" */
  insert_base_category_one?: Maybe<Base_Category>;
  /** insert data into the table: "contact" */
  insert_contact?: Maybe<Contact_Mutation_Response>;
  /** insert a single row into the table: "contact" */
  insert_contact_one?: Maybe<Contact>;
  /** insert data into the table: "contact_type" */
  insert_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** insert a single row into the table: "contact_type" */
  insert_contact_type_one?: Maybe<Contact_Type>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "entity_type" */
  insert_entity_type?: Maybe<Entity_Type_Mutation_Response>;
  /** insert a single row into the table: "entity_type" */
  insert_entity_type_one?: Maybe<Entity_Type>;
  /** insert data into the table: "fuel" */
  insert_fuel?: Maybe<Fuel_Mutation_Response>;
  /** insert a single row into the table: "fuel" */
  insert_fuel_one?: Maybe<Fuel>;
  /** insert data into the table: "gearshift" */
  insert_gearshift?: Maybe<Gearshift_Mutation_Response>;
  /** insert a single row into the table: "gearshift" */
  insert_gearshift_one?: Maybe<Gearshift>;
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>;
  /** insert data into the table: "invoice_item" */
  insert_invoice_item?: Maybe<Invoice_Item_Mutation_Response>;
  /** insert a single row into the table: "invoice_item" */
  insert_invoice_item_one?: Maybe<Invoice_Item>;
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>;
  /** insert data into the table: "kilometer_fee" */
  insert_kilometer_fee?: Maybe<Kilometer_Fee_Mutation_Response>;
  /** insert data into the table: "kilometer_fee_entry" */
  insert_kilometer_fee_entry?: Maybe<Kilometer_Fee_Entry_Mutation_Response>;
  /** insert a single row into the table: "kilometer_fee_entry" */
  insert_kilometer_fee_entry_one?: Maybe<Kilometer_Fee_Entry>;
  /** insert a single row into the table: "kilometer_fee" */
  insert_kilometer_fee_one?: Maybe<Kilometer_Fee>;
  /** insert data into the table: "kilometer_fee_vehicle_type" */
  insert_kilometer_fee_vehicle_type?: Maybe<Kilometer_Fee_Vehicle_Type_Mutation_Response>;
  /** insert a single row into the table: "kilometer_fee_vehicle_type" */
  insert_kilometer_fee_vehicle_type_one?: Maybe<Kilometer_Fee_Vehicle_Type>;
  /** insert data into the table: "log_book" */
  insert_log_book?: Maybe<Log_Book_Mutation_Response>;
  /** insert a single row into the table: "log_book" */
  insert_log_book_one?: Maybe<Log_Book>;
  /** insert data into the table: "note" */
  insert_note?: Maybe<Note_Mutation_Response>;
  /** insert a single row into the table: "note" */
  insert_note_one?: Maybe<Note>;
  /** insert data into the table: "payment_medium" */
  insert_payment_medium?: Maybe<Payment_Medium_Mutation_Response>;
  /** insert a single row into the table: "payment_medium" */
  insert_payment_medium_one?: Maybe<Payment_Medium>;
  /** insert data into the table: "price_definition" */
  insert_price_definition?: Maybe<Price_Definition_Mutation_Response>;
  /** insert data into the table: "price_definition_entry" */
  insert_price_definition_entry?: Maybe<Price_Definition_Entry_Mutation_Response>;
  /** insert a single row into the table: "price_definition_entry" */
  insert_price_definition_entry_one?: Maybe<Price_Definition_Entry>;
  /** insert a single row into the table: "price_definition" */
  insert_price_definition_one?: Maybe<Price_Definition>;
  /** insert data into the table: "price_definition_vehicle_type" */
  insert_price_definition_vehicle_type?: Maybe<Price_Definition_Vehicle_Type_Mutation_Response>;
  /** insert a single row into the table: "price_definition_vehicle_type" */
  insert_price_definition_vehicle_type_one?: Maybe<Price_Definition_Vehicle_Type>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "product_eligible_for" */
  insert_product_eligible_for?: Maybe<Product_Eligible_For_Mutation_Response>;
  /** insert a single row into the table: "product_eligible_for" */
  insert_product_eligible_for_one?: Maybe<Product_Eligible_For>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_type" */
  insert_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** insert a single row into the table: "product_type" */
  insert_product_type_one?: Maybe<Product_Type>;
  /** insert data into the table: "rental_contract" */
  insert_rental_contract?: Maybe<Rental_Contract_Mutation_Response>;
  /** insert data into the table: "rental_contract_accessory" */
  insert_rental_contract_accessory?: Maybe<Rental_Contract_Accessory_Mutation_Response>;
  /** insert a single row into the table: "rental_contract_accessory" */
  insert_rental_contract_accessory_one?: Maybe<Rental_Contract_Accessory>;
  /** insert data into the table: "rental_contract_deposit" */
  insert_rental_contract_deposit?: Maybe<Rental_Contract_Deposit_Mutation_Response>;
  /** insert a single row into the table: "rental_contract_deposit" */
  insert_rental_contract_deposit_one?: Maybe<Rental_Contract_Deposit>;
  /** insert data into the table: "rental_contract_driver" */
  insert_rental_contract_driver?: Maybe<Rental_Contract_Driver_Mutation_Response>;
  /** insert a single row into the table: "rental_contract_driver" */
  insert_rental_contract_driver_one?: Maybe<Rental_Contract_Driver>;
  /** insert data into the table: "rental_contract_file" */
  insert_rental_contract_file?: Maybe<Rental_Contract_File_Mutation_Response>;
  /** insert a single row into the table: "rental_contract_file" */
  insert_rental_contract_file_one?: Maybe<Rental_Contract_File>;
  /** insert a single row into the table: "rental_contract" */
  insert_rental_contract_one?: Maybe<Rental_Contract>;
  /** insert data into the table: "rental_contract_payment" */
  insert_rental_contract_payment?: Maybe<Rental_Contract_Payment_Mutation_Response>;
  /** insert a single row into the table: "rental_contract_payment" */
  insert_rental_contract_payment_one?: Maybe<Rental_Contract_Payment>;
  /** insert data into the table: "rental_state" */
  insert_rental_state?: Maybe<Rental_State_Mutation_Response>;
  /** insert a single row into the table: "rental_state" */
  insert_rental_state_one?: Maybe<Rental_State>;
  /** insert data into the table: "sequence" */
  insert_sequence?: Maybe<Sequence_Mutation_Response>;
  /** insert a single row into the table: "sequence" */
  insert_sequence_one?: Maybe<Sequence>;
  /** insert data into the table: "sequence_type" */
  insert_sequence_type?: Maybe<Sequence_Type_Mutation_Response>;
  /** insert a single row into the table: "sequence_type" */
  insert_sequence_type_one?: Maybe<Sequence_Type>;
  /** insert data into the table: "template_document" */
  insert_template_document?: Maybe<Template_Document_Mutation_Response>;
  /** insert a single row into the table: "template_document" */
  insert_template_document_one?: Maybe<Template_Document>;
  /** insert data into the table: "template_email" */
  insert_template_email?: Maybe<Template_Email_Mutation_Response>;
  /** insert a single row into the table: "template_email" */
  insert_template_email_one?: Maybe<Template_Email>;
  /** insert data into the table: "template_group" */
  insert_template_group?: Maybe<Template_Group_Mutation_Response>;
  /** insert a single row into the table: "template_group" */
  insert_template_group_one?: Maybe<Template_Group>;
  /** insert data into the table: "unit" */
  insert_unit?: Maybe<Unit_Mutation_Response>;
  /** insert a single row into the table: "unit" */
  insert_unit_one?: Maybe<Unit>;
  /** insert data into the table: "value_type" */
  insert_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** insert a single row into the table: "value_type" */
  insert_value_type_one?: Maybe<Value_Type>;
  /** insert data into the table: "vehicle" */
  insert_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** insert data into the table: "vehicle_category" */
  insert_vehicle_category?: Maybe<Vehicle_Category_Mutation_Response>;
  /** insert a single row into the table: "vehicle_category" */
  insert_vehicle_category_one?: Maybe<Vehicle_Category>;
  /** insert a single row into the table: "vehicle" */
  insert_vehicle_one?: Maybe<Vehicle>;
  /** insert data into the table: "vehicle_type" */
  insert_vehicle_type?: Maybe<Vehicle_Type_Mutation_Response>;
  /** insert data into the table: "vehicle_type_categories" */
  insert_vehicle_type_categories?: Maybe<Vehicle_Type_Categories_Mutation_Response>;
  /** insert a single row into the table: "vehicle_type_categories" */
  insert_vehicle_type_categories_one?: Maybe<Vehicle_Type_Categories>;
  /** insert a single row into the table: "vehicle_type" */
  insert_vehicle_type_one?: Maybe<Vehicle_Type>;
  /** update single row of the table: "auth.providers" */
  updateAuthProvider?: Maybe<AuthProviders>;
  /** update single row of the table: "auth.provider_requests" */
  updateAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** update data of the table: "auth.provider_requests" */
  updateAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** update data of the table: "auth.providers" */
  updateAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  updateAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** update single row of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** update data of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** update data of the table: "auth.refresh_tokens" */
  updateAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  updateAuthRole?: Maybe<AuthRoles>;
  /** update data of the table: "auth.roles" */
  updateAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** update single row of the table: "auth.user_providers" */
  updateAuthUserProvider?: Maybe<AuthUserProviders>;
  /** update data of the table: "auth.user_providers" */
  updateAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** update single row of the table: "auth.user_roles" */
  updateAuthUserRole?: Maybe<AuthUserRoles>;
  /** update data of the table: "auth.user_roles" */
  updateAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** update single row of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** update data of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** update single row of the table: "storage.buckets" */
  updateBucket?: Maybe<Buckets>;
  /** update data of the table: "storage.buckets" */
  updateBuckets?: Maybe<Buckets_Mutation_Response>;
  /** update single row of the table: "storage.files" */
  updateFile?: Maybe<Files>;
  /** update data of the table: "storage.files" */
  updateFiles?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "auth.users" */
  updateUser?: Maybe<Users>;
  /** update data of the table: "auth.users" */
  updateUsers?: Maybe<Users_Mutation_Response>;
  /** update multiples rows of table: "auth.provider_requests" */
  update_authProviderRequests_many?: Maybe<Array<Maybe<AuthProviderRequests_Mutation_Response>>>;
  /** update multiples rows of table: "auth.providers" */
  update_authProviders_many?: Maybe<Array<Maybe<AuthProviders_Mutation_Response>>>;
  /** update multiples rows of table: "auth.refresh_token_types" */
  update_authRefreshTokenTypes_many?: Maybe<Array<Maybe<AuthRefreshTokenTypes_Mutation_Response>>>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_authRefreshTokens_many?: Maybe<Array<Maybe<AuthRefreshTokens_Mutation_Response>>>;
  /** update multiples rows of table: "auth.roles" */
  update_authRoles_many?: Maybe<Array<Maybe<AuthRoles_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_providers" */
  update_authUserProviders_many?: Maybe<Array<Maybe<AuthUserProviders_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_roles" */
  update_authUserRoles_many?: Maybe<Array<Maybe<AuthUserRoles_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_security_keys" */
  update_authUserSecurityKeys_many?: Maybe<Array<Maybe<AuthUserSecurityKeys_Mutation_Response>>>;
  /** update data of the table: "base_category" */
  update_base_category?: Maybe<Base_Category_Mutation_Response>;
  /** update single row of the table: "base_category" */
  update_base_category_by_pk?: Maybe<Base_Category>;
  /** update multiples rows of table: "base_category" */
  update_base_category_many?: Maybe<Array<Maybe<Base_Category_Mutation_Response>>>;
  /** update multiples rows of table: "storage.buckets" */
  update_buckets_many?: Maybe<Array<Maybe<Buckets_Mutation_Response>>>;
  /** update data of the table: "contact" */
  update_contact?: Maybe<Contact_Mutation_Response>;
  /** update single row of the table: "contact" */
  update_contact_by_pk?: Maybe<Contact>;
  /** update multiples rows of table: "contact" */
  update_contact_many?: Maybe<Array<Maybe<Contact_Mutation_Response>>>;
  /** update data of the table: "contact_type" */
  update_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** update single row of the table: "contact_type" */
  update_contact_type_by_pk?: Maybe<Contact_Type>;
  /** update multiples rows of table: "contact_type" */
  update_contact_type_many?: Maybe<Array<Maybe<Contact_Type_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "entity_type" */
  update_entity_type?: Maybe<Entity_Type_Mutation_Response>;
  /** update single row of the table: "entity_type" */
  update_entity_type_by_pk?: Maybe<Entity_Type>;
  /** update multiples rows of table: "entity_type" */
  update_entity_type_many?: Maybe<Array<Maybe<Entity_Type_Mutation_Response>>>;
  /** update multiples rows of table: "storage.files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "fuel" */
  update_fuel?: Maybe<Fuel_Mutation_Response>;
  /** update single row of the table: "fuel" */
  update_fuel_by_pk?: Maybe<Fuel>;
  /** update multiples rows of table: "fuel" */
  update_fuel_many?: Maybe<Array<Maybe<Fuel_Mutation_Response>>>;
  /** update data of the table: "gearshift" */
  update_gearshift?: Maybe<Gearshift_Mutation_Response>;
  /** update single row of the table: "gearshift" */
  update_gearshift_by_pk?: Maybe<Gearshift>;
  /** update multiples rows of table: "gearshift" */
  update_gearshift_many?: Maybe<Array<Maybe<Gearshift_Mutation_Response>>>;
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>;
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>;
  /** update data of the table: "invoice_item" */
  update_invoice_item?: Maybe<Invoice_Item_Mutation_Response>;
  /** update single row of the table: "invoice_item" */
  update_invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** update multiples rows of table: "invoice_item" */
  update_invoice_item_many?: Maybe<Array<Maybe<Invoice_Item_Mutation_Response>>>;
  /** update multiples rows of table: "invoice" */
  update_invoice_many?: Maybe<Array<Maybe<Invoice_Mutation_Response>>>;
  /** update data of the table: "kilometer_fee" */
  update_kilometer_fee?: Maybe<Kilometer_Fee_Mutation_Response>;
  /** update single row of the table: "kilometer_fee" */
  update_kilometer_fee_by_pk?: Maybe<Kilometer_Fee>;
  /** update data of the table: "kilometer_fee_entry" */
  update_kilometer_fee_entry?: Maybe<Kilometer_Fee_Entry_Mutation_Response>;
  /** update single row of the table: "kilometer_fee_entry" */
  update_kilometer_fee_entry_by_pk?: Maybe<Kilometer_Fee_Entry>;
  /** update multiples rows of table: "kilometer_fee_entry" */
  update_kilometer_fee_entry_many?: Maybe<Array<Maybe<Kilometer_Fee_Entry_Mutation_Response>>>;
  /** update multiples rows of table: "kilometer_fee" */
  update_kilometer_fee_many?: Maybe<Array<Maybe<Kilometer_Fee_Mutation_Response>>>;
  /** update data of the table: "kilometer_fee_vehicle_type" */
  update_kilometer_fee_vehicle_type?: Maybe<Kilometer_Fee_Vehicle_Type_Mutation_Response>;
  /** update single row of the table: "kilometer_fee_vehicle_type" */
  update_kilometer_fee_vehicle_type_by_pk?: Maybe<Kilometer_Fee_Vehicle_Type>;
  /** update multiples rows of table: "kilometer_fee_vehicle_type" */
  update_kilometer_fee_vehicle_type_many?: Maybe<Array<Maybe<Kilometer_Fee_Vehicle_Type_Mutation_Response>>>;
  /** update data of the table: "log_book" */
  update_log_book?: Maybe<Log_Book_Mutation_Response>;
  /** update single row of the table: "log_book" */
  update_log_book_by_pk?: Maybe<Log_Book>;
  /** update multiples rows of table: "log_book" */
  update_log_book_many?: Maybe<Array<Maybe<Log_Book_Mutation_Response>>>;
  /** update data of the table: "note" */
  update_note?: Maybe<Note_Mutation_Response>;
  /** update single row of the table: "note" */
  update_note_by_pk?: Maybe<Note>;
  /** update multiples rows of table: "note" */
  update_note_many?: Maybe<Array<Maybe<Note_Mutation_Response>>>;
  /** update data of the table: "payment_medium" */
  update_payment_medium?: Maybe<Payment_Medium_Mutation_Response>;
  /** update single row of the table: "payment_medium" */
  update_payment_medium_by_pk?: Maybe<Payment_Medium>;
  /** update multiples rows of table: "payment_medium" */
  update_payment_medium_many?: Maybe<Array<Maybe<Payment_Medium_Mutation_Response>>>;
  /** update data of the table: "price_definition" */
  update_price_definition?: Maybe<Price_Definition_Mutation_Response>;
  /** update single row of the table: "price_definition" */
  update_price_definition_by_pk?: Maybe<Price_Definition>;
  /** update data of the table: "price_definition_entry" */
  update_price_definition_entry?: Maybe<Price_Definition_Entry_Mutation_Response>;
  /** update single row of the table: "price_definition_entry" */
  update_price_definition_entry_by_pk?: Maybe<Price_Definition_Entry>;
  /** update multiples rows of table: "price_definition_entry" */
  update_price_definition_entry_many?: Maybe<Array<Maybe<Price_Definition_Entry_Mutation_Response>>>;
  /** update multiples rows of table: "price_definition" */
  update_price_definition_many?: Maybe<Array<Maybe<Price_Definition_Mutation_Response>>>;
  /** update data of the table: "price_definition_vehicle_type" */
  update_price_definition_vehicle_type?: Maybe<Price_Definition_Vehicle_Type_Mutation_Response>;
  /** update single row of the table: "price_definition_vehicle_type" */
  update_price_definition_vehicle_type_by_pk?: Maybe<Price_Definition_Vehicle_Type>;
  /** update multiples rows of table: "price_definition_vehicle_type" */
  update_price_definition_vehicle_type_many?: Maybe<Array<Maybe<Price_Definition_Vehicle_Type_Mutation_Response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "product_eligible_for" */
  update_product_eligible_for?: Maybe<Product_Eligible_For_Mutation_Response>;
  /** update single row of the table: "product_eligible_for" */
  update_product_eligible_for_by_pk?: Maybe<Product_Eligible_For>;
  /** update multiples rows of table: "product_eligible_for" */
  update_product_eligible_for_many?: Maybe<Array<Maybe<Product_Eligible_For_Mutation_Response>>>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "product_type" */
  update_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** update single row of the table: "product_type" */
  update_product_type_by_pk?: Maybe<Product_Type>;
  /** update multiples rows of table: "product_type" */
  update_product_type_many?: Maybe<Array<Maybe<Product_Type_Mutation_Response>>>;
  /** update data of the table: "rental_contract" */
  update_rental_contract?: Maybe<Rental_Contract_Mutation_Response>;
  /** update data of the table: "rental_contract_accessory" */
  update_rental_contract_accessory?: Maybe<Rental_Contract_Accessory_Mutation_Response>;
  /** update single row of the table: "rental_contract_accessory" */
  update_rental_contract_accessory_by_pk?: Maybe<Rental_Contract_Accessory>;
  /** update multiples rows of table: "rental_contract_accessory" */
  update_rental_contract_accessory_many?: Maybe<Array<Maybe<Rental_Contract_Accessory_Mutation_Response>>>;
  /** update single row of the table: "rental_contract" */
  update_rental_contract_by_pk?: Maybe<Rental_Contract>;
  /** update data of the table: "rental_contract_deposit" */
  update_rental_contract_deposit?: Maybe<Rental_Contract_Deposit_Mutation_Response>;
  /** update single row of the table: "rental_contract_deposit" */
  update_rental_contract_deposit_by_pk?: Maybe<Rental_Contract_Deposit>;
  /** update multiples rows of table: "rental_contract_deposit" */
  update_rental_contract_deposit_many?: Maybe<Array<Maybe<Rental_Contract_Deposit_Mutation_Response>>>;
  /** update data of the table: "rental_contract_driver" */
  update_rental_contract_driver?: Maybe<Rental_Contract_Driver_Mutation_Response>;
  /** update single row of the table: "rental_contract_driver" */
  update_rental_contract_driver_by_pk?: Maybe<Rental_Contract_Driver>;
  /** update multiples rows of table: "rental_contract_driver" */
  update_rental_contract_driver_many?: Maybe<Array<Maybe<Rental_Contract_Driver_Mutation_Response>>>;
  /** update data of the table: "rental_contract_file" */
  update_rental_contract_file?: Maybe<Rental_Contract_File_Mutation_Response>;
  /** update single row of the table: "rental_contract_file" */
  update_rental_contract_file_by_pk?: Maybe<Rental_Contract_File>;
  /** update multiples rows of table: "rental_contract_file" */
  update_rental_contract_file_many?: Maybe<Array<Maybe<Rental_Contract_File_Mutation_Response>>>;
  /** update multiples rows of table: "rental_contract" */
  update_rental_contract_many?: Maybe<Array<Maybe<Rental_Contract_Mutation_Response>>>;
  /** update data of the table: "rental_contract_payment" */
  update_rental_contract_payment?: Maybe<Rental_Contract_Payment_Mutation_Response>;
  /** update single row of the table: "rental_contract_payment" */
  update_rental_contract_payment_by_pk?: Maybe<Rental_Contract_Payment>;
  /** update multiples rows of table: "rental_contract_payment" */
  update_rental_contract_payment_many?: Maybe<Array<Maybe<Rental_Contract_Payment_Mutation_Response>>>;
  /** update data of the table: "rental_state" */
  update_rental_state?: Maybe<Rental_State_Mutation_Response>;
  /** update single row of the table: "rental_state" */
  update_rental_state_by_pk?: Maybe<Rental_State>;
  /** update multiples rows of table: "rental_state" */
  update_rental_state_many?: Maybe<Array<Maybe<Rental_State_Mutation_Response>>>;
  /** update data of the table: "sequence" */
  update_sequence?: Maybe<Sequence_Mutation_Response>;
  /** update single row of the table: "sequence" */
  update_sequence_by_pk?: Maybe<Sequence>;
  /** update multiples rows of table: "sequence" */
  update_sequence_many?: Maybe<Array<Maybe<Sequence_Mutation_Response>>>;
  /** update data of the table: "sequence_type" */
  update_sequence_type?: Maybe<Sequence_Type_Mutation_Response>;
  /** update single row of the table: "sequence_type" */
  update_sequence_type_by_pk?: Maybe<Sequence_Type>;
  /** update multiples rows of table: "sequence_type" */
  update_sequence_type_many?: Maybe<Array<Maybe<Sequence_Type_Mutation_Response>>>;
  /** update data of the table: "template_document" */
  update_template_document?: Maybe<Template_Document_Mutation_Response>;
  /** update single row of the table: "template_document" */
  update_template_document_by_pk?: Maybe<Template_Document>;
  /** update multiples rows of table: "template_document" */
  update_template_document_many?: Maybe<Array<Maybe<Template_Document_Mutation_Response>>>;
  /** update data of the table: "template_email" */
  update_template_email?: Maybe<Template_Email_Mutation_Response>;
  /** update single row of the table: "template_email" */
  update_template_email_by_pk?: Maybe<Template_Email>;
  /** update multiples rows of table: "template_email" */
  update_template_email_many?: Maybe<Array<Maybe<Template_Email_Mutation_Response>>>;
  /** update data of the table: "template_group" */
  update_template_group?: Maybe<Template_Group_Mutation_Response>;
  /** update single row of the table: "template_group" */
  update_template_group_by_pk?: Maybe<Template_Group>;
  /** update multiples rows of table: "template_group" */
  update_template_group_many?: Maybe<Array<Maybe<Template_Group_Mutation_Response>>>;
  /** update data of the table: "unit" */
  update_unit?: Maybe<Unit_Mutation_Response>;
  /** update single row of the table: "unit" */
  update_unit_by_pk?: Maybe<Unit>;
  /** update multiples rows of table: "unit" */
  update_unit_many?: Maybe<Array<Maybe<Unit_Mutation_Response>>>;
  /** update multiples rows of table: "auth.users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "value_type" */
  update_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** update single row of the table: "value_type" */
  update_value_type_by_pk?: Maybe<Value_Type>;
  /** update multiples rows of table: "value_type" */
  update_value_type_many?: Maybe<Array<Maybe<Value_Type_Mutation_Response>>>;
  /** update data of the table: "vehicle" */
  update_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** update single row of the table: "vehicle" */
  update_vehicle_by_pk?: Maybe<Vehicle>;
  /** update data of the table: "vehicle_category" */
  update_vehicle_category?: Maybe<Vehicle_Category_Mutation_Response>;
  /** update single row of the table: "vehicle_category" */
  update_vehicle_category_by_pk?: Maybe<Vehicle_Category>;
  /** update multiples rows of table: "vehicle_category" */
  update_vehicle_category_many?: Maybe<Array<Maybe<Vehicle_Category_Mutation_Response>>>;
  /** update multiples rows of table: "vehicle" */
  update_vehicle_many?: Maybe<Array<Maybe<Vehicle_Mutation_Response>>>;
  /** update data of the table: "vehicle_type" */
  update_vehicle_type?: Maybe<Vehicle_Type_Mutation_Response>;
  /** update single row of the table: "vehicle_type" */
  update_vehicle_type_by_pk?: Maybe<Vehicle_Type>;
  /** update data of the table: "vehicle_type_categories" */
  update_vehicle_type_categories?: Maybe<Vehicle_Type_Categories_Mutation_Response>;
  /** update single row of the table: "vehicle_type_categories" */
  update_vehicle_type_categories_by_pk?: Maybe<Vehicle_Type_Categories>;
  /** update multiples rows of table: "vehicle_type_categories" */
  update_vehicle_type_categories_many?: Maybe<Array<Maybe<Vehicle_Type_Categories_Mutation_Response>>>;
  /** update multiples rows of table: "vehicle_type" */
  update_vehicle_type_many?: Maybe<Array<Maybe<Vehicle_Type_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDeleteAuthProviderArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthProviderRequestsArgs = {
  where: AuthProviderRequests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthProvidersArgs = {
  where: AuthProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenTypesArgs = {
  where: AuthRefreshTokenTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthRoleArgs = {
  role: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthRolesArgs = {
  where: AuthRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserProvidersArgs = {
  where: AuthUserProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserRolesArgs = {
  where: AuthUserRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAuthUserSecurityKeysArgs = {
  where: AuthUserSecurityKeys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteBucketArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteBucketsArgs = {
  where: Buckets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteFilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_CategoryArgs = {
  where: Base_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Category_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ContactArgs = {
  where: Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_TypeArgs = {
  where: Contact_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Entity_TypeArgs = {
  where: Entity_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entity_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FuelArgs = {
  where: Fuel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fuel_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GearshiftArgs = {
  where: Gearshift_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gearshift_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Invoice_ItemArgs = {
  where: Invoice_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Kilometer_FeeArgs = {
  where: Kilometer_Fee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kilometer_Fee_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Kilometer_Fee_EntryArgs = {
  where: Kilometer_Fee_Entry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kilometer_Fee_Entry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Kilometer_Fee_Vehicle_TypeArgs = {
  where: Kilometer_Fee_Vehicle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kilometer_Fee_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Log_BookArgs = {
  where: Log_Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Log_Book_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NoteArgs = {
  where: Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Note_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_MediumArgs = {
  where: Payment_Medium_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Medium_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Price_DefinitionArgs = {
  where: Price_Definition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Price_Definition_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Price_Definition_EntryArgs = {
  where: Price_Definition_Entry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Price_Definition_Entry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Price_Definition_Vehicle_TypeArgs = {
  where: Price_Definition_Vehicle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Price_Definition_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_Eligible_ForArgs = {
  where: Product_Eligible_For_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Eligible_For_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Product_TypeArgs = {
  where: Product_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_ContractArgs = {
  where: Rental_Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_AccessoryArgs = {
  where: Rental_Contract_Accessory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_Accessory_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_DepositArgs = {
  where: Rental_Contract_Deposit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_Deposit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_DriverArgs = {
  where: Rental_Contract_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_Driver_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_FileArgs = {
  where: Rental_Contract_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_PaymentArgs = {
  where: Rental_Contract_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_Contract_Payment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rental_StateArgs = {
  where: Rental_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rental_State_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SequenceArgs = {
  where: Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_TypeArgs = {
  where: Sequence_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Template_DocumentArgs = {
  where: Template_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Template_Document_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Template_EmailArgs = {
  where: Template_Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Template_Email_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Template_GroupArgs = {
  where: Template_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Template_Group_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UnitArgs = {
  where: Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Value_TypeArgs = {
  where: Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Value_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VehicleArgs = {
  where: Vehicle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_CategoryArgs = {
  where: Vehicle_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Category_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_TypeArgs = {
  where: Vehicle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Type_CategoriesArgs = {
  where: Vehicle_Type_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicle_Type_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsertAuthProviderArgs = {
  object: AuthProviders_Insert_Input;
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthProviderRequestArgs = {
  object: AuthProviderRequests_Insert_Input;
  on_conflict?: InputMaybe<AuthProviderRequests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthProviderRequestsArgs = {
  objects: Array<AuthProviderRequests_Insert_Input>;
  on_conflict?: InputMaybe<AuthProviderRequests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthProvidersArgs = {
  objects: Array<AuthProviders_Insert_Input>;
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenArgs = {
  object: AuthRefreshTokens_Insert_Input;
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenTypeArgs = {
  object: AuthRefreshTokenTypes_Insert_Input;
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenTypesArgs = {
  objects: Array<AuthRefreshTokenTypes_Insert_Input>;
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRefreshTokensArgs = {
  objects: Array<AuthRefreshTokens_Insert_Input>;
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRoleArgs = {
  object: AuthRoles_Insert_Input;
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthRolesArgs = {
  objects: Array<AuthRoles_Insert_Input>;
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserProviderArgs = {
  object: AuthUserProviders_Insert_Input;
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserProvidersArgs = {
  objects: Array<AuthUserProviders_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserRoleArgs = {
  object: AuthUserRoles_Insert_Input;
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserRolesArgs = {
  objects: Array<AuthUserRoles_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserSecurityKeyArgs = {
  object: AuthUserSecurityKeys_Insert_Input;
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthUserSecurityKeysArgs = {
  objects: Array<AuthUserSecurityKeys_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertBucketArgs = {
  object: Buckets_Insert_Input;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertBucketsArgs = {
  objects: Array<Buckets_Insert_Input>;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertFileArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertFilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUserArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_CategoryArgs = {
  objects: Array<Base_Category_Insert_Input>;
  on_conflict?: InputMaybe<Base_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Category_OneArgs = {
  object: Base_Category_Insert_Input;
  on_conflict?: InputMaybe<Base_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactArgs = {
  objects: Array<Contact_Insert_Input>;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_OneArgs = {
  object: Contact_Insert_Input;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_TypeArgs = {
  objects: Array<Contact_Type_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Type_OneArgs = {
  object: Contact_Type_Insert_Input;
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_TypeArgs = {
  objects: Array<Entity_Type_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_Type_OneArgs = {
  object: Entity_Type_Insert_Input;
  on_conflict?: InputMaybe<Entity_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FuelArgs = {
  objects: Array<Fuel_Insert_Input>;
  on_conflict?: InputMaybe<Fuel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fuel_OneArgs = {
  object: Fuel_Insert_Input;
  on_conflict?: InputMaybe<Fuel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GearshiftArgs = {
  objects: Array<Gearshift_Insert_Input>;
  on_conflict?: InputMaybe<Gearshift_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gearshift_OneArgs = {
  object: Gearshift_Insert_Input;
  on_conflict?: InputMaybe<Gearshift_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_ItemArgs = {
  objects: Array<Invoice_Item_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_Item_OneArgs = {
  object: Invoice_Item_Insert_Input;
  on_conflict?: InputMaybe<Invoice_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kilometer_FeeArgs = {
  objects: Array<Kilometer_Fee_Insert_Input>;
  on_conflict?: InputMaybe<Kilometer_Fee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kilometer_Fee_EntryArgs = {
  objects: Array<Kilometer_Fee_Entry_Insert_Input>;
  on_conflict?: InputMaybe<Kilometer_Fee_Entry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kilometer_Fee_Entry_OneArgs = {
  object: Kilometer_Fee_Entry_Insert_Input;
  on_conflict?: InputMaybe<Kilometer_Fee_Entry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kilometer_Fee_OneArgs = {
  object: Kilometer_Fee_Insert_Input;
  on_conflict?: InputMaybe<Kilometer_Fee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kilometer_Fee_Vehicle_TypeArgs = {
  objects: Array<Kilometer_Fee_Vehicle_Type_Insert_Input>;
  on_conflict?: InputMaybe<Kilometer_Fee_Vehicle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kilometer_Fee_Vehicle_Type_OneArgs = {
  object: Kilometer_Fee_Vehicle_Type_Insert_Input;
  on_conflict?: InputMaybe<Kilometer_Fee_Vehicle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Log_BookArgs = {
  objects: Array<Log_Book_Insert_Input>;
  on_conflict?: InputMaybe<Log_Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Log_Book_OneArgs = {
  object: Log_Book_Insert_Input;
  on_conflict?: InputMaybe<Log_Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NoteArgs = {
  objects: Array<Note_Insert_Input>;
  on_conflict?: InputMaybe<Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Note_OneArgs = {
  object: Note_Insert_Input;
  on_conflict?: InputMaybe<Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_MediumArgs = {
  objects: Array<Payment_Medium_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Medium_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Medium_OneArgs = {
  object: Payment_Medium_Insert_Input;
  on_conflict?: InputMaybe<Payment_Medium_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_DefinitionArgs = {
  objects: Array<Price_Definition_Insert_Input>;
  on_conflict?: InputMaybe<Price_Definition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Definition_EntryArgs = {
  objects: Array<Price_Definition_Entry_Insert_Input>;
  on_conflict?: InputMaybe<Price_Definition_Entry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Definition_Entry_OneArgs = {
  object: Price_Definition_Entry_Insert_Input;
  on_conflict?: InputMaybe<Price_Definition_Entry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Definition_OneArgs = {
  object: Price_Definition_Insert_Input;
  on_conflict?: InputMaybe<Price_Definition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Definition_Vehicle_TypeArgs = {
  objects: Array<Price_Definition_Vehicle_Type_Insert_Input>;
  on_conflict?: InputMaybe<Price_Definition_Vehicle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Definition_Vehicle_Type_OneArgs = {
  object: Price_Definition_Vehicle_Type_Insert_Input;
  on_conflict?: InputMaybe<Price_Definition_Vehicle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Eligible_ForArgs = {
  objects: Array<Product_Eligible_For_Insert_Input>;
  on_conflict?: InputMaybe<Product_Eligible_For_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Eligible_For_OneArgs = {
  object: Product_Eligible_For_Insert_Input;
  on_conflict?: InputMaybe<Product_Eligible_For_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_TypeArgs = {
  objects: Array<Product_Type_Insert_Input>;
  on_conflict?: InputMaybe<Product_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Type_OneArgs = {
  object: Product_Type_Insert_Input;
  on_conflict?: InputMaybe<Product_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_ContractArgs = {
  objects: Array<Rental_Contract_Insert_Input>;
  on_conflict?: InputMaybe<Rental_Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_AccessoryArgs = {
  objects: Array<Rental_Contract_Accessory_Insert_Input>;
  on_conflict?: InputMaybe<Rental_Contract_Accessory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_Accessory_OneArgs = {
  object: Rental_Contract_Accessory_Insert_Input;
  on_conflict?: InputMaybe<Rental_Contract_Accessory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_DepositArgs = {
  objects: Array<Rental_Contract_Deposit_Insert_Input>;
  on_conflict?: InputMaybe<Rental_Contract_Deposit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_Deposit_OneArgs = {
  object: Rental_Contract_Deposit_Insert_Input;
  on_conflict?: InputMaybe<Rental_Contract_Deposit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_DriverArgs = {
  objects: Array<Rental_Contract_Driver_Insert_Input>;
  on_conflict?: InputMaybe<Rental_Contract_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_Driver_OneArgs = {
  object: Rental_Contract_Driver_Insert_Input;
  on_conflict?: InputMaybe<Rental_Contract_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_FileArgs = {
  objects: Array<Rental_Contract_File_Insert_Input>;
  on_conflict?: InputMaybe<Rental_Contract_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_File_OneArgs = {
  object: Rental_Contract_File_Insert_Input;
  on_conflict?: InputMaybe<Rental_Contract_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_OneArgs = {
  object: Rental_Contract_Insert_Input;
  on_conflict?: InputMaybe<Rental_Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_PaymentArgs = {
  objects: Array<Rental_Contract_Payment_Insert_Input>;
  on_conflict?: InputMaybe<Rental_Contract_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_Contract_Payment_OneArgs = {
  object: Rental_Contract_Payment_Insert_Input;
  on_conflict?: InputMaybe<Rental_Contract_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_StateArgs = {
  objects: Array<Rental_State_Insert_Input>;
  on_conflict?: InputMaybe<Rental_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rental_State_OneArgs = {
  object: Rental_State_Insert_Input;
  on_conflict?: InputMaybe<Rental_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SequenceArgs = {
  objects: Array<Sequence_Insert_Input>;
  on_conflict?: InputMaybe<Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_OneArgs = {
  object: Sequence_Insert_Input;
  on_conflict?: InputMaybe<Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_TypeArgs = {
  objects: Array<Sequence_Type_Insert_Input>;
  on_conflict?: InputMaybe<Sequence_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Type_OneArgs = {
  object: Sequence_Type_Insert_Input;
  on_conflict?: InputMaybe<Sequence_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_DocumentArgs = {
  objects: Array<Template_Document_Insert_Input>;
  on_conflict?: InputMaybe<Template_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_Document_OneArgs = {
  object: Template_Document_Insert_Input;
  on_conflict?: InputMaybe<Template_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_EmailArgs = {
  objects: Array<Template_Email_Insert_Input>;
  on_conflict?: InputMaybe<Template_Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_Email_OneArgs = {
  object: Template_Email_Insert_Input;
  on_conflict?: InputMaybe<Template_Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_GroupArgs = {
  objects: Array<Template_Group_Insert_Input>;
  on_conflict?: InputMaybe<Template_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_Group_OneArgs = {
  object: Template_Group_Insert_Input;
  on_conflict?: InputMaybe<Template_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UnitArgs = {
  objects: Array<Unit_Insert_Input>;
  on_conflict?: InputMaybe<Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_OneArgs = {
  object: Unit_Insert_Input;
  on_conflict?: InputMaybe<Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Value_TypeArgs = {
  objects: Array<Value_Type_Insert_Input>;
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Value_Type_OneArgs = {
  object: Value_Type_Insert_Input;
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VehicleArgs = {
  objects: Array<Vehicle_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_CategoryArgs = {
  objects: Array<Vehicle_Category_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Category_OneArgs = {
  object: Vehicle_Category_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_OneArgs = {
  object: Vehicle_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_TypeArgs = {
  objects: Array<Vehicle_Type_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Type_CategoriesArgs = {
  objects: Array<Vehicle_Type_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_Type_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Type_Categories_OneArgs = {
  object: Vehicle_Type_Categories_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Type_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicle_Type_OneArgs = {
  object: Vehicle_Type_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdateAuthProviderArgs = {
  _set?: InputMaybe<AuthProviders_Set_Input>;
  pk_columns: AuthProviders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthProviderRequestArgs = {
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  pk_columns: AuthProviderRequests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthProviderRequestsArgs = {
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  where: AuthProviderRequests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthProvidersArgs = {
  _set?: InputMaybe<AuthProviders_Set_Input>;
  where: AuthProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenArgs = {
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  pk_columns: AuthRefreshTokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenTypeArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  pk_columns: AuthRefreshTokenTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenTypesArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  where: AuthRefreshTokenTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokensArgs = {
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  where: AuthRefreshTokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthRoleArgs = {
  _set?: InputMaybe<AuthRoles_Set_Input>;
  pk_columns: AuthRoles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthRolesArgs = {
  _set?: InputMaybe<AuthRoles_Set_Input>;
  where: AuthRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserProviderArgs = {
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  pk_columns: AuthUserProviders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserProvidersArgs = {
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  where: AuthUserProviders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserRoleArgs = {
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  pk_columns: AuthUserRoles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserRolesArgs = {
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  where: AuthUserRoles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserSecurityKeyArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  pk_columns: AuthUserSecurityKeys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateAuthUserSecurityKeysArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  where: AuthUserSecurityKeys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateBucketArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  pk_columns: Buckets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateBucketsArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  where: Buckets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateFileArgs = {
  _inc?: InputMaybe<Files_Inc_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateFilesArgs = {
  _inc?: InputMaybe<Files_Inc_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProviderRequests_ManyArgs = {
  updates: Array<AuthProviderRequests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthProviders_ManyArgs = {
  updates: Array<AuthProviders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthRefreshTokenTypes_ManyArgs = {
  updates: Array<AuthRefreshTokenTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthRefreshTokens_ManyArgs = {
  updates: Array<AuthRefreshTokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthRoles_ManyArgs = {
  updates: Array<AuthRoles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthUserProviders_ManyArgs = {
  updates: Array<AuthUserProviders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthUserRoles_ManyArgs = {
  updates: Array<AuthUserRoles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthUserSecurityKeys_ManyArgs = {
  updates: Array<AuthUserSecurityKeys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_CategoryArgs = {
  _set?: InputMaybe<Base_Category_Set_Input>;
  where: Base_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Category_By_PkArgs = {
  _set?: InputMaybe<Base_Category_Set_Input>;
  pk_columns: Base_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Category_ManyArgs = {
  updates: Array<Base_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Buckets_ManyArgs = {
  updates: Array<Buckets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactArgs = {
  _set?: InputMaybe<Contact_Set_Input>;
  where: Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_By_PkArgs = {
  _set?: InputMaybe<Contact_Set_Input>;
  pk_columns: Contact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_ManyArgs = {
  updates: Array<Contact_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_TypeArgs = {
  _set?: InputMaybe<Contact_Type_Set_Input>;
  where: Contact_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Type_By_PkArgs = {
  _set?: InputMaybe<Contact_Type_Set_Input>;
  pk_columns: Contact_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Type_ManyArgs = {
  updates: Array<Contact_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_TypeArgs = {
  _set?: InputMaybe<Entity_Type_Set_Input>;
  where: Entity_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_Type_By_PkArgs = {
  _set?: InputMaybe<Entity_Type_Set_Input>;
  pk_columns: Entity_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_Type_ManyArgs = {
  updates: Array<Entity_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FuelArgs = {
  _set?: InputMaybe<Fuel_Set_Input>;
  where: Fuel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fuel_By_PkArgs = {
  _set?: InputMaybe<Fuel_Set_Input>;
  pk_columns: Fuel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fuel_ManyArgs = {
  updates: Array<Fuel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GearshiftArgs = {
  _set?: InputMaybe<Gearshift_Set_Input>;
  where: Gearshift_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gearshift_By_PkArgs = {
  _set?: InputMaybe<Gearshift_Set_Input>;
  pk_columns: Gearshift_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gearshift_ManyArgs = {
  updates: Array<Gearshift_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  where: Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  pk_columns: Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_ItemArgs = {
  _inc?: InputMaybe<Invoice_Item_Inc_Input>;
  _set?: InputMaybe<Invoice_Item_Set_Input>;
  where: Invoice_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Item_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Item_Inc_Input>;
  _set?: InputMaybe<Invoice_Item_Set_Input>;
  pk_columns: Invoice_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_Item_ManyArgs = {
  updates: Array<Invoice_Item_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoice_ManyArgs = {
  updates: Array<Invoice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_FeeArgs = {
  _inc?: InputMaybe<Kilometer_Fee_Inc_Input>;
  _set?: InputMaybe<Kilometer_Fee_Set_Input>;
  where: Kilometer_Fee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_By_PkArgs = {
  _inc?: InputMaybe<Kilometer_Fee_Inc_Input>;
  _set?: InputMaybe<Kilometer_Fee_Set_Input>;
  pk_columns: Kilometer_Fee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_EntryArgs = {
  _inc?: InputMaybe<Kilometer_Fee_Entry_Inc_Input>;
  _set?: InputMaybe<Kilometer_Fee_Entry_Set_Input>;
  where: Kilometer_Fee_Entry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_Entry_By_PkArgs = {
  _inc?: InputMaybe<Kilometer_Fee_Entry_Inc_Input>;
  _set?: InputMaybe<Kilometer_Fee_Entry_Set_Input>;
  pk_columns: Kilometer_Fee_Entry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_Entry_ManyArgs = {
  updates: Array<Kilometer_Fee_Entry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_ManyArgs = {
  updates: Array<Kilometer_Fee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_Vehicle_TypeArgs = {
  _set?: InputMaybe<Kilometer_Fee_Vehicle_Type_Set_Input>;
  where: Kilometer_Fee_Vehicle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_Vehicle_Type_By_PkArgs = {
  _set?: InputMaybe<Kilometer_Fee_Vehicle_Type_Set_Input>;
  pk_columns: Kilometer_Fee_Vehicle_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kilometer_Fee_Vehicle_Type_ManyArgs = {
  updates: Array<Kilometer_Fee_Vehicle_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Log_BookArgs = {
  _inc?: InputMaybe<Log_Book_Inc_Input>;
  _set?: InputMaybe<Log_Book_Set_Input>;
  where: Log_Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Log_Book_By_PkArgs = {
  _inc?: InputMaybe<Log_Book_Inc_Input>;
  _set?: InputMaybe<Log_Book_Set_Input>;
  pk_columns: Log_Book_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Log_Book_ManyArgs = {
  updates: Array<Log_Book_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NoteArgs = {
  _set?: InputMaybe<Note_Set_Input>;
  where: Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Note_By_PkArgs = {
  _set?: InputMaybe<Note_Set_Input>;
  pk_columns: Note_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Note_ManyArgs = {
  updates: Array<Note_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_MediumArgs = {
  _set?: InputMaybe<Payment_Medium_Set_Input>;
  where: Payment_Medium_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Medium_By_PkArgs = {
  _set?: InputMaybe<Payment_Medium_Set_Input>;
  pk_columns: Payment_Medium_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Medium_ManyArgs = {
  updates: Array<Payment_Medium_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Price_DefinitionArgs = {
  _inc?: InputMaybe<Price_Definition_Inc_Input>;
  _set?: InputMaybe<Price_Definition_Set_Input>;
  where: Price_Definition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_By_PkArgs = {
  _inc?: InputMaybe<Price_Definition_Inc_Input>;
  _set?: InputMaybe<Price_Definition_Set_Input>;
  pk_columns: Price_Definition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_EntryArgs = {
  _inc?: InputMaybe<Price_Definition_Entry_Inc_Input>;
  _set?: InputMaybe<Price_Definition_Entry_Set_Input>;
  where: Price_Definition_Entry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_Entry_By_PkArgs = {
  _inc?: InputMaybe<Price_Definition_Entry_Inc_Input>;
  _set?: InputMaybe<Price_Definition_Entry_Set_Input>;
  pk_columns: Price_Definition_Entry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_Entry_ManyArgs = {
  updates: Array<Price_Definition_Entry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_ManyArgs = {
  updates: Array<Price_Definition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_Vehicle_TypeArgs = {
  _set?: InputMaybe<Price_Definition_Vehicle_Type_Set_Input>;
  where: Price_Definition_Vehicle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_Vehicle_Type_By_PkArgs = {
  _set?: InputMaybe<Price_Definition_Vehicle_Type_Set_Input>;
  pk_columns: Price_Definition_Vehicle_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Definition_Vehicle_Type_ManyArgs = {
  updates: Array<Price_Definition_Vehicle_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Eligible_ForArgs = {
  _set?: InputMaybe<Product_Eligible_For_Set_Input>;
  where: Product_Eligible_For_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Eligible_For_By_PkArgs = {
  _set?: InputMaybe<Product_Eligible_For_Set_Input>;
  pk_columns: Product_Eligible_For_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Eligible_For_ManyArgs = {
  updates: Array<Product_Eligible_For_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_TypeArgs = {
  _set?: InputMaybe<Product_Type_Set_Input>;
  where: Product_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Type_By_PkArgs = {
  _set?: InputMaybe<Product_Type_Set_Input>;
  pk_columns: Product_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Type_ManyArgs = {
  updates: Array<Product_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_ContractArgs = {
  _inc?: InputMaybe<Rental_Contract_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Set_Input>;
  where: Rental_Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_AccessoryArgs = {
  _inc?: InputMaybe<Rental_Contract_Accessory_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Accessory_Set_Input>;
  where: Rental_Contract_Accessory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Accessory_By_PkArgs = {
  _inc?: InputMaybe<Rental_Contract_Accessory_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Accessory_Set_Input>;
  pk_columns: Rental_Contract_Accessory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Accessory_ManyArgs = {
  updates: Array<Rental_Contract_Accessory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_By_PkArgs = {
  _inc?: InputMaybe<Rental_Contract_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Set_Input>;
  pk_columns: Rental_Contract_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_DepositArgs = {
  _inc?: InputMaybe<Rental_Contract_Deposit_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Deposit_Set_Input>;
  where: Rental_Contract_Deposit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Deposit_By_PkArgs = {
  _inc?: InputMaybe<Rental_Contract_Deposit_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Deposit_Set_Input>;
  pk_columns: Rental_Contract_Deposit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Deposit_ManyArgs = {
  updates: Array<Rental_Contract_Deposit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_DriverArgs = {
  _set?: InputMaybe<Rental_Contract_Driver_Set_Input>;
  where: Rental_Contract_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Driver_By_PkArgs = {
  _set?: InputMaybe<Rental_Contract_Driver_Set_Input>;
  pk_columns: Rental_Contract_Driver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Driver_ManyArgs = {
  updates: Array<Rental_Contract_Driver_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_FileArgs = {
  _inc?: InputMaybe<Rental_Contract_File_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_File_Set_Input>;
  where: Rental_Contract_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_File_By_PkArgs = {
  _inc?: InputMaybe<Rental_Contract_File_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_File_Set_Input>;
  pk_columns: Rental_Contract_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_File_ManyArgs = {
  updates: Array<Rental_Contract_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_ManyArgs = {
  updates: Array<Rental_Contract_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_PaymentArgs = {
  _inc?: InputMaybe<Rental_Contract_Payment_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Payment_Set_Input>;
  where: Rental_Contract_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Payment_By_PkArgs = {
  _inc?: InputMaybe<Rental_Contract_Payment_Inc_Input>;
  _set?: InputMaybe<Rental_Contract_Payment_Set_Input>;
  pk_columns: Rental_Contract_Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_Contract_Payment_ManyArgs = {
  updates: Array<Rental_Contract_Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_StateArgs = {
  _set?: InputMaybe<Rental_State_Set_Input>;
  where: Rental_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_State_By_PkArgs = {
  _set?: InputMaybe<Rental_State_Set_Input>;
  pk_columns: Rental_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rental_State_ManyArgs = {
  updates: Array<Rental_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SequenceArgs = {
  _inc?: InputMaybe<Sequence_Inc_Input>;
  _set?: InputMaybe<Sequence_Set_Input>;
  where: Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_By_PkArgs = {
  _inc?: InputMaybe<Sequence_Inc_Input>;
  _set?: InputMaybe<Sequence_Set_Input>;
  pk_columns: Sequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_ManyArgs = {
  updates: Array<Sequence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_TypeArgs = {
  _set?: InputMaybe<Sequence_Type_Set_Input>;
  where: Sequence_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Type_By_PkArgs = {
  _set?: InputMaybe<Sequence_Type_Set_Input>;
  pk_columns: Sequence_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Type_ManyArgs = {
  updates: Array<Sequence_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Template_DocumentArgs = {
  _set?: InputMaybe<Template_Document_Set_Input>;
  where: Template_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Document_By_PkArgs = {
  _set?: InputMaybe<Template_Document_Set_Input>;
  pk_columns: Template_Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Document_ManyArgs = {
  updates: Array<Template_Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Template_EmailArgs = {
  _set?: InputMaybe<Template_Email_Set_Input>;
  where: Template_Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Email_By_PkArgs = {
  _set?: InputMaybe<Template_Email_Set_Input>;
  pk_columns: Template_Email_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Email_ManyArgs = {
  updates: Array<Template_Email_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Template_GroupArgs = {
  _set?: InputMaybe<Template_Group_Set_Input>;
  where: Template_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Group_By_PkArgs = {
  _set?: InputMaybe<Template_Group_Set_Input>;
  pk_columns: Template_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Group_ManyArgs = {
  updates: Array<Template_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UnitArgs = {
  _set?: InputMaybe<Unit_Set_Input>;
  where: Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_By_PkArgs = {
  _set?: InputMaybe<Unit_Set_Input>;
  pk_columns: Unit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_ManyArgs = {
  updates: Array<Unit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Value_TypeArgs = {
  _set?: InputMaybe<Value_Type_Set_Input>;
  where: Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Value_Type_By_PkArgs = {
  _set?: InputMaybe<Value_Type_Set_Input>;
  pk_columns: Value_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Value_Type_ManyArgs = {
  updates: Array<Value_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VehicleArgs = {
  _inc?: InputMaybe<Vehicle_Inc_Input>;
  _set?: InputMaybe<Vehicle_Set_Input>;
  where: Vehicle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Inc_Input>;
  _set?: InputMaybe<Vehicle_Set_Input>;
  pk_columns: Vehicle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_CategoryArgs = {
  _inc?: InputMaybe<Vehicle_Category_Inc_Input>;
  _set?: InputMaybe<Vehicle_Category_Set_Input>;
  where: Vehicle_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Category_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Category_Inc_Input>;
  _set?: InputMaybe<Vehicle_Category_Set_Input>;
  pk_columns: Vehicle_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Category_ManyArgs = {
  updates: Array<Vehicle_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_ManyArgs = {
  updates: Array<Vehicle_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_TypeArgs = {
  _inc?: InputMaybe<Vehicle_Type_Inc_Input>;
  _set?: InputMaybe<Vehicle_Type_Set_Input>;
  where: Vehicle_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Type_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Type_Inc_Input>;
  _set?: InputMaybe<Vehicle_Type_Set_Input>;
  pk_columns: Vehicle_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Type_CategoriesArgs = {
  _set?: InputMaybe<Vehicle_Type_Categories_Set_Input>;
  where: Vehicle_Type_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Type_Categories_By_PkArgs = {
  _set?: InputMaybe<Vehicle_Type_Categories_Set_Input>;
  pk_columns: Vehicle_Type_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Type_Categories_ManyArgs = {
  updates: Array<Vehicle_Type_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicle_Type_ManyArgs = {
  updates: Array<Vehicle_Type_Updates>;
};

/** columns and relationships of "note" */
export type Note = {
  __typename?: 'note';
  content: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  created_by_user?: Maybe<Users>;
  entity_id: Scalars['uuid']['output'];
  entity_type: Entity_Type_Enum;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "note" */
export type Note_Aggregate = {
  __typename?: 'note_aggregate';
  aggregate?: Maybe<Note_Aggregate_Fields>;
  nodes: Array<Note>;
};

/** aggregate fields of "note" */
export type Note_Aggregate_Fields = {
  __typename?: 'note_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Note_Max_Fields>;
  min?: Maybe<Note_Min_Fields>;
};


/** aggregate fields of "note" */
export type Note_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "note". All fields are combined with a logical 'AND'. */
export type Note_Bool_Exp = {
  _and?: InputMaybe<Array<Note_Bool_Exp>>;
  _not?: InputMaybe<Note_Bool_Exp>;
  _or?: InputMaybe<Array<Note_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user?: InputMaybe<Users_Bool_Exp>;
  entity_id?: InputMaybe<Uuid_Comparison_Exp>;
  entity_type?: InputMaybe<Entity_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "note" */
export enum Note_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotesPkey = 'notes_pkey'
}

/** input type for inserting data into table "note" */
export type Note_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  created_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Note_Max_Fields = {
  __typename?: 'note_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  entity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Note_Min_Fields = {
  __typename?: 'note_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  entity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "note" */
export type Note_Mutation_Response = {
  __typename?: 'note_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Note>;
};

/** on_conflict condition type for table "note" */
export type Note_On_Conflict = {
  constraint: Note_Constraint;
  update_columns?: Array<Note_Update_Column>;
  where?: InputMaybe<Note_Bool_Exp>;
};

/** Ordering options when selecting data from "note". */
export type Note_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<Users_Order_By>;
  entity_id?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: note */
export type Note_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "note" */
export enum Note_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "note" */
export type Note_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "note" */
export type Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Note_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "note" */
export enum Note_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Note_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Note_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment_medium" */
export type Payment_Medium = {
  __typename?: 'payment_medium';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "payment_medium" */
export type Payment_Medium_Aggregate = {
  __typename?: 'payment_medium_aggregate';
  aggregate?: Maybe<Payment_Medium_Aggregate_Fields>;
  nodes: Array<Payment_Medium>;
};

/** aggregate fields of "payment_medium" */
export type Payment_Medium_Aggregate_Fields = {
  __typename?: 'payment_medium_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Payment_Medium_Max_Fields>;
  min?: Maybe<Payment_Medium_Min_Fields>;
};


/** aggregate fields of "payment_medium" */
export type Payment_Medium_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Medium_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "payment_medium". All fields are combined with a logical 'AND'. */
export type Payment_Medium_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Medium_Bool_Exp>>;
  _not?: InputMaybe<Payment_Medium_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Medium_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_medium" */
export enum Payment_Medium_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentMediumPkey = 'payment_medium_pkey'
}

export enum Payment_Medium_Enum {
  Cash = 'cash',
  Creditcard = 'creditcard',
  Depitcard = 'depitcard',
  Transfer = 'transfer'
}

/** Boolean expression to compare columns of type "payment_medium_enum". All fields are combined with logical 'AND'. */
export type Payment_Medium_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Medium_Enum>;
  _in?: InputMaybe<Array<Payment_Medium_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Payment_Medium_Enum>;
  _nin?: InputMaybe<Array<Payment_Medium_Enum>>;
};

/** input type for inserting data into table "payment_medium" */
export type Payment_Medium_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Payment_Medium_Max_Fields = {
  __typename?: 'payment_medium_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Payment_Medium_Min_Fields = {
  __typename?: 'payment_medium_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "payment_medium" */
export type Payment_Medium_Mutation_Response = {
  __typename?: 'payment_medium_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Medium>;
};

/** on_conflict condition type for table "payment_medium" */
export type Payment_Medium_On_Conflict = {
  constraint: Payment_Medium_Constraint;
  update_columns?: Array<Payment_Medium_Update_Column>;
  where?: InputMaybe<Payment_Medium_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_medium". */
export type Payment_Medium_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_medium */
export type Payment_Medium_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "payment_medium" */
export enum Payment_Medium_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_medium" */
export type Payment_Medium_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "payment_medium" */
export type Payment_Medium_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Medium_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Medium_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "payment_medium" */
export enum Payment_Medium_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Payment_Medium_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Medium_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Medium_Bool_Exp;
};

/** columns and relationships of "price_definition" */
export type Price_Definition = {
  __typename?: 'price_definition';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  entries: Array<Price_Definition_Entry>;
  /** An aggregate relationship */
  entries_aggregate: Price_Definition_Entry_Aggregate;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sort_order: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  vehicle_types: Array<Price_Definition_Vehicle_Type>;
  /** An aggregate relationship */
  vehicle_types_aggregate: Price_Definition_Vehicle_Type_Aggregate;
};


/** columns and relationships of "price_definition" */
export type Price_DefinitionEntriesArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Entry_Order_By>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


/** columns and relationships of "price_definition" */
export type Price_DefinitionEntries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Entry_Order_By>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


/** columns and relationships of "price_definition" */
export type Price_DefinitionVehicle_TypesArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


/** columns and relationships of "price_definition" */
export type Price_DefinitionVehicle_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};

/** aggregated selection of "price_definition" */
export type Price_Definition_Aggregate = {
  __typename?: 'price_definition_aggregate';
  aggregate?: Maybe<Price_Definition_Aggregate_Fields>;
  nodes: Array<Price_Definition>;
};

/** aggregate fields of "price_definition" */
export type Price_Definition_Aggregate_Fields = {
  __typename?: 'price_definition_aggregate_fields';
  avg?: Maybe<Price_Definition_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Price_Definition_Max_Fields>;
  min?: Maybe<Price_Definition_Min_Fields>;
  stddev?: Maybe<Price_Definition_Stddev_Fields>;
  stddev_pop?: Maybe<Price_Definition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Price_Definition_Stddev_Samp_Fields>;
  sum?: Maybe<Price_Definition_Sum_Fields>;
  var_pop?: Maybe<Price_Definition_Var_Pop_Fields>;
  var_samp?: Maybe<Price_Definition_Var_Samp_Fields>;
  variance?: Maybe<Price_Definition_Variance_Fields>;
};


/** aggregate fields of "price_definition" */
export type Price_Definition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Price_Definition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Price_Definition_Avg_Fields = {
  __typename?: 'price_definition_avg_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "price_definition". All fields are combined with a logical 'AND'. */
export type Price_Definition_Bool_Exp = {
  _and?: InputMaybe<Array<Price_Definition_Bool_Exp>>;
  _not?: InputMaybe<Price_Definition_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Definition_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  entries?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
  entries_aggregate?: InputMaybe<Price_Definition_Entry_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  valid_from?: InputMaybe<Date_Comparison_Exp>;
  valid_to?: InputMaybe<Date_Comparison_Exp>;
  vehicle_types?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
  vehicle_types_aggregate?: InputMaybe<Price_Definition_Vehicle_Type_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "price_definition" */
export enum Price_Definition_Constraint {
  /** unique or primary key constraint on columns "id" */
  PriceDefinitionPkey = 'price_definition_pkey'
}

/** columns and relationships of "price_definition_entry" */
export type Price_Definition_Entry = {
  __typename?: 'price_definition_entry';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  days?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  included_kilometer: Scalars['numeric']['output'];
  /** An object relationship */
  price_definition: Price_Definition;
  price_definition_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  value: Scalars['numeric']['output'];
};

/** aggregated selection of "price_definition_entry" */
export type Price_Definition_Entry_Aggregate = {
  __typename?: 'price_definition_entry_aggregate';
  aggregate?: Maybe<Price_Definition_Entry_Aggregate_Fields>;
  nodes: Array<Price_Definition_Entry>;
};

export type Price_Definition_Entry_Aggregate_Bool_Exp = {
  count?: InputMaybe<Price_Definition_Entry_Aggregate_Bool_Exp_Count>;
};

export type Price_Definition_Entry_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "price_definition_entry" */
export type Price_Definition_Entry_Aggregate_Fields = {
  __typename?: 'price_definition_entry_aggregate_fields';
  avg?: Maybe<Price_Definition_Entry_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Price_Definition_Entry_Max_Fields>;
  min?: Maybe<Price_Definition_Entry_Min_Fields>;
  stddev?: Maybe<Price_Definition_Entry_Stddev_Fields>;
  stddev_pop?: Maybe<Price_Definition_Entry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Price_Definition_Entry_Stddev_Samp_Fields>;
  sum?: Maybe<Price_Definition_Entry_Sum_Fields>;
  var_pop?: Maybe<Price_Definition_Entry_Var_Pop_Fields>;
  var_samp?: Maybe<Price_Definition_Entry_Var_Samp_Fields>;
  variance?: Maybe<Price_Definition_Entry_Variance_Fields>;
};


/** aggregate fields of "price_definition_entry" */
export type Price_Definition_Entry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "price_definition_entry" */
export type Price_Definition_Entry_Aggregate_Order_By = {
  avg?: InputMaybe<Price_Definition_Entry_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Price_Definition_Entry_Max_Order_By>;
  min?: InputMaybe<Price_Definition_Entry_Min_Order_By>;
  stddev?: InputMaybe<Price_Definition_Entry_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Price_Definition_Entry_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Price_Definition_Entry_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Price_Definition_Entry_Sum_Order_By>;
  var_pop?: InputMaybe<Price_Definition_Entry_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Price_Definition_Entry_Var_Samp_Order_By>;
  variance?: InputMaybe<Price_Definition_Entry_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "price_definition_entry" */
export type Price_Definition_Entry_Arr_Rel_Insert_Input = {
  data: Array<Price_Definition_Entry_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Definition_Entry_On_Conflict>;
};

/** aggregate avg on columns */
export type Price_Definition_Entry_Avg_Fields = {
  __typename?: 'price_definition_entry_avg_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Avg_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "price_definition_entry". All fields are combined with a logical 'AND'. */
export type Price_Definition_Entry_Bool_Exp = {
  _and?: InputMaybe<Array<Price_Definition_Entry_Bool_Exp>>;
  _not?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Definition_Entry_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  days?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  included_kilometer?: InputMaybe<Numeric_Comparison_Exp>;
  price_definition?: InputMaybe<Price_Definition_Bool_Exp>;
  price_definition_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "price_definition_entry" */
export enum Price_Definition_Entry_Constraint {
  /** unique or primary key constraint on columns "id" */
  PriceDefinitionEntryPkey = 'price_definition_entry_pkey'
}

/** input type for incrementing numeric columns in table "price_definition_entry" */
export type Price_Definition_Entry_Inc_Input = {
  days?: InputMaybe<Scalars['numeric']['input']>;
  included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "price_definition_entry" */
export type Price_Definition_Entry_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  price_definition?: InputMaybe<Price_Definition_Obj_Rel_Insert_Input>;
  price_definition_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Price_Definition_Entry_Max_Fields = {
  __typename?: 'price_definition_entry_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  days?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  included_kilometer?: Maybe<Scalars['numeric']['output']>;
  price_definition_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  price_definition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Price_Definition_Entry_Min_Fields = {
  __typename?: 'price_definition_entry_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  days?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  included_kilometer?: Maybe<Scalars['numeric']['output']>;
  price_definition_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  price_definition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "price_definition_entry" */
export type Price_Definition_Entry_Mutation_Response = {
  __typename?: 'price_definition_entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Price_Definition_Entry>;
};

/** on_conflict condition type for table "price_definition_entry" */
export type Price_Definition_Entry_On_Conflict = {
  constraint: Price_Definition_Entry_Constraint;
  update_columns?: Array<Price_Definition_Entry_Update_Column>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};

/** Ordering options when selecting data from "price_definition_entry". */
export type Price_Definition_Entry_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  days?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  price_definition?: InputMaybe<Price_Definition_Order_By>;
  price_definition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: price_definition_entry */
export type Price_Definition_Entry_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "price_definition_entry" */
export enum Price_Definition_Entry_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  IncludedKilometer = 'included_kilometer',
  /** column name */
  PriceDefinitionId = 'price_definition_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "price_definition_entry" */
export type Price_Definition_Entry_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  price_definition_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Price_Definition_Entry_Stddev_Fields = {
  __typename?: 'price_definition_entry_stddev_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Stddev_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Price_Definition_Entry_Stddev_Pop_Fields = {
  __typename?: 'price_definition_entry_stddev_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Stddev_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Price_Definition_Entry_Stddev_Samp_Fields = {
  __typename?: 'price_definition_entry_stddev_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Stddev_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "price_definition_entry" */
export type Price_Definition_Entry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Definition_Entry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Definition_Entry_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  price_definition_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Price_Definition_Entry_Sum_Fields = {
  __typename?: 'price_definition_entry_sum_fields';
  days?: Maybe<Scalars['numeric']['output']>;
  included_kilometer?: Maybe<Scalars['numeric']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Sum_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "price_definition_entry" */
export enum Price_Definition_Entry_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Days = 'days',
  /** column name */
  Id = 'id',
  /** column name */
  IncludedKilometer = 'included_kilometer',
  /** column name */
  PriceDefinitionId = 'price_definition_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type Price_Definition_Entry_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Price_Definition_Entry_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Definition_Entry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Definition_Entry_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Price_Definition_Entry_Var_Pop_Fields = {
  __typename?: 'price_definition_entry_var_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Var_Pop_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Price_Definition_Entry_Var_Samp_Fields = {
  __typename?: 'price_definition_entry_var_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Var_Samp_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Price_Definition_Entry_Variance_Fields = {
  __typename?: 'price_definition_entry_variance_fields';
  days?: Maybe<Scalars['Float']['output']>;
  included_kilometer?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "price_definition_entry" */
export type Price_Definition_Entry_Variance_Order_By = {
  days?: InputMaybe<Order_By>;
  included_kilometer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "price_definition" */
export type Price_Definition_Inc_Input = {
  sort_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "price_definition" */
export type Price_Definition_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  entries?: InputMaybe<Price_Definition_Entry_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
  vehicle_types?: InputMaybe<Price_Definition_Vehicle_Type_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Price_Definition_Max_Fields = {
  __typename?: 'price_definition_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
};

/** aggregate min on columns */
export type Price_Definition_Min_Fields = {
  __typename?: 'price_definition_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
};

/** response of any mutation on the table "price_definition" */
export type Price_Definition_Mutation_Response = {
  __typename?: 'price_definition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Price_Definition>;
};

/** input type for inserting object relation for remote table "price_definition" */
export type Price_Definition_Obj_Rel_Insert_Input = {
  data: Price_Definition_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Definition_On_Conflict>;
};

/** on_conflict condition type for table "price_definition" */
export type Price_Definition_On_Conflict = {
  constraint: Price_Definition_Constraint;
  update_columns?: Array<Price_Definition_Update_Column>;
  where?: InputMaybe<Price_Definition_Bool_Exp>;
};

/** Ordering options when selecting data from "price_definition". */
export type Price_Definition_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  entries_aggregate?: InputMaybe<Price_Definition_Entry_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  vehicle_types_aggregate?: InputMaybe<Price_Definition_Vehicle_Type_Aggregate_Order_By>;
};

/** primary key columns input for table: price_definition */
export type Price_Definition_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "price_definition" */
export enum Price_Definition_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

/** input type for updating data in table "price_definition" */
export type Price_Definition_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate stddev on columns */
export type Price_Definition_Stddev_Fields = {
  __typename?: 'price_definition_stddev_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Price_Definition_Stddev_Pop_Fields = {
  __typename?: 'price_definition_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Price_Definition_Stddev_Samp_Fields = {
  __typename?: 'price_definition_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "price_definition" */
export type Price_Definition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Definition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Definition_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate sum on columns */
export type Price_Definition_Sum_Fields = {
  __typename?: 'price_definition_sum_fields';
  sort_order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "price_definition" */
export enum Price_Definition_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

export type Price_Definition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Price_Definition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Definition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Definition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Price_Definition_Var_Pop_Fields = {
  __typename?: 'price_definition_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Price_Definition_Var_Samp_Fields = {
  __typename?: 'price_definition_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Price_Definition_Variance_Fields = {
  __typename?: 'price_definition_variance_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type = {
  __typename?: 'price_definition_vehicle_type';
  created_at: Scalars['timestamp']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  price_definition: Price_Definition;
  price_definition_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamp']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vehicle_type: Vehicle_Type;
  vehicle_type_id: Scalars['uuid']['output'];
};

/** aggregated selection of "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Aggregate = {
  __typename?: 'price_definition_vehicle_type_aggregate';
  aggregate?: Maybe<Price_Definition_Vehicle_Type_Aggregate_Fields>;
  nodes: Array<Price_Definition_Vehicle_Type>;
};

export type Price_Definition_Vehicle_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Price_Definition_Vehicle_Type_Aggregate_Bool_Exp_Count>;
};

export type Price_Definition_Vehicle_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Aggregate_Fields = {
  __typename?: 'price_definition_vehicle_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Price_Definition_Vehicle_Type_Max_Fields>;
  min?: Maybe<Price_Definition_Vehicle_Type_Min_Fields>;
};


/** aggregate fields of "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Price_Definition_Vehicle_Type_Max_Order_By>;
  min?: InputMaybe<Price_Definition_Vehicle_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Arr_Rel_Insert_Input = {
  data: Array<Price_Definition_Vehicle_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Definition_Vehicle_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "price_definition_vehicle_type". All fields are combined with a logical 'AND'. */
export type Price_Definition_Vehicle_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Price_Definition_Vehicle_Type_Bool_Exp>>;
  _not?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Definition_Vehicle_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  price_definition?: InputMaybe<Price_Definition_Bool_Exp>;
  price_definition_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle_type?: InputMaybe<Vehicle_Type_Bool_Exp>;
  vehicle_type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "price_definition_vehicle_type" */
export enum Price_Definition_Vehicle_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  PriceDefinitionVehicleTypePkey = 'price_definition_vehicle_type_pkey',
  /** unique or primary key constraint on columns "price_definition_id", "vehicle_type_id" */
  PriceDefinitionVehicleTypePriceDefinitionIdVehicleTKey = 'price_definition_vehicle_type_price_definition_id_vehicle_t_key'
}

/** input type for inserting data into table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price_definition?: InputMaybe<Price_Definition_Obj_Rel_Insert_Input>;
  price_definition_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Vehicle_Type_Obj_Rel_Insert_Input>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Price_Definition_Vehicle_Type_Max_Fields = {
  __typename?: 'price_definition_vehicle_type_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price_definition_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_definition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Price_Definition_Vehicle_Type_Min_Fields = {
  __typename?: 'price_definition_vehicle_type_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  price_definition_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_definition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Mutation_Response = {
  __typename?: 'price_definition_vehicle_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Price_Definition_Vehicle_Type>;
};

/** on_conflict condition type for table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_On_Conflict = {
  constraint: Price_Definition_Vehicle_Type_Constraint;
  update_columns?: Array<Price_Definition_Vehicle_Type_Update_Column>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "price_definition_vehicle_type". */
export type Price_Definition_Vehicle_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_definition?: InputMaybe<Price_Definition_Order_By>;
  price_definition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type?: InputMaybe<Vehicle_Type_Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: price_definition_vehicle_type */
export type Price_Definition_Vehicle_Type_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "price_definition_vehicle_type" */
export enum Price_Definition_Vehicle_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  PriceDefinitionId = 'price_definition_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

/** input type for updating data in table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price_definition_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "price_definition_vehicle_type" */
export type Price_Definition_Vehicle_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Definition_Vehicle_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Definition_Vehicle_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  price_definition_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "price_definition_vehicle_type" */
export enum Price_Definition_Vehicle_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  PriceDefinitionId = 'price_definition_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

export type Price_Definition_Vehicle_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Definition_Vehicle_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Definition_Vehicle_Type_Bool_Exp;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  allow_amount?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_default?: Maybe<Scalars['Boolean']['output']>;
  is_internal?: Maybe<Scalars['Boolean']['output']>;
  is_selectable?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_source?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  product_base_categories: Array<Product_Eligible_For>;
  /** An aggregate relationship */
  product_base_categories_aggregate: Product_Eligible_For_Aggregate;
  product_type: Product_Type_Enum;
  secondary_value: Scalars['numeric']['output'];
  sort_order: Scalars['Int']['output'];
  tagline?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  unit?: Maybe<Unit>;
  unit_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
  value: Scalars['numeric']['output'];
  value_type: Value_Type_Enum;
};


/** columns and relationships of "product" */
export type ProductProduct_Base_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Eligible_For_Order_By>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductProduct_Base_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Eligible_For_Order_By>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  allow_amount?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  is_internal?: InputMaybe<Boolean_Comparison_Exp>;
  is_selectable?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  legacy_source?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  product_base_categories?: InputMaybe<Product_Eligible_For_Bool_Exp>;
  product_base_categories_aggregate?: InputMaybe<Product_Eligible_For_Aggregate_Bool_Exp>;
  product_type?: InputMaybe<Product_Type_Enum_Comparison_Exp>;
  secondary_value?: InputMaybe<Numeric_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  tagline?: InputMaybe<String_Comparison_Exp>;
  unit?: InputMaybe<Unit_Bool_Exp>;
  unit_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  valid_from?: InputMaybe<Date_Comparison_Exp>;
  valid_to?: InputMaybe<Date_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
  value_type?: InputMaybe<Value_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'product_pkey'
}

/** columns and relationships of "product_eligible_for" */
export type Product_Eligible_For = {
  __typename?: 'product_eligible_for';
  base_category: Base_Category_Enum;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "product_eligible_for" */
export type Product_Eligible_For_Aggregate = {
  __typename?: 'product_eligible_for_aggregate';
  aggregate?: Maybe<Product_Eligible_For_Aggregate_Fields>;
  nodes: Array<Product_Eligible_For>;
};

export type Product_Eligible_For_Aggregate_Bool_Exp = {
  count?: InputMaybe<Product_Eligible_For_Aggregate_Bool_Exp_Count>;
};

export type Product_Eligible_For_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Product_Eligible_For_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_eligible_for" */
export type Product_Eligible_For_Aggregate_Fields = {
  __typename?: 'product_eligible_for_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Eligible_For_Max_Fields>;
  min?: Maybe<Product_Eligible_For_Min_Fields>;
};


/** aggregate fields of "product_eligible_for" */
export type Product_Eligible_For_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "product_eligible_for" */
export type Product_Eligible_For_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Eligible_For_Max_Order_By>;
  min?: InputMaybe<Product_Eligible_For_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_eligible_for" */
export type Product_Eligible_For_Arr_Rel_Insert_Input = {
  data: Array<Product_Eligible_For_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Eligible_For_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_eligible_for". All fields are combined with a logical 'AND'. */
export type Product_Eligible_For_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Eligible_For_Bool_Exp>>;
  _not?: InputMaybe<Product_Eligible_For_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Eligible_For_Bool_Exp>>;
  base_category?: InputMaybe<Base_Category_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_eligible_for" */
export enum Product_Eligible_For_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductEligibleForPkey = 'product_eligible_for_pkey',
  /** unique or primary key constraint on columns "base_category", "product_id" */
  ProductEligibleForProductIdBaseCategoryKey = 'product_eligible_for_product_id_base_category_key'
}

/** input type for inserting data into table "product_eligible_for" */
export type Product_Eligible_For_Insert_Input = {
  base_category?: InputMaybe<Base_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Product_Eligible_For_Max_Fields = {
  __typename?: 'product_eligible_for_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "product_eligible_for" */
export type Product_Eligible_For_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Eligible_For_Min_Fields = {
  __typename?: 'product_eligible_for_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "product_eligible_for" */
export type Product_Eligible_For_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_eligible_for" */
export type Product_Eligible_For_Mutation_Response = {
  __typename?: 'product_eligible_for_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Eligible_For>;
};

/** on_conflict condition type for table "product_eligible_for" */
export type Product_Eligible_For_On_Conflict = {
  constraint: Product_Eligible_For_Constraint;
  update_columns?: Array<Product_Eligible_For_Update_Column>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};

/** Ordering options when selecting data from "product_eligible_for". */
export type Product_Eligible_For_Order_By = {
  base_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_eligible_for */
export type Product_Eligible_For_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "product_eligible_for" */
export enum Product_Eligible_For_Select_Column {
  /** column name */
  BaseCategory = 'base_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "product_eligible_for" */
export type Product_Eligible_For_Set_Input = {
  base_category?: InputMaybe<Base_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "product_eligible_for" */
export type Product_Eligible_For_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Eligible_For_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Eligible_For_Stream_Cursor_Value_Input = {
  base_category?: InputMaybe<Base_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "product_eligible_for" */
export enum Product_Eligible_For_Update_Column {
  /** column name */
  BaseCategory = 'base_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Product_Eligible_For_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Eligible_For_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Eligible_For_Bool_Exp;
};

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  secondary_value?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  allow_amount?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  is_selectable?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_source?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_base_categories?: InputMaybe<Product_Eligible_For_Arr_Rel_Insert_Input>;
  product_type?: InputMaybe<Product_Type_Enum>;
  secondary_value?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Unit_Obj_Rel_Insert_Input>;
  unit_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  value_type?: InputMaybe<Value_Type_Enum>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_source?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  secondary_value?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  tagline?: Maybe<Scalars['String']['output']>;
  unit_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_source?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  secondary_value?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  tagline?: Maybe<Scalars['String']['output']>;
  unit_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_from?: Maybe<Scalars['date']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  allow_amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  is_internal?: InputMaybe<Order_By>;
  is_selectable?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_source?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_base_categories_aggregate?: InputMaybe<Product_Eligible_For_Aggregate_Order_By>;
  product_type?: InputMaybe<Order_By>;
  secondary_value?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  tagline?: InputMaybe<Order_By>;
  unit?: InputMaybe<Unit_Order_By>;
  unit_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  value_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  AllowAmount = 'allow_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  IsSelectable = 'is_selectable',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacySource = 'legacy_source',
  /** column name */
  Name = 'name',
  /** column name */
  ProductType = 'product_type',
  /** column name */
  SecondaryValue = 'secondary_value',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to',
  /** column name */
  Value = 'value',
  /** column name */
  ValueType = 'value_type'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  allow_amount?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  is_selectable?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_source?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_type?: InputMaybe<Product_Type_Enum>;
  secondary_value?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  unit_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  value_type?: InputMaybe<Value_Type_Enum>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  allow_amount?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  is_selectable?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_source?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product_type?: InputMaybe<Product_Type_Enum>;
  secondary_value?: InputMaybe<Scalars['numeric']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  unit_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_from?: InputMaybe<Scalars['date']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  value_type?: InputMaybe<Value_Type_Enum>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  secondary_value?: Maybe<Scalars['numeric']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "product_type" */
export type Product_Type = {
  __typename?: 'product_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "product_type" */
export type Product_Type_Aggregate = {
  __typename?: 'product_type_aggregate';
  aggregate?: Maybe<Product_Type_Aggregate_Fields>;
  nodes: Array<Product_Type>;
};

/** aggregate fields of "product_type" */
export type Product_Type_Aggregate_Fields = {
  __typename?: 'product_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Product_Type_Max_Fields>;
  min?: Maybe<Product_Type_Min_Fields>;
};


/** aggregate fields of "product_type" */
export type Product_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "product_type". All fields are combined with a logical 'AND'. */
export type Product_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Type_Bool_Exp>>;
  _not?: InputMaybe<Product_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_type" */
export enum Product_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProductTypePkey = 'product_type_pkey'
}

export enum Product_Type_Enum {
  Accessory = 'accessory',
  Discount = 'discount',
  Fee = 'fee',
  Insurance = 'insurance',
  Others = 'others',
  Service = 'service',
  Voucher = 'voucher'
}

/** Boolean expression to compare columns of type "product_type_enum". All fields are combined with logical 'AND'. */
export type Product_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Product_Type_Enum>;
  _in?: InputMaybe<Array<Product_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Product_Type_Enum>;
  _nin?: InputMaybe<Array<Product_Type_Enum>>;
};

/** input type for inserting data into table "product_type" */
export type Product_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Product_Type_Max_Fields = {
  __typename?: 'product_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Product_Type_Min_Fields = {
  __typename?: 'product_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "product_type" */
export type Product_Type_Mutation_Response = {
  __typename?: 'product_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Type>;
};

/** on_conflict condition type for table "product_type" */
export type Product_Type_On_Conflict = {
  constraint: Product_Type_Constraint;
  update_columns?: Array<Product_Type_Update_Column>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "product_type". */
export type Product_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_type */
export type Product_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "product_type" */
export enum Product_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product_type" */
export type Product_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "product_type" */
export type Product_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "product_type" */
export enum Product_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Product_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Type_Bool_Exp;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  AllowAmount = 'allow_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  IsSelectable = 'is_selectable',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacySource = 'legacy_source',
  /** column name */
  Name = 'name',
  /** column name */
  ProductType = 'product_type',
  /** column name */
  SecondaryValue = 'secondary_value',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UnitId = 'unit_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to',
  /** column name */
  Value = 'value',
  /** column name */
  ValueType = 'value_type'
}

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  secondary_value?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequests_Aggregate;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProviders_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypes_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokens_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRoles_Aggregate;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProviders_Aggregate;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRoles_Aggregate;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeys_Aggregate;
  /** fetch data from the table: "base_category" */
  base_category: Array<Base_Category>;
  /** fetch aggregated fields from the table: "base_category" */
  base_category_aggregate: Base_Category_Aggregate;
  /** fetch data from the table: "base_category" using primary key columns */
  base_category_by_pk?: Maybe<Base_Category>;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** fetch data from the table: "contact_type" */
  contact_type: Array<Contact_Type>;
  /** fetch aggregated fields from the table: "contact_type" */
  contact_type_aggregate: Contact_Type_Aggregate;
  /** fetch data from the table: "contact_type" using primary key columns */
  contact_type_by_pk?: Maybe<Contact_Type>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "entity_type" */
  entity_type: Array<Entity_Type>;
  /** fetch aggregated fields from the table: "entity_type" */
  entity_type_aggregate: Entity_Type_Aggregate;
  /** fetch data from the table: "entity_type" using primary key columns */
  entity_type_by_pk?: Maybe<Entity_Type>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table: "fuel" */
  fuel: Array<Fuel>;
  /** fetch aggregated fields from the table: "fuel" */
  fuel_aggregate: Fuel_Aggregate;
  /** fetch data from the table: "fuel" using primary key columns */
  fuel_by_pk?: Maybe<Fuel>;
  /** fetch data from the table: "gearshift" */
  gearshift: Array<Gearshift>;
  /** fetch aggregated fields from the table: "gearshift" */
  gearshift_aggregate: Gearshift_Aggregate;
  /** fetch data from the table: "gearshift" using primary key columns */
  gearshift_by_pk?: Maybe<Gearshift>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_item" */
  invoice_item: Array<Invoice_Item>;
  /** fetch aggregated fields from the table: "invoice_item" */
  invoice_item_aggregate: Invoice_Item_Aggregate;
  /** fetch data from the table: "invoice_item" using primary key columns */
  invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** fetch data from the table: "kilometer_fee" */
  kilometer_fee: Array<Kilometer_Fee>;
  /** fetch aggregated fields from the table: "kilometer_fee" */
  kilometer_fee_aggregate: Kilometer_Fee_Aggregate;
  /** fetch data from the table: "kilometer_fee" using primary key columns */
  kilometer_fee_by_pk?: Maybe<Kilometer_Fee>;
  /** fetch data from the table: "kilometer_fee_entry" */
  kilometer_fee_entry: Array<Kilometer_Fee_Entry>;
  /** fetch aggregated fields from the table: "kilometer_fee_entry" */
  kilometer_fee_entry_aggregate: Kilometer_Fee_Entry_Aggregate;
  /** fetch data from the table: "kilometer_fee_entry" using primary key columns */
  kilometer_fee_entry_by_pk?: Maybe<Kilometer_Fee_Entry>;
  /** fetch data from the table: "kilometer_fee_vehicle_type" */
  kilometer_fee_vehicle_type: Array<Kilometer_Fee_Vehicle_Type>;
  /** fetch aggregated fields from the table: "kilometer_fee_vehicle_type" */
  kilometer_fee_vehicle_type_aggregate: Kilometer_Fee_Vehicle_Type_Aggregate;
  /** fetch data from the table: "kilometer_fee_vehicle_type" using primary key columns */
  kilometer_fee_vehicle_type_by_pk?: Maybe<Kilometer_Fee_Vehicle_Type>;
  /** fetch data from the table: "log_book" */
  log_book: Array<Log_Book>;
  /** fetch aggregated fields from the table: "log_book" */
  log_book_aggregate: Log_Book_Aggregate;
  /** fetch data from the table: "log_book" using primary key columns */
  log_book_by_pk?: Maybe<Log_Book>;
  /** fetch data from the table: "note" */
  note: Array<Note>;
  /** fetch aggregated fields from the table: "note" */
  note_aggregate: Note_Aggregate;
  /** fetch data from the table: "note" using primary key columns */
  note_by_pk?: Maybe<Note>;
  /** fetch data from the table: "payment_medium" */
  payment_medium: Array<Payment_Medium>;
  /** fetch aggregated fields from the table: "payment_medium" */
  payment_medium_aggregate: Payment_Medium_Aggregate;
  /** fetch data from the table: "payment_medium" using primary key columns */
  payment_medium_by_pk?: Maybe<Payment_Medium>;
  /** fetch data from the table: "price_definition" */
  price_definition: Array<Price_Definition>;
  /** fetch aggregated fields from the table: "price_definition" */
  price_definition_aggregate: Price_Definition_Aggregate;
  /** fetch data from the table: "price_definition" using primary key columns */
  price_definition_by_pk?: Maybe<Price_Definition>;
  /** fetch data from the table: "price_definition_entry" */
  price_definition_entry: Array<Price_Definition_Entry>;
  /** fetch aggregated fields from the table: "price_definition_entry" */
  price_definition_entry_aggregate: Price_Definition_Entry_Aggregate;
  /** fetch data from the table: "price_definition_entry" using primary key columns */
  price_definition_entry_by_pk?: Maybe<Price_Definition_Entry>;
  /** fetch data from the table: "price_definition_vehicle_type" */
  price_definition_vehicle_type: Array<Price_Definition_Vehicle_Type>;
  /** fetch aggregated fields from the table: "price_definition_vehicle_type" */
  price_definition_vehicle_type_aggregate: Price_Definition_Vehicle_Type_Aggregate;
  /** fetch data from the table: "price_definition_vehicle_type" using primary key columns */
  price_definition_vehicle_type_by_pk?: Maybe<Price_Definition_Vehicle_Type>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_eligible_for" */
  product_eligible_for: Array<Product_Eligible_For>;
  /** fetch aggregated fields from the table: "product_eligible_for" */
  product_eligible_for_aggregate: Product_Eligible_For_Aggregate;
  /** fetch data from the table: "product_eligible_for" using primary key columns */
  product_eligible_for_by_pk?: Maybe<Product_Eligible_For>;
  /** fetch data from the table: "product_type" */
  product_type: Array<Product_Type>;
  /** fetch aggregated fields from the table: "product_type" */
  product_type_aggregate: Product_Type_Aggregate;
  /** fetch data from the table: "product_type" using primary key columns */
  product_type_by_pk?: Maybe<Product_Type>;
  /** fetch data from the table: "rental_contract" */
  rental_contract: Array<Rental_Contract>;
  /** fetch data from the table: "rental_contract_accessory" */
  rental_contract_accessory: Array<Rental_Contract_Accessory>;
  /** fetch aggregated fields from the table: "rental_contract_accessory" */
  rental_contract_accessory_aggregate: Rental_Contract_Accessory_Aggregate;
  /** fetch data from the table: "rental_contract_accessory" using primary key columns */
  rental_contract_accessory_by_pk?: Maybe<Rental_Contract_Accessory>;
  /** fetch aggregated fields from the table: "rental_contract" */
  rental_contract_aggregate: Rental_Contract_Aggregate;
  /** fetch data from the table: "rental_contract" using primary key columns */
  rental_contract_by_pk?: Maybe<Rental_Contract>;
  /** fetch data from the table: "rental_contract_deposit" */
  rental_contract_deposit: Array<Rental_Contract_Deposit>;
  /** fetch aggregated fields from the table: "rental_contract_deposit" */
  rental_contract_deposit_aggregate: Rental_Contract_Deposit_Aggregate;
  /** fetch data from the table: "rental_contract_deposit" using primary key columns */
  rental_contract_deposit_by_pk?: Maybe<Rental_Contract_Deposit>;
  /** fetch data from the table: "rental_contract_driver" */
  rental_contract_driver: Array<Rental_Contract_Driver>;
  /** fetch aggregated fields from the table: "rental_contract_driver" */
  rental_contract_driver_aggregate: Rental_Contract_Driver_Aggregate;
  /** fetch data from the table: "rental_contract_driver" using primary key columns */
  rental_contract_driver_by_pk?: Maybe<Rental_Contract_Driver>;
  /** fetch data from the table: "rental_contract_file" */
  rental_contract_file: Array<Rental_Contract_File>;
  /** fetch aggregated fields from the table: "rental_contract_file" */
  rental_contract_file_aggregate: Rental_Contract_File_Aggregate;
  /** fetch data from the table: "rental_contract_file" using primary key columns */
  rental_contract_file_by_pk?: Maybe<Rental_Contract_File>;
  /** fetch data from the table: "rental_contract_payment" */
  rental_contract_payment: Array<Rental_Contract_Payment>;
  /** fetch aggregated fields from the table: "rental_contract_payment" */
  rental_contract_payment_aggregate: Rental_Contract_Payment_Aggregate;
  /** fetch data from the table: "rental_contract_payment" using primary key columns */
  rental_contract_payment_by_pk?: Maybe<Rental_Contract_Payment>;
  /** fetch data from the table: "rental_state" */
  rental_state: Array<Rental_State>;
  /** fetch aggregated fields from the table: "rental_state" */
  rental_state_aggregate: Rental_State_Aggregate;
  /** fetch data from the table: "rental_state" using primary key columns */
  rental_state_by_pk?: Maybe<Rental_State>;
  /** fetch data from the table: "sequence" */
  sequence: Array<Sequence>;
  /** fetch aggregated fields from the table: "sequence" */
  sequence_aggregate: Sequence_Aggregate;
  /** fetch data from the table: "sequence" using primary key columns */
  sequence_by_pk?: Maybe<Sequence>;
  /** fetch data from the table: "sequence_type" */
  sequence_type: Array<Sequence_Type>;
  /** fetch aggregated fields from the table: "sequence_type" */
  sequence_type_aggregate: Sequence_Type_Aggregate;
  /** fetch data from the table: "sequence_type" using primary key columns */
  sequence_type_by_pk?: Maybe<Sequence_Type>;
  /** fetch data from the table: "template_document" */
  template_document: Array<Template_Document>;
  /** fetch aggregated fields from the table: "template_document" */
  template_document_aggregate: Template_Document_Aggregate;
  /** fetch data from the table: "template_document" using primary key columns */
  template_document_by_pk?: Maybe<Template_Document>;
  /** fetch data from the table: "template_email" */
  template_email: Array<Template_Email>;
  /** fetch aggregated fields from the table: "template_email" */
  template_email_aggregate: Template_Email_Aggregate;
  /** fetch data from the table: "template_email" using primary key columns */
  template_email_by_pk?: Maybe<Template_Email>;
  /** fetch data from the table: "template_group" */
  template_group: Array<Template_Group>;
  /** fetch aggregated fields from the table: "template_group" */
  template_group_aggregate: Template_Group_Aggregate;
  /** fetch data from the table: "template_group" using primary key columns */
  template_group_by_pk?: Maybe<Template_Group>;
  /** fetch data from the table: "unit" */
  unit: Array<Unit>;
  /** fetch aggregated fields from the table: "unit" */
  unit_aggregate: Unit_Aggregate;
  /** fetch data from the table: "unit" using primary key columns */
  unit_by_pk?: Maybe<Unit>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table: "value_type" */
  value_type: Array<Value_Type>;
  /** fetch aggregated fields from the table: "value_type" */
  value_type_aggregate: Value_Type_Aggregate;
  /** fetch data from the table: "value_type" using primary key columns */
  value_type_by_pk?: Maybe<Value_Type>;
  /** fetch data from the table: "vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicle" */
  vehicle_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "vehicle" using primary key columns */
  vehicle_by_pk?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_category" */
  vehicle_category: Array<Vehicle_Category>;
  /** fetch aggregated fields from the table: "vehicle_category" */
  vehicle_category_aggregate: Vehicle_Category_Aggregate;
  /** fetch data from the table: "vehicle_category" using primary key columns */
  vehicle_category_by_pk?: Maybe<Vehicle_Category>;
  /** fetch data from the table: "vehicle_type" */
  vehicle_type: Array<Vehicle_Type>;
  /** fetch aggregated fields from the table: "vehicle_type" */
  vehicle_type_aggregate: Vehicle_Type_Aggregate;
  /** fetch data from the table: "vehicle_type" using primary key columns */
  vehicle_type_by_pk?: Maybe<Vehicle_Type>;
  /** An array relationship */
  vehicle_type_categories: Array<Vehicle_Type_Categories>;
  /** An aggregate relationship */
  vehicle_type_categories_aggregate: Vehicle_Type_Categories_Aggregate;
  /** fetch data from the table: "vehicle_type_categories" using primary key columns */
  vehicle_type_categories_by_pk?: Maybe<Vehicle_Type_Categories>;
};


export type Query_RootAuthProviderArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Query_RootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Query_RootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Query_RootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Query_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Query_RootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Query_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Query_RootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Query_RootAuthRoleArgs = {
  role: Scalars['String']['input'];
};


export type Query_RootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Query_RootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Query_RootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Query_RootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Query_RootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Query_RootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Query_RootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Query_RootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Query_RootBase_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Base_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Category_Order_By>>;
  where?: InputMaybe<Base_Category_Bool_Exp>;
};


export type Query_RootBase_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Category_Order_By>>;
  where?: InputMaybe<Base_Category_Bool_Exp>;
};


export type Query_RootBase_Category_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootBucketArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Query_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Query_RootContactArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Query_RootContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Query_RootContact_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootContact_TypeArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Query_RootContact_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Query_RootContact_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEntity_TypeArgs = {
  distinct_on?: InputMaybe<Array<Entity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Type_Order_By>>;
  where?: InputMaybe<Entity_Type_Bool_Exp>;
};


export type Query_RootEntity_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Type_Order_By>>;
  where?: InputMaybe<Entity_Type_Bool_Exp>;
};


export type Query_RootEntity_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFuelArgs = {
  distinct_on?: InputMaybe<Array<Fuel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fuel_Order_By>>;
  where?: InputMaybe<Fuel_Bool_Exp>;
};


export type Query_RootFuel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fuel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fuel_Order_By>>;
  where?: InputMaybe<Fuel_Bool_Exp>;
};


export type Query_RootFuel_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootGearshiftArgs = {
  distinct_on?: InputMaybe<Array<Gearshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gearshift_Order_By>>;
  where?: InputMaybe<Gearshift_Bool_Exp>;
};


export type Query_RootGearshift_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gearshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gearshift_Order_By>>;
  where?: InputMaybe<Gearshift_Bool_Exp>;
};


export type Query_RootGearshift_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Query_RootInvoice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootInvoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};


export type Query_RootInvoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};


export type Query_RootInvoice_Item_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootKilometer_FeeArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Bool_Exp>;
};


export type Query_RootKilometer_Fee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Bool_Exp>;
};


export type Query_RootKilometer_Fee_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootKilometer_Fee_EntryArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Entry_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


export type Query_RootKilometer_Fee_Entry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Entry_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


export type Query_RootKilometer_Fee_Entry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootKilometer_Fee_Vehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


export type Query_RootKilometer_Fee_Vehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


export type Query_RootKilometer_Fee_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootLog_BookArgs = {
  distinct_on?: InputMaybe<Array<Log_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Log_Book_Order_By>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};


export type Query_RootLog_Book_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Log_Book_Order_By>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};


export type Query_RootLog_Book_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNoteArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Query_RootNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Query_RootNote_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPayment_MediumArgs = {
  distinct_on?: InputMaybe<Array<Payment_Medium_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Medium_Order_By>>;
  where?: InputMaybe<Payment_Medium_Bool_Exp>;
};


export type Query_RootPayment_Medium_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Medium_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Medium_Order_By>>;
  where?: InputMaybe<Payment_Medium_Bool_Exp>;
};


export type Query_RootPayment_Medium_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootPrice_DefinitionArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Order_By>>;
  where?: InputMaybe<Price_Definition_Bool_Exp>;
};


export type Query_RootPrice_Definition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Order_By>>;
  where?: InputMaybe<Price_Definition_Bool_Exp>;
};


export type Query_RootPrice_Definition_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPrice_Definition_EntryArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Entry_Order_By>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


export type Query_RootPrice_Definition_Entry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Entry_Order_By>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


export type Query_RootPrice_Definition_Entry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPrice_Definition_Vehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


export type Query_RootPrice_Definition_Vehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


export type Query_RootPrice_Definition_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProduct_Eligible_ForArgs = {
  distinct_on?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Eligible_For_Order_By>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};


export type Query_RootProduct_Eligible_For_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Eligible_For_Order_By>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};


export type Query_RootProduct_Eligible_For_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProduct_TypeArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type Query_RootProduct_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type Query_RootProduct_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootRental_ContractArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


export type Query_RootRental_Contract_AccessoryArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Accessory_Order_By>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


export type Query_RootRental_Contract_Accessory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Accessory_Order_By>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


export type Query_RootRental_Contract_Accessory_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRental_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


export type Query_RootRental_Contract_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRental_Contract_DepositArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Deposit_Order_By>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


export type Query_RootRental_Contract_Deposit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Deposit_Order_By>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


export type Query_RootRental_Contract_Deposit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRental_Contract_DriverArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Driver_Order_By>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


export type Query_RootRental_Contract_Driver_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Driver_Order_By>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


export type Query_RootRental_Contract_Driver_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRental_Contract_FileArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_File_Order_By>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


export type Query_RootRental_Contract_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_File_Order_By>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


export type Query_RootRental_Contract_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRental_Contract_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Payment_Order_By>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};


export type Query_RootRental_Contract_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Payment_Order_By>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};


export type Query_RootRental_Contract_Payment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRental_StateArgs = {
  distinct_on?: InputMaybe<Array<Rental_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_State_Order_By>>;
  where?: InputMaybe<Rental_State_Bool_Exp>;
};


export type Query_RootRental_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_State_Order_By>>;
  where?: InputMaybe<Rental_State_Bool_Exp>;
};


export type Query_RootRental_State_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootSequenceArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Order_By>>;
  where?: InputMaybe<Sequence_Bool_Exp>;
};


export type Query_RootSequence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Order_By>>;
  where?: InputMaybe<Sequence_Bool_Exp>;
};


export type Query_RootSequence_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSequence_TypeArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Type_Order_By>>;
  where?: InputMaybe<Sequence_Type_Bool_Exp>;
};


export type Query_RootSequence_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Type_Order_By>>;
  where?: InputMaybe<Sequence_Type_Bool_Exp>;
};


export type Query_RootSequence_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootTemplate_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Template_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Document_Order_By>>;
  where?: InputMaybe<Template_Document_Bool_Exp>;
};


export type Query_RootTemplate_Document_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Document_Order_By>>;
  where?: InputMaybe<Template_Document_Bool_Exp>;
};


export type Query_RootTemplate_Document_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTemplate_EmailArgs = {
  distinct_on?: InputMaybe<Array<Template_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Email_Order_By>>;
  where?: InputMaybe<Template_Email_Bool_Exp>;
};


export type Query_RootTemplate_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Email_Order_By>>;
  where?: InputMaybe<Template_Email_Bool_Exp>;
};


export type Query_RootTemplate_Email_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTemplate_GroupArgs = {
  distinct_on?: InputMaybe<Array<Template_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Group_Order_By>>;
  where?: InputMaybe<Template_Group_Bool_Exp>;
};


export type Query_RootTemplate_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Group_Order_By>>;
  where?: InputMaybe<Template_Group_Bool_Exp>;
};


export type Query_RootTemplate_Group_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUnitArgs = {
  distinct_on?: InputMaybe<Array<Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unit_Order_By>>;
  where?: InputMaybe<Unit_Bool_Exp>;
};


export type Query_RootUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unit_Order_By>>;
  where?: InputMaybe<Unit_Bool_Exp>;
};


export type Query_RootUnit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootValue_TypeArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Query_RootValue_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Query_RootValue_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootVehicleArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};


export type Query_RootVehicle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};


export type Query_RootVehicle_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVehicle_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Category_Order_By>>;
  where?: InputMaybe<Vehicle_Category_Bool_Exp>;
};


export type Query_RootVehicle_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Category_Order_By>>;
  where?: InputMaybe<Vehicle_Category_Bool_Exp>;
};


export type Query_RootVehicle_Category_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};


export type Query_RootVehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};


export type Query_RootVehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVehicle_Type_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


export type Query_RootVehicle_Type_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


export type Query_RootVehicle_Type_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "rental_contract" */
export type Rental_Contract = {
  __typename?: 'rental_contract';
  /** An array relationship */
  accessories: Array<Rental_Contract_Accessory>;
  /** An aggregate relationship */
  accessories_aggregate: Rental_Contract_Accessory_Aggregate;
  actual_kilometer?: Maybe<Scalars['numeric']['output']>;
  actual_to?: Maybe<Scalars['timestamptz']['output']>;
  actual_vehicle_id?: Maybe<Scalars['uuid']['output']>;
  calculation_duration?: Maybe<Scalars['numeric']['output']>;
  calculation_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_is_half_day?: Maybe<Scalars['Boolean']['output']>;
  calculation_total_accessories?: Maybe<Scalars['numeric']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['numeric']['output']>;
  calculation_total_car_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_discount?: Maybe<Scalars['numeric']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_gross?: Maybe<Scalars['numeric']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['numeric']['output']>;
  calculation_total_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_tax?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  deposits: Array<Rental_Contract_Deposit>;
  /** An aggregate relationship */
  deposits_aggregate: Rental_Contract_Deposit_Aggregate;
  /** An object relationship */
  discount?: Maybe<Product>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  drivers: Array<Rental_Contract_Driver>;
  /** An aggregate relationship */
  drivers_aggregate: Rental_Contract_Driver_Aggregate;
  expected_kilometer?: Maybe<Scalars['numeric']['output']>;
  expected_to?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  files: Array<Rental_Contract_File>;
  /** An aggregate relationship */
  files_aggregate: Rental_Contract_File_Aggregate;
  /** An object relationship */
  foreign_country?: Maybe<Country>;
  foreign_country_id?: Maybe<Scalars['uuid']['output']>;
  from: Scalars['timestamptz']['output'];
  from_booking_portal?: Maybe<Scalars['Boolean']['output']>;
  fuel_end?: Maybe<Scalars['numeric']['output']>;
  fuel_start?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  insurance?: Maybe<Product>;
  insurance_id?: Maybe<Scalars['uuid']['output']>;
  insurance_retention?: Maybe<Scalars['numeric']['output']>;
  internal_reference_number?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  is_archived?: Maybe<Scalars['Boolean']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  is_paid?: Maybe<Scalars['Boolean']['output']>;
  is_ride_to_foreign_country?: Maybe<Scalars['Boolean']['output']>;
  is_weekend?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
  minimum_deposit?: Maybe<Scalars['numeric']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  payments: Array<Rental_Contract_Payment>;
  /** An aggregate relationship */
  payments_aggregate: Rental_Contract_Payment_Aggregate;
  /** An object relationship */
  primary_contact?: Maybe<Contact>;
  primary_contact_id?: Maybe<Scalars['uuid']['output']>;
  refueled?: Maybe<Scalars['numeric']['output']>;
  rental_contract_number?: Maybe<Scalars['String']['output']>;
  rental_state: Rental_State_Enum;
  request_note?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  requested_vehicle_type?: Maybe<Vehicle_Type>;
  requested_vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  secondary_contact?: Maybe<Contact>;
  secondary_contact_id?: Maybe<Scalars['uuid']['output']>;
  terms_checked: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractAccessoriesArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Accessory_Order_By>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractAccessories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Accessory_Order_By>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractDepositsArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Deposit_Order_By>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractDeposits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Deposit_Order_By>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractDriversArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Driver_Order_By>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractDrivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Driver_Order_By>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractFilesArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_File_Order_By>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_File_Order_By>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Payment_Order_By>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};


/** columns and relationships of "rental_contract" */
export type Rental_ContractPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Payment_Order_By>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};

/** columns and relationships of "rental_contract_accessory" */
export type Rental_Contract_Accessory = {
  __typename?: 'rental_contract_accessory';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['uuid']['output'];
  /** An object relationship */
  rental_contract: Rental_Contract;
  rental_contract_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rental_contract_accessory" */
export type Rental_Contract_Accessory_Aggregate = {
  __typename?: 'rental_contract_accessory_aggregate';
  aggregate?: Maybe<Rental_Contract_Accessory_Aggregate_Fields>;
  nodes: Array<Rental_Contract_Accessory>;
};

export type Rental_Contract_Accessory_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rental_Contract_Accessory_Aggregate_Bool_Exp_Count>;
};

export type Rental_Contract_Accessory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_contract_accessory" */
export type Rental_Contract_Accessory_Aggregate_Fields = {
  __typename?: 'rental_contract_accessory_aggregate_fields';
  avg?: Maybe<Rental_Contract_Accessory_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_Contract_Accessory_Max_Fields>;
  min?: Maybe<Rental_Contract_Accessory_Min_Fields>;
  stddev?: Maybe<Rental_Contract_Accessory_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Contract_Accessory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Contract_Accessory_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Contract_Accessory_Sum_Fields>;
  var_pop?: Maybe<Rental_Contract_Accessory_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Contract_Accessory_Var_Samp_Fields>;
  variance?: Maybe<Rental_Contract_Accessory_Variance_Fields>;
};


/** aggregate fields of "rental_contract_accessory" */
export type Rental_Contract_Accessory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Aggregate_Order_By = {
  avg?: InputMaybe<Rental_Contract_Accessory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rental_Contract_Accessory_Max_Order_By>;
  min?: InputMaybe<Rental_Contract_Accessory_Min_Order_By>;
  stddev?: InputMaybe<Rental_Contract_Accessory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rental_Contract_Accessory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rental_Contract_Accessory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rental_Contract_Accessory_Sum_Order_By>;
  var_pop?: InputMaybe<Rental_Contract_Accessory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rental_Contract_Accessory_Var_Samp_Order_By>;
  variance?: InputMaybe<Rental_Contract_Accessory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Arr_Rel_Insert_Input = {
  data: Array<Rental_Contract_Accessory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_Accessory_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Contract_Accessory_Avg_Fields = {
  __typename?: 'rental_contract_accessory_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental_contract_accessory". All fields are combined with a logical 'AND'. */
export type Rental_Contract_Accessory_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_Contract_Accessory_Bool_Exp>>;
  _not?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_Contract_Accessory_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  rental_contract?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_contract_accessory" */
export enum Rental_Contract_Accessory_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalContractProductPkey = 'rental_contract_product_pkey'
}

/** input type for incrementing numeric columns in table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract?: InputMaybe<Rental_Contract_Obj_Rel_Insert_Input>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rental_Contract_Accessory_Max_Fields = {
  __typename?: 'rental_contract_accessory_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Contract_Accessory_Min_Fields = {
  __typename?: 'rental_contract_accessory_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  product_id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Mutation_Response = {
  __typename?: 'rental_contract_accessory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Contract_Accessory>;
};

/** on_conflict condition type for table "rental_contract_accessory" */
export type Rental_Contract_Accessory_On_Conflict = {
  constraint: Rental_Contract_Accessory_Constraint;
  update_columns?: Array<Rental_Contract_Accessory_Update_Column>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_contract_accessory". */
export type Rental_Contract_Accessory_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  rental_contract?: InputMaybe<Rental_Contract_Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_contract_accessory */
export type Rental_Contract_Accessory_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rental_contract_accessory" */
export enum Rental_Contract_Accessory_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rental_Contract_Accessory_Stddev_Fields = {
  __typename?: 'rental_contract_accessory_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Contract_Accessory_Stddev_Pop_Fields = {
  __typename?: 'rental_contract_accessory_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Contract_Accessory_Stddev_Samp_Fields = {
  __typename?: 'rental_contract_accessory_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Contract_Accessory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Contract_Accessory_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  product_id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rental_Contract_Accessory_Sum_Fields = {
  __typename?: 'rental_contract_accessory_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "rental_contract_accessory" */
export enum Rental_Contract_Accessory_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Rental_Contract_Accessory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rental_Contract_Accessory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_Contract_Accessory_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Contract_Accessory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rental_Contract_Accessory_Var_Pop_Fields = {
  __typename?: 'rental_contract_accessory_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Contract_Accessory_Var_Samp_Fields = {
  __typename?: 'rental_contract_accessory_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Contract_Accessory_Variance_Fields = {
  __typename?: 'rental_contract_accessory_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rental_contract_accessory" */
export type Rental_Contract_Accessory_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregated selection of "rental_contract" */
export type Rental_Contract_Aggregate = {
  __typename?: 'rental_contract_aggregate';
  aggregate?: Maybe<Rental_Contract_Aggregate_Fields>;
  nodes: Array<Rental_Contract>;
};

export type Rental_Contract_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rental_Contract_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rental_Contract_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rental_Contract_Aggregate_Bool_Exp_Count>;
};

export type Rental_Contract_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rental_Contract_Select_Column_Rental_Contract_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rental_Contract_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rental_Contract_Select_Column_Rental_Contract_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rental_Contract_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_contract" */
export type Rental_Contract_Aggregate_Fields = {
  __typename?: 'rental_contract_aggregate_fields';
  avg?: Maybe<Rental_Contract_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_Contract_Max_Fields>;
  min?: Maybe<Rental_Contract_Min_Fields>;
  stddev?: Maybe<Rental_Contract_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Contract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Contract_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Contract_Sum_Fields>;
  var_pop?: Maybe<Rental_Contract_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Contract_Var_Samp_Fields>;
  variance?: Maybe<Rental_Contract_Variance_Fields>;
};


/** aggregate fields of "rental_contract" */
export type Rental_Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rental_contract" */
export type Rental_Contract_Aggregate_Order_By = {
  avg?: InputMaybe<Rental_Contract_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rental_Contract_Max_Order_By>;
  min?: InputMaybe<Rental_Contract_Min_Order_By>;
  stddev?: InputMaybe<Rental_Contract_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rental_Contract_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rental_Contract_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rental_Contract_Sum_Order_By>;
  var_pop?: InputMaybe<Rental_Contract_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rental_Contract_Var_Samp_Order_By>;
  variance?: InputMaybe<Rental_Contract_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental_contract" */
export type Rental_Contract_Arr_Rel_Insert_Input = {
  data: Array<Rental_Contract_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Contract_Avg_Fields = {
  __typename?: 'rental_contract_avg_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rental_contract" */
export type Rental_Contract_Avg_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental_contract". All fields are combined with a logical 'AND'. */
export type Rental_Contract_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_Contract_Bool_Exp>>;
  _not?: InputMaybe<Rental_Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_Contract_Bool_Exp>>;
  accessories?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
  accessories_aggregate?: InputMaybe<Rental_Contract_Accessory_Aggregate_Bool_Exp>;
  actual_kilometer?: InputMaybe<Numeric_Comparison_Exp>;
  actual_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  actual_vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
  calculation_duration?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_insurance?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_is_half_day?: InputMaybe<Boolean_Comparison_Exp>;
  calculation_total_accessories?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_all_round_price?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_car_net?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_contract_fee?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_discount?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_foreign_country_fee?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_gross?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_included_kilometer?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_insurance?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_net?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Numeric_Comparison_Exp>;
  calculation_total_tax?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  deposits?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
  deposits_aggregate?: InputMaybe<Rental_Contract_Deposit_Aggregate_Bool_Exp>;
  discount?: InputMaybe<Product_Bool_Exp>;
  discount_id?: InputMaybe<Uuid_Comparison_Exp>;
  drivers?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
  drivers_aggregate?: InputMaybe<Rental_Contract_Driver_Aggregate_Bool_Exp>;
  expected_kilometer?: InputMaybe<Numeric_Comparison_Exp>;
  expected_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  files?: InputMaybe<Rental_Contract_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Rental_Contract_File_Aggregate_Bool_Exp>;
  foreign_country?: InputMaybe<Country_Bool_Exp>;
  foreign_country_id?: InputMaybe<Uuid_Comparison_Exp>;
  from?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_booking_portal?: InputMaybe<Boolean_Comparison_Exp>;
  fuel_end?: InputMaybe<Numeric_Comparison_Exp>;
  fuel_start?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurance?: InputMaybe<Product_Bool_Exp>;
  insurance_id?: InputMaybe<Uuid_Comparison_Exp>;
  insurance_retention?: InputMaybe<Numeric_Comparison_Exp>;
  internal_reference_number?: InputMaybe<String_Comparison_Exp>;
  invoices?: InputMaybe<Invoice_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  is_archived?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_paid?: InputMaybe<Boolean_Comparison_Exp>;
  is_ride_to_foreign_country?: InputMaybe<Boolean_Comparison_Exp>;
  is_weekend?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  mileage_end?: InputMaybe<Numeric_Comparison_Exp>;
  mileage_start?: InputMaybe<Numeric_Comparison_Exp>;
  minimum_deposit?: InputMaybe<Numeric_Comparison_Exp>;
  newsletter?: InputMaybe<Boolean_Comparison_Exp>;
  paid_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  payments?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
  payments_aggregate?: InputMaybe<Rental_Contract_Payment_Aggregate_Bool_Exp>;
  primary_contact?: InputMaybe<Contact_Bool_Exp>;
  primary_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  refueled?: InputMaybe<Numeric_Comparison_Exp>;
  rental_contract_number?: InputMaybe<String_Comparison_Exp>;
  rental_state?: InputMaybe<Rental_State_Enum_Comparison_Exp>;
  request_note?: InputMaybe<String_Comparison_Exp>;
  requested_vehicle_type?: InputMaybe<Vehicle_Type_Bool_Exp>;
  requested_vehicle_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  secondary_contact?: InputMaybe<Contact_Bool_Exp>;
  secondary_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  terms_checked?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
};

/** unique or primary key constraints on table "rental_contract" */
export enum Rental_Contract_Constraint {
  /** unique or primary key constraint on columns "legacy_id" */
  RentalContractLegacyIdKey = 'rental_contract_legacy_id_key',
  /** unique or primary key constraint on columns "id" */
  RentalContractPkey = 'rental_contract_pkey'
}

/** columns and relationships of "rental_contract_deposit" */
export type Rental_Contract_Deposit = {
  __typename?: 'rental_contract_deposit';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  payment_medium: Payment_Medium_Enum;
  /** An object relationship */
  rental_contract: Rental_Contract;
  rental_contract_id: Scalars['uuid']['output'];
  returned?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rental_contract_deposit" */
export type Rental_Contract_Deposit_Aggregate = {
  __typename?: 'rental_contract_deposit_aggregate';
  aggregate?: Maybe<Rental_Contract_Deposit_Aggregate_Fields>;
  nodes: Array<Rental_Contract_Deposit>;
};

export type Rental_Contract_Deposit_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rental_Contract_Deposit_Aggregate_Bool_Exp_Count>;
};

export type Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rental_Contract_Deposit_Select_Column_Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rental_Contract_Deposit_Select_Column_Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rental_Contract_Deposit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_contract_deposit" */
export type Rental_Contract_Deposit_Aggregate_Fields = {
  __typename?: 'rental_contract_deposit_aggregate_fields';
  avg?: Maybe<Rental_Contract_Deposit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_Contract_Deposit_Max_Fields>;
  min?: Maybe<Rental_Contract_Deposit_Min_Fields>;
  stddev?: Maybe<Rental_Contract_Deposit_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Contract_Deposit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Contract_Deposit_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Contract_Deposit_Sum_Fields>;
  var_pop?: Maybe<Rental_Contract_Deposit_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Contract_Deposit_Var_Samp_Fields>;
  variance?: Maybe<Rental_Contract_Deposit_Variance_Fields>;
};


/** aggregate fields of "rental_contract_deposit" */
export type Rental_Contract_Deposit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Aggregate_Order_By = {
  avg?: InputMaybe<Rental_Contract_Deposit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rental_Contract_Deposit_Max_Order_By>;
  min?: InputMaybe<Rental_Contract_Deposit_Min_Order_By>;
  stddev?: InputMaybe<Rental_Contract_Deposit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rental_Contract_Deposit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rental_Contract_Deposit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rental_Contract_Deposit_Sum_Order_By>;
  var_pop?: InputMaybe<Rental_Contract_Deposit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rental_Contract_Deposit_Var_Samp_Order_By>;
  variance?: InputMaybe<Rental_Contract_Deposit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Arr_Rel_Insert_Input = {
  data: Array<Rental_Contract_Deposit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_Deposit_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Contract_Deposit_Avg_Fields = {
  __typename?: 'rental_contract_deposit_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental_contract_deposit". All fields are combined with a logical 'AND'. */
export type Rental_Contract_Deposit_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_Contract_Deposit_Bool_Exp>>;
  _not?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_Contract_Deposit_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_medium?: InputMaybe<Payment_Medium_Enum_Comparison_Exp>;
  rental_contract?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  returned?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_contract_deposit" */
export enum Rental_Contract_Deposit_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalContractDepositPkey = 'rental_contract_deposit_pkey'
}

/** input type for incrementing numeric columns in table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_medium?: InputMaybe<Payment_Medium_Enum>;
  rental_contract?: InputMaybe<Rental_Contract_Obj_Rel_Insert_Input>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  returned?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rental_Contract_Deposit_Max_Fields = {
  __typename?: 'rental_contract_deposit_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Contract_Deposit_Min_Fields = {
  __typename?: 'rental_contract_deposit_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Mutation_Response = {
  __typename?: 'rental_contract_deposit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Contract_Deposit>;
};

/** on_conflict condition type for table "rental_contract_deposit" */
export type Rental_Contract_Deposit_On_Conflict = {
  constraint: Rental_Contract_Deposit_Constraint;
  update_columns?: Array<Rental_Contract_Deposit_Update_Column>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_contract_deposit". */
export type Rental_Contract_Deposit_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_medium?: InputMaybe<Order_By>;
  rental_contract?: InputMaybe<Rental_Contract_Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  returned?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_contract_deposit */
export type Rental_Contract_Deposit_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rental_contract_deposit" */
export enum Rental_Contract_Deposit_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMedium = 'payment_medium',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  Returned = 'returned',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "rental_contract_deposit_aggregate_bool_exp_bool_and_arguments_columns" columns of table "rental_contract_deposit" */
export enum Rental_Contract_Deposit_Select_Column_Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Returned = 'returned'
}

/** select "rental_contract_deposit_aggregate_bool_exp_bool_or_arguments_columns" columns of table "rental_contract_deposit" */
export enum Rental_Contract_Deposit_Select_Column_Rental_Contract_Deposit_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Returned = 'returned'
}

/** input type for updating data in table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_medium?: InputMaybe<Payment_Medium_Enum>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  returned?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rental_Contract_Deposit_Stddev_Fields = {
  __typename?: 'rental_contract_deposit_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Contract_Deposit_Stddev_Pop_Fields = {
  __typename?: 'rental_contract_deposit_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Contract_Deposit_Stddev_Samp_Fields = {
  __typename?: 'rental_contract_deposit_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Contract_Deposit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Contract_Deposit_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_medium?: InputMaybe<Payment_Medium_Enum>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  returned?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rental_Contract_Deposit_Sum_Fields = {
  __typename?: 'rental_contract_deposit_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "rental_contract_deposit" */
export enum Rental_Contract_Deposit_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMedium = 'payment_medium',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  Returned = 'returned',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Rental_Contract_Deposit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rental_Contract_Deposit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_Contract_Deposit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Contract_Deposit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rental_Contract_Deposit_Var_Pop_Fields = {
  __typename?: 'rental_contract_deposit_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Contract_Deposit_Var_Samp_Fields = {
  __typename?: 'rental_contract_deposit_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Contract_Deposit_Variance_Fields = {
  __typename?: 'rental_contract_deposit_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rental_contract_deposit" */
export type Rental_Contract_Deposit_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "rental_contract_driver" */
export type Rental_Contract_Driver = {
  __typename?: 'rental_contract_driver';
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  rental_contract: Rental_Contract;
  rental_contract_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rental_contract_driver" */
export type Rental_Contract_Driver_Aggregate = {
  __typename?: 'rental_contract_driver_aggregate';
  aggregate?: Maybe<Rental_Contract_Driver_Aggregate_Fields>;
  nodes: Array<Rental_Contract_Driver>;
};

export type Rental_Contract_Driver_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rental_Contract_Driver_Aggregate_Bool_Exp_Count>;
};

export type Rental_Contract_Driver_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_contract_driver" */
export type Rental_Contract_Driver_Aggregate_Fields = {
  __typename?: 'rental_contract_driver_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_Contract_Driver_Max_Fields>;
  min?: Maybe<Rental_Contract_Driver_Min_Fields>;
};


/** aggregate fields of "rental_contract_driver" */
export type Rental_Contract_Driver_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rental_contract_driver" */
export type Rental_Contract_Driver_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rental_Contract_Driver_Max_Order_By>;
  min?: InputMaybe<Rental_Contract_Driver_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rental_contract_driver" */
export type Rental_Contract_Driver_Arr_Rel_Insert_Input = {
  data: Array<Rental_Contract_Driver_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_Driver_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rental_contract_driver". All fields are combined with a logical 'AND'. */
export type Rental_Contract_Driver_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_Contract_Driver_Bool_Exp>>;
  _not?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_Contract_Driver_Bool_Exp>>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rental_contract?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_contract_driver" */
export enum Rental_Contract_Driver_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalContractContactPkey = 'rental_contract_contact_pkey'
}

/** input type for inserting data into table "rental_contract_driver" */
export type Rental_Contract_Driver_Insert_Input = {
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract?: InputMaybe<Rental_Contract_Obj_Rel_Insert_Input>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rental_Contract_Driver_Max_Fields = {
  __typename?: 'rental_contract_driver_max_fields';
  contact_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rental_contract_driver" */
export type Rental_Contract_Driver_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Contract_Driver_Min_Fields = {
  __typename?: 'rental_contract_driver_min_fields';
  contact_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rental_contract_driver" */
export type Rental_Contract_Driver_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rental_contract_driver" */
export type Rental_Contract_Driver_Mutation_Response = {
  __typename?: 'rental_contract_driver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Contract_Driver>;
};

/** on_conflict condition type for table "rental_contract_driver" */
export type Rental_Contract_Driver_On_Conflict = {
  constraint: Rental_Contract_Driver_Constraint;
  update_columns?: Array<Rental_Contract_Driver_Update_Column>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_contract_driver". */
export type Rental_Contract_Driver_Order_By = {
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract?: InputMaybe<Rental_Contract_Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_contract_driver */
export type Rental_Contract_Driver_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rental_contract_driver" */
export enum Rental_Contract_Driver_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "rental_contract_driver" */
export type Rental_Contract_Driver_Set_Input = {
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "rental_contract_driver" */
export type Rental_Contract_Driver_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Contract_Driver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Contract_Driver_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "rental_contract_driver" */
export enum Rental_Contract_Driver_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Rental_Contract_Driver_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_Contract_Driver_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Contract_Driver_Bool_Exp;
};

/** columns and relationships of "rental_contract_file" */
export type Rental_Contract_File = {
  __typename?: 'rental_contract_file';
  content_type: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  encoding: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  originalname: Scalars['String']['output'];
  /** An object relationship */
  rental_contract: Rental_Contract;
  rental_contract_id: Scalars['uuid']['output'];
  size: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rental_contract_file" */
export type Rental_Contract_File_Aggregate = {
  __typename?: 'rental_contract_file_aggregate';
  aggregate?: Maybe<Rental_Contract_File_Aggregate_Fields>;
  nodes: Array<Rental_Contract_File>;
};

export type Rental_Contract_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rental_Contract_File_Aggregate_Bool_Exp_Count>;
};

export type Rental_Contract_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_contract_file" */
export type Rental_Contract_File_Aggregate_Fields = {
  __typename?: 'rental_contract_file_aggregate_fields';
  avg?: Maybe<Rental_Contract_File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_Contract_File_Max_Fields>;
  min?: Maybe<Rental_Contract_File_Min_Fields>;
  stddev?: Maybe<Rental_Contract_File_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Contract_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Contract_File_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Contract_File_Sum_Fields>;
  var_pop?: Maybe<Rental_Contract_File_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Contract_File_Var_Samp_Fields>;
  variance?: Maybe<Rental_Contract_File_Variance_Fields>;
};


/** aggregate fields of "rental_contract_file" */
export type Rental_Contract_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rental_contract_file" */
export type Rental_Contract_File_Aggregate_Order_By = {
  avg?: InputMaybe<Rental_Contract_File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rental_Contract_File_Max_Order_By>;
  min?: InputMaybe<Rental_Contract_File_Min_Order_By>;
  stddev?: InputMaybe<Rental_Contract_File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rental_Contract_File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rental_Contract_File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rental_Contract_File_Sum_Order_By>;
  var_pop?: InputMaybe<Rental_Contract_File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rental_Contract_File_Var_Samp_Order_By>;
  variance?: InputMaybe<Rental_Contract_File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental_contract_file" */
export type Rental_Contract_File_Arr_Rel_Insert_Input = {
  data: Array<Rental_Contract_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_File_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Contract_File_Avg_Fields = {
  __typename?: 'rental_contract_file_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental_contract_file". All fields are combined with a logical 'AND'. */
export type Rental_Contract_File_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_Contract_File_Bool_Exp>>;
  _not?: InputMaybe<Rental_Contract_File_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_Contract_File_Bool_Exp>>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  encoding?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  originalname?: InputMaybe<String_Comparison_Exp>;
  rental_contract?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_contract_file" */
export enum Rental_Contract_File_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalContractFilePkey = 'rental_contract_file_pkey'
}

/** input type for incrementing numeric columns in table "rental_contract_file" */
export type Rental_Contract_File_Inc_Input = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "rental_contract_file" */
export type Rental_Contract_File_Insert_Input = {
  content_type?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalname?: InputMaybe<Scalars['String']['input']>;
  rental_contract?: InputMaybe<Rental_Contract_Obj_Rel_Insert_Input>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rental_Contract_File_Max_Fields = {
  __typename?: 'rental_contract_file_max_fields';
  content_type?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalname?: Maybe<Scalars['String']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Max_Order_By = {
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  encoding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  originalname?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Contract_File_Min_Fields = {
  __typename?: 'rental_contract_file_min_fields';
  content_type?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalname?: Maybe<Scalars['String']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Min_Order_By = {
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  encoding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  originalname?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rental_contract_file" */
export type Rental_Contract_File_Mutation_Response = {
  __typename?: 'rental_contract_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Contract_File>;
};

/** on_conflict condition type for table "rental_contract_file" */
export type Rental_Contract_File_On_Conflict = {
  constraint: Rental_Contract_File_Constraint;
  update_columns?: Array<Rental_Contract_File_Update_Column>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_contract_file". */
export type Rental_Contract_File_Order_By = {
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  encoding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  originalname?: InputMaybe<Order_By>;
  rental_contract?: InputMaybe<Rental_Contract_Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_contract_file */
export type Rental_Contract_File_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rental_contract_file" */
export enum Rental_Contract_File_Select_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Encoding = 'encoding',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Originalname = 'originalname',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "rental_contract_file" */
export type Rental_Contract_File_Set_Input = {
  content_type?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalname?: InputMaybe<Scalars['String']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rental_Contract_File_Stddev_Fields = {
  __typename?: 'rental_contract_file_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Contract_File_Stddev_Pop_Fields = {
  __typename?: 'rental_contract_file_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Contract_File_Stddev_Samp_Fields = {
  __typename?: 'rental_contract_file_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rental_contract_file" */
export type Rental_Contract_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Contract_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Contract_File_Stream_Cursor_Value_Input = {
  content_type?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  encoding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalname?: InputMaybe<Scalars['String']['input']>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rental_Contract_File_Sum_Fields = {
  __typename?: 'rental_contract_file_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** update columns of table "rental_contract_file" */
export enum Rental_Contract_File_Update_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Encoding = 'encoding',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Originalname = 'originalname',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Rental_Contract_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rental_Contract_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_Contract_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Contract_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rental_Contract_File_Var_Pop_Fields = {
  __typename?: 'rental_contract_file_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Contract_File_Var_Samp_Fields = {
  __typename?: 'rental_contract_file_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Contract_File_Variance_Fields = {
  __typename?: 'rental_contract_file_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rental_contract_file" */
export type Rental_Contract_File_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "rental_contract" */
export type Rental_Contract_Inc_Input = {
  actual_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  calculation_duration?: InputMaybe<Scalars['numeric']['input']>;
  calculation_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_accessories?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_all_round_price?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_car_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_discount?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_foreign_country_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_gross?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_tax?: InputMaybe<Scalars['numeric']['input']>;
  expected_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  fuel_end?: InputMaybe<Scalars['numeric']['input']>;
  fuel_start?: InputMaybe<Scalars['numeric']['input']>;
  insurance_retention?: InputMaybe<Scalars['numeric']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  minimum_deposit?: InputMaybe<Scalars['numeric']['input']>;
  refueled?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "rental_contract" */
export type Rental_Contract_Insert_Input = {
  accessories?: InputMaybe<Rental_Contract_Accessory_Arr_Rel_Insert_Input>;
  actual_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  actual_to?: InputMaybe<Scalars['timestamptz']['input']>;
  actual_vehicle_id?: InputMaybe<Scalars['uuid']['input']>;
  calculation_duration?: InputMaybe<Scalars['numeric']['input']>;
  calculation_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_is_half_day?: InputMaybe<Scalars['Boolean']['input']>;
  calculation_total_accessories?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_all_round_price?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_car_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_discount?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_foreign_country_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_gross?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_tax?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  deposits?: InputMaybe<Rental_Contract_Deposit_Arr_Rel_Insert_Input>;
  discount?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  drivers?: InputMaybe<Rental_Contract_Driver_Arr_Rel_Insert_Input>;
  expected_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  expected_to?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Rental_Contract_File_Arr_Rel_Insert_Input>;
  foreign_country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  foreign_country_id?: InputMaybe<Scalars['uuid']['input']>;
  from?: InputMaybe<Scalars['timestamptz']['input']>;
  from_booking_portal?: InputMaybe<Scalars['Boolean']['input']>;
  fuel_end?: InputMaybe<Scalars['numeric']['input']>;
  fuel_start?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  insurance?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  insurance_id?: InputMaybe<Scalars['uuid']['input']>;
  insurance_retention?: InputMaybe<Scalars['numeric']['input']>;
  internal_reference_number?: InputMaybe<Scalars['String']['input']>;
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_paid?: InputMaybe<Scalars['Boolean']['input']>;
  is_ride_to_foreign_country?: InputMaybe<Scalars['Boolean']['input']>;
  is_weekend?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  minimum_deposit?: InputMaybe<Scalars['numeric']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  payments?: InputMaybe<Rental_Contract_Payment_Arr_Rel_Insert_Input>;
  primary_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  primary_contact_id?: InputMaybe<Scalars['uuid']['input']>;
  refueled?: InputMaybe<Scalars['numeric']['input']>;
  rental_contract_number?: InputMaybe<Scalars['String']['input']>;
  rental_state?: InputMaybe<Rental_State_Enum>;
  request_note?: InputMaybe<Scalars['String']['input']>;
  requested_vehicle_type?: InputMaybe<Vehicle_Type_Obj_Rel_Insert_Input>;
  requested_vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
  secondary_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  secondary_contact_id?: InputMaybe<Scalars['uuid']['input']>;
  terms_checked?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Rental_Contract_Max_Fields = {
  __typename?: 'rental_contract_max_fields';
  actual_kilometer?: Maybe<Scalars['numeric']['output']>;
  actual_to?: Maybe<Scalars['timestamptz']['output']>;
  actual_vehicle_id?: Maybe<Scalars['uuid']['output']>;
  calculation_duration?: Maybe<Scalars['numeric']['output']>;
  calculation_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_accessories?: Maybe<Scalars['numeric']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['numeric']['output']>;
  calculation_total_car_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_discount?: Maybe<Scalars['numeric']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_gross?: Maybe<Scalars['numeric']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['numeric']['output']>;
  calculation_total_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_tax?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expected_kilometer?: Maybe<Scalars['numeric']['output']>;
  expected_to?: Maybe<Scalars['timestamptz']['output']>;
  foreign_country_id?: Maybe<Scalars['uuid']['output']>;
  from?: Maybe<Scalars['timestamptz']['output']>;
  fuel_end?: Maybe<Scalars['numeric']['output']>;
  fuel_start?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  insurance_id?: Maybe<Scalars['uuid']['output']>;
  insurance_retention?: Maybe<Scalars['numeric']['output']>;
  internal_reference_number?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
  minimum_deposit?: Maybe<Scalars['numeric']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  primary_contact_id?: Maybe<Scalars['uuid']['output']>;
  refueled?: Maybe<Scalars['numeric']['output']>;
  rental_contract_number?: Maybe<Scalars['String']['output']>;
  request_note?: Maybe<Scalars['String']['output']>;
  requested_vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
  secondary_contact_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rental_contract" */
export type Rental_Contract_Max_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  actual_to?: InputMaybe<Order_By>;
  actual_vehicle_id?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  expected_to?: InputMaybe<Order_By>;
  foreign_country_id?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  internal_reference_number?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  primary_contact_id?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
  rental_contract_number?: InputMaybe<Order_By>;
  request_note?: InputMaybe<Order_By>;
  requested_vehicle_type_id?: InputMaybe<Order_By>;
  secondary_contact_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Contract_Min_Fields = {
  __typename?: 'rental_contract_min_fields';
  actual_kilometer?: Maybe<Scalars['numeric']['output']>;
  actual_to?: Maybe<Scalars['timestamptz']['output']>;
  actual_vehicle_id?: Maybe<Scalars['uuid']['output']>;
  calculation_duration?: Maybe<Scalars['numeric']['output']>;
  calculation_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_accessories?: Maybe<Scalars['numeric']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['numeric']['output']>;
  calculation_total_car_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_discount?: Maybe<Scalars['numeric']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_gross?: Maybe<Scalars['numeric']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['numeric']['output']>;
  calculation_total_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_tax?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['uuid']['output']>;
  expected_kilometer?: Maybe<Scalars['numeric']['output']>;
  expected_to?: Maybe<Scalars['timestamptz']['output']>;
  foreign_country_id?: Maybe<Scalars['uuid']['output']>;
  from?: Maybe<Scalars['timestamptz']['output']>;
  fuel_end?: Maybe<Scalars['numeric']['output']>;
  fuel_start?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  insurance_id?: Maybe<Scalars['uuid']['output']>;
  insurance_retention?: Maybe<Scalars['numeric']['output']>;
  internal_reference_number?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
  minimum_deposit?: Maybe<Scalars['numeric']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  primary_contact_id?: Maybe<Scalars['uuid']['output']>;
  refueled?: Maybe<Scalars['numeric']['output']>;
  rental_contract_number?: Maybe<Scalars['String']['output']>;
  request_note?: Maybe<Scalars['String']['output']>;
  requested_vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
  secondary_contact_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rental_contract" */
export type Rental_Contract_Min_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  actual_to?: InputMaybe<Order_By>;
  actual_vehicle_id?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  expected_to?: InputMaybe<Order_By>;
  foreign_country_id?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  internal_reference_number?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  primary_contact_id?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
  rental_contract_number?: InputMaybe<Order_By>;
  request_note?: InputMaybe<Order_By>;
  requested_vehicle_type_id?: InputMaybe<Order_By>;
  secondary_contact_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rental_contract" */
export type Rental_Contract_Mutation_Response = {
  __typename?: 'rental_contract_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Contract>;
};

/** input type for inserting object relation for remote table "rental_contract" */
export type Rental_Contract_Obj_Rel_Insert_Input = {
  data: Rental_Contract_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_On_Conflict>;
};

/** on_conflict condition type for table "rental_contract" */
export type Rental_Contract_On_Conflict = {
  constraint: Rental_Contract_Constraint;
  update_columns?: Array<Rental_Contract_Update_Column>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_contract". */
export type Rental_Contract_Order_By = {
  accessories_aggregate?: InputMaybe<Rental_Contract_Accessory_Aggregate_Order_By>;
  actual_kilometer?: InputMaybe<Order_By>;
  actual_to?: InputMaybe<Order_By>;
  actual_vehicle_id?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_is_half_day?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deposits_aggregate?: InputMaybe<Rental_Contract_Deposit_Aggregate_Order_By>;
  discount?: InputMaybe<Product_Order_By>;
  discount_id?: InputMaybe<Order_By>;
  drivers_aggregate?: InputMaybe<Rental_Contract_Driver_Aggregate_Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  expected_to?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Rental_Contract_File_Aggregate_Order_By>;
  foreign_country?: InputMaybe<Country_Order_By>;
  foreign_country_id?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  from_booking_portal?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance?: InputMaybe<Product_Order_By>;
  insurance_id?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  internal_reference_number?: InputMaybe<Order_By>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  is_archived?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_paid?: InputMaybe<Order_By>;
  is_ride_to_foreign_country?: InputMaybe<Order_By>;
  is_weekend?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  newsletter?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  payments_aggregate?: InputMaybe<Rental_Contract_Payment_Aggregate_Order_By>;
  primary_contact?: InputMaybe<Contact_Order_By>;
  primary_contact_id?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
  rental_contract_number?: InputMaybe<Order_By>;
  rental_state?: InputMaybe<Order_By>;
  request_note?: InputMaybe<Order_By>;
  requested_vehicle_type?: InputMaybe<Vehicle_Type_Order_By>;
  requested_vehicle_type_id?: InputMaybe<Order_By>;
  secondary_contact?: InputMaybe<Contact_Order_By>;
  secondary_contact_id?: InputMaybe<Order_By>;
  terms_checked?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
};

/** columns and relationships of "rental_contract_payment" */
export type Rental_Contract_Payment = {
  __typename?: 'rental_contract_payment';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  payment_medium: Payment_Medium_Enum;
  /** An object relationship */
  rental_contract: Rental_Contract;
  rental_contract_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rental_contract_payment" */
export type Rental_Contract_Payment_Aggregate = {
  __typename?: 'rental_contract_payment_aggregate';
  aggregate?: Maybe<Rental_Contract_Payment_Aggregate_Fields>;
  nodes: Array<Rental_Contract_Payment>;
};

export type Rental_Contract_Payment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rental_Contract_Payment_Aggregate_Bool_Exp_Count>;
};

export type Rental_Contract_Payment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rental_contract_payment" */
export type Rental_Contract_Payment_Aggregate_Fields = {
  __typename?: 'rental_contract_payment_aggregate_fields';
  avg?: Maybe<Rental_Contract_Payment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_Contract_Payment_Max_Fields>;
  min?: Maybe<Rental_Contract_Payment_Min_Fields>;
  stddev?: Maybe<Rental_Contract_Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Rental_Contract_Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rental_Contract_Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Rental_Contract_Payment_Sum_Fields>;
  var_pop?: Maybe<Rental_Contract_Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Rental_Contract_Payment_Var_Samp_Fields>;
  variance?: Maybe<Rental_Contract_Payment_Variance_Fields>;
};


/** aggregate fields of "rental_contract_payment" */
export type Rental_Contract_Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rental_contract_payment" */
export type Rental_Contract_Payment_Aggregate_Order_By = {
  avg?: InputMaybe<Rental_Contract_Payment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rental_Contract_Payment_Max_Order_By>;
  min?: InputMaybe<Rental_Contract_Payment_Min_Order_By>;
  stddev?: InputMaybe<Rental_Contract_Payment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rental_Contract_Payment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rental_Contract_Payment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rental_Contract_Payment_Sum_Order_By>;
  var_pop?: InputMaybe<Rental_Contract_Payment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rental_Contract_Payment_Var_Samp_Order_By>;
  variance?: InputMaybe<Rental_Contract_Payment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rental_contract_payment" */
export type Rental_Contract_Payment_Arr_Rel_Insert_Input = {
  data: Array<Rental_Contract_Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rental_Contract_Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Rental_Contract_Payment_Avg_Fields = {
  __typename?: 'rental_contract_payment_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rental_contract_payment". All fields are combined with a logical 'AND'. */
export type Rental_Contract_Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_Contract_Payment_Bool_Exp>>;
  _not?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_Contract_Payment_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_medium?: InputMaybe<Payment_Medium_Enum_Comparison_Exp>;
  rental_contract?: InputMaybe<Rental_Contract_Bool_Exp>;
  rental_contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_contract_payment" */
export enum Rental_Contract_Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalContractPaymentPkey = 'rental_contract_payment_pkey'
}

/** input type for incrementing numeric columns in table "rental_contract_payment" */
export type Rental_Contract_Payment_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "rental_contract_payment" */
export type Rental_Contract_Payment_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_medium?: InputMaybe<Payment_Medium_Enum>;
  rental_contract?: InputMaybe<Rental_Contract_Obj_Rel_Insert_Input>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rental_Contract_Payment_Max_Fields = {
  __typename?: 'rental_contract_payment_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rental_Contract_Payment_Min_Fields = {
  __typename?: 'rental_contract_payment_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rental_contract_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rental_contract_payment" */
export type Rental_Contract_Payment_Mutation_Response = {
  __typename?: 'rental_contract_payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Contract_Payment>;
};

/** on_conflict condition type for table "rental_contract_payment" */
export type Rental_Contract_Payment_On_Conflict = {
  constraint: Rental_Contract_Payment_Constraint;
  update_columns?: Array<Rental_Contract_Payment_Update_Column>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_contract_payment". */
export type Rental_Contract_Payment_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_medium?: InputMaybe<Order_By>;
  rental_contract?: InputMaybe<Rental_Contract_Order_By>;
  rental_contract_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_contract_payment */
export type Rental_Contract_Payment_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rental_contract_payment" */
export enum Rental_Contract_Payment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMedium = 'payment_medium',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "rental_contract_payment" */
export type Rental_Contract_Payment_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_medium?: InputMaybe<Payment_Medium_Enum>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rental_Contract_Payment_Stddev_Fields = {
  __typename?: 'rental_contract_payment_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Contract_Payment_Stddev_Pop_Fields = {
  __typename?: 'rental_contract_payment_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Contract_Payment_Stddev_Samp_Fields = {
  __typename?: 'rental_contract_payment_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rental_contract_payment" */
export type Rental_Contract_Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Contract_Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Contract_Payment_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  payment_medium?: InputMaybe<Payment_Medium_Enum>;
  rental_contract_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rental_Contract_Payment_Sum_Fields = {
  __typename?: 'rental_contract_payment_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "rental_contract_payment" */
export enum Rental_Contract_Payment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMedium = 'payment_medium',
  /** column name */
  RentalContractId = 'rental_contract_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Rental_Contract_Payment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rental_Contract_Payment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_Contract_Payment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Contract_Payment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rental_Contract_Payment_Var_Pop_Fields = {
  __typename?: 'rental_contract_payment_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Contract_Payment_Var_Samp_Fields = {
  __typename?: 'rental_contract_payment_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Contract_Payment_Variance_Fields = {
  __typename?: 'rental_contract_payment_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rental_contract_payment" */
export type Rental_Contract_Payment_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_contract */
export type Rental_Contract_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rental_contract" */
export enum Rental_Contract_Select_Column {
  /** column name */
  ActualKilometer = 'actual_kilometer',
  /** column name */
  ActualTo = 'actual_to',
  /** column name */
  ActualVehicleId = 'actual_vehicle_id',
  /** column name */
  CalculationDuration = 'calculation_duration',
  /** column name */
  CalculationInsurance = 'calculation_insurance',
  /** column name */
  CalculationIsHalfDay = 'calculation_is_half_day',
  /** column name */
  CalculationTotalAccessories = 'calculation_total_accessories',
  /** column name */
  CalculationTotalAllRoundPrice = 'calculation_total_all_round_price',
  /** column name */
  CalculationTotalCarNet = 'calculation_total_car_net',
  /** column name */
  CalculationTotalContractFee = 'calculation_total_contract_fee',
  /** column name */
  CalculationTotalDiscount = 'calculation_total_discount',
  /** column name */
  CalculationTotalForeignCountryFee = 'calculation_total_foreign_country_fee',
  /** column name */
  CalculationTotalGross = 'calculation_total_gross',
  /** column name */
  CalculationTotalIncludedKilometer = 'calculation_total_included_kilometer',
  /** column name */
  CalculationTotalInsurance = 'calculation_total_insurance',
  /** column name */
  CalculationTotalNet = 'calculation_total_net',
  /** column name */
  CalculationTotalOverTheLimitKilometerFee = 'calculation_total_over_the_limit_kilometer_fee',
  /** column name */
  CalculationTotalTax = 'calculation_total_tax',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  ExpectedKilometer = 'expected_kilometer',
  /** column name */
  ExpectedTo = 'expected_to',
  /** column name */
  ForeignCountryId = 'foreign_country_id',
  /** column name */
  From = 'from',
  /** column name */
  FromBookingPortal = 'from_booking_portal',
  /** column name */
  FuelEnd = 'fuel_end',
  /** column name */
  FuelStart = 'fuel_start',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceId = 'insurance_id',
  /** column name */
  InsuranceRetention = 'insurance_retention',
  /** column name */
  InternalReferenceNumber = 'internal_reference_number',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsPaid = 'is_paid',
  /** column name */
  IsRideToForeignCountry = 'is_ride_to_foreign_country',
  /** column name */
  IsWeekend = 'is_weekend',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  MileageEnd = 'mileage_end',
  /** column name */
  MileageStart = 'mileage_start',
  /** column name */
  MinimumDeposit = 'minimum_deposit',
  /** column name */
  Newsletter = 'newsletter',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PrimaryContactId = 'primary_contact_id',
  /** column name */
  Refueled = 'refueled',
  /** column name */
  RentalContractNumber = 'rental_contract_number',
  /** column name */
  RentalState = 'rental_state',
  /** column name */
  RequestNote = 'request_note',
  /** column name */
  RequestedVehicleTypeId = 'requested_vehicle_type_id',
  /** column name */
  SecondaryContactId = 'secondary_contact_id',
  /** column name */
  TermsChecked = 'terms_checked',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "rental_contract_aggregate_bool_exp_bool_and_arguments_columns" columns of table "rental_contract" */
export enum Rental_Contract_Select_Column_Rental_Contract_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CalculationIsHalfDay = 'calculation_is_half_day',
  /** column name */
  FromBookingPortal = 'from_booking_portal',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsPaid = 'is_paid',
  /** column name */
  IsRideToForeignCountry = 'is_ride_to_foreign_country',
  /** column name */
  IsWeekend = 'is_weekend',
  /** column name */
  Newsletter = 'newsletter',
  /** column name */
  TermsChecked = 'terms_checked'
}

/** select "rental_contract_aggregate_bool_exp_bool_or_arguments_columns" columns of table "rental_contract" */
export enum Rental_Contract_Select_Column_Rental_Contract_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CalculationIsHalfDay = 'calculation_is_half_day',
  /** column name */
  FromBookingPortal = 'from_booking_portal',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsPaid = 'is_paid',
  /** column name */
  IsRideToForeignCountry = 'is_ride_to_foreign_country',
  /** column name */
  IsWeekend = 'is_weekend',
  /** column name */
  Newsletter = 'newsletter',
  /** column name */
  TermsChecked = 'terms_checked'
}

/** input type for updating data in table "rental_contract" */
export type Rental_Contract_Set_Input = {
  actual_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  actual_to?: InputMaybe<Scalars['timestamptz']['input']>;
  actual_vehicle_id?: InputMaybe<Scalars['uuid']['input']>;
  calculation_duration?: InputMaybe<Scalars['numeric']['input']>;
  calculation_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_is_half_day?: InputMaybe<Scalars['Boolean']['input']>;
  calculation_total_accessories?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_all_round_price?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_car_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_discount?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_foreign_country_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_gross?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_tax?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  expected_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  expected_to?: InputMaybe<Scalars['timestamptz']['input']>;
  foreign_country_id?: InputMaybe<Scalars['uuid']['input']>;
  from?: InputMaybe<Scalars['timestamptz']['input']>;
  from_booking_portal?: InputMaybe<Scalars['Boolean']['input']>;
  fuel_end?: InputMaybe<Scalars['numeric']['input']>;
  fuel_start?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  insurance_id?: InputMaybe<Scalars['uuid']['input']>;
  insurance_retention?: InputMaybe<Scalars['numeric']['input']>;
  internal_reference_number?: InputMaybe<Scalars['String']['input']>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_paid?: InputMaybe<Scalars['Boolean']['input']>;
  is_ride_to_foreign_country?: InputMaybe<Scalars['Boolean']['input']>;
  is_weekend?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  minimum_deposit?: InputMaybe<Scalars['numeric']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  primary_contact_id?: InputMaybe<Scalars['uuid']['input']>;
  refueled?: InputMaybe<Scalars['numeric']['input']>;
  rental_contract_number?: InputMaybe<Scalars['String']['input']>;
  rental_state?: InputMaybe<Rental_State_Enum>;
  request_note?: InputMaybe<Scalars['String']['input']>;
  requested_vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
  secondary_contact_id?: InputMaybe<Scalars['uuid']['input']>;
  terms_checked?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rental_Contract_Stddev_Fields = {
  __typename?: 'rental_contract_stddev_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rental_contract" */
export type Rental_Contract_Stddev_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rental_Contract_Stddev_Pop_Fields = {
  __typename?: 'rental_contract_stddev_pop_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rental_contract" */
export type Rental_Contract_Stddev_Pop_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rental_Contract_Stddev_Samp_Fields = {
  __typename?: 'rental_contract_stddev_samp_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rental_contract" */
export type Rental_Contract_Stddev_Samp_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rental_contract" */
export type Rental_Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_Contract_Stream_Cursor_Value_Input = {
  actual_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  actual_to?: InputMaybe<Scalars['timestamptz']['input']>;
  actual_vehicle_id?: InputMaybe<Scalars['uuid']['input']>;
  calculation_duration?: InputMaybe<Scalars['numeric']['input']>;
  calculation_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_is_half_day?: InputMaybe<Scalars['Boolean']['input']>;
  calculation_total_accessories?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_all_round_price?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_car_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_contract_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_discount?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_foreign_country_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_gross?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_included_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_insurance?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_net?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Scalars['numeric']['input']>;
  calculation_total_tax?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['uuid']['input']>;
  expected_kilometer?: InputMaybe<Scalars['numeric']['input']>;
  expected_to?: InputMaybe<Scalars['timestamptz']['input']>;
  foreign_country_id?: InputMaybe<Scalars['uuid']['input']>;
  from?: InputMaybe<Scalars['timestamptz']['input']>;
  from_booking_portal?: InputMaybe<Scalars['Boolean']['input']>;
  fuel_end?: InputMaybe<Scalars['numeric']['input']>;
  fuel_start?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  insurance_id?: InputMaybe<Scalars['uuid']['input']>;
  insurance_retention?: InputMaybe<Scalars['numeric']['input']>;
  internal_reference_number?: InputMaybe<Scalars['String']['input']>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_paid?: InputMaybe<Scalars['Boolean']['input']>;
  is_ride_to_foreign_country?: InputMaybe<Scalars['Boolean']['input']>;
  is_weekend?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  mileage_end?: InputMaybe<Scalars['numeric']['input']>;
  mileage_start?: InputMaybe<Scalars['numeric']['input']>;
  minimum_deposit?: InputMaybe<Scalars['numeric']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  primary_contact_id?: InputMaybe<Scalars['uuid']['input']>;
  refueled?: InputMaybe<Scalars['numeric']['input']>;
  rental_contract_number?: InputMaybe<Scalars['String']['input']>;
  rental_state?: InputMaybe<Rental_State_Enum>;
  request_note?: InputMaybe<Scalars['String']['input']>;
  requested_vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
  secondary_contact_id?: InputMaybe<Scalars['uuid']['input']>;
  terms_checked?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rental_Contract_Sum_Fields = {
  __typename?: 'rental_contract_sum_fields';
  actual_kilometer?: Maybe<Scalars['numeric']['output']>;
  calculation_duration?: Maybe<Scalars['numeric']['output']>;
  calculation_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_accessories?: Maybe<Scalars['numeric']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['numeric']['output']>;
  calculation_total_car_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_discount?: Maybe<Scalars['numeric']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_gross?: Maybe<Scalars['numeric']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['numeric']['output']>;
  calculation_total_insurance?: Maybe<Scalars['numeric']['output']>;
  calculation_total_net?: Maybe<Scalars['numeric']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['numeric']['output']>;
  calculation_total_tax?: Maybe<Scalars['numeric']['output']>;
  expected_kilometer?: Maybe<Scalars['numeric']['output']>;
  fuel_end?: Maybe<Scalars['numeric']['output']>;
  fuel_start?: Maybe<Scalars['numeric']['output']>;
  insurance_retention?: Maybe<Scalars['numeric']['output']>;
  mileage_end?: Maybe<Scalars['numeric']['output']>;
  mileage_start?: Maybe<Scalars['numeric']['output']>;
  minimum_deposit?: Maybe<Scalars['numeric']['output']>;
  refueled?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "rental_contract" */
export type Rental_Contract_Sum_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** update columns of table "rental_contract" */
export enum Rental_Contract_Update_Column {
  /** column name */
  ActualKilometer = 'actual_kilometer',
  /** column name */
  ActualTo = 'actual_to',
  /** column name */
  ActualVehicleId = 'actual_vehicle_id',
  /** column name */
  CalculationDuration = 'calculation_duration',
  /** column name */
  CalculationInsurance = 'calculation_insurance',
  /** column name */
  CalculationIsHalfDay = 'calculation_is_half_day',
  /** column name */
  CalculationTotalAccessories = 'calculation_total_accessories',
  /** column name */
  CalculationTotalAllRoundPrice = 'calculation_total_all_round_price',
  /** column name */
  CalculationTotalCarNet = 'calculation_total_car_net',
  /** column name */
  CalculationTotalContractFee = 'calculation_total_contract_fee',
  /** column name */
  CalculationTotalDiscount = 'calculation_total_discount',
  /** column name */
  CalculationTotalForeignCountryFee = 'calculation_total_foreign_country_fee',
  /** column name */
  CalculationTotalGross = 'calculation_total_gross',
  /** column name */
  CalculationTotalIncludedKilometer = 'calculation_total_included_kilometer',
  /** column name */
  CalculationTotalInsurance = 'calculation_total_insurance',
  /** column name */
  CalculationTotalNet = 'calculation_total_net',
  /** column name */
  CalculationTotalOverTheLimitKilometerFee = 'calculation_total_over_the_limit_kilometer_fee',
  /** column name */
  CalculationTotalTax = 'calculation_total_tax',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  ExpectedKilometer = 'expected_kilometer',
  /** column name */
  ExpectedTo = 'expected_to',
  /** column name */
  ForeignCountryId = 'foreign_country_id',
  /** column name */
  From = 'from',
  /** column name */
  FromBookingPortal = 'from_booking_portal',
  /** column name */
  FuelEnd = 'fuel_end',
  /** column name */
  FuelStart = 'fuel_start',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceId = 'insurance_id',
  /** column name */
  InsuranceRetention = 'insurance_retention',
  /** column name */
  InternalReferenceNumber = 'internal_reference_number',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsPaid = 'is_paid',
  /** column name */
  IsRideToForeignCountry = 'is_ride_to_foreign_country',
  /** column name */
  IsWeekend = 'is_weekend',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  MileageEnd = 'mileage_end',
  /** column name */
  MileageStart = 'mileage_start',
  /** column name */
  MinimumDeposit = 'minimum_deposit',
  /** column name */
  Newsletter = 'newsletter',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  PrimaryContactId = 'primary_contact_id',
  /** column name */
  Refueled = 'refueled',
  /** column name */
  RentalContractNumber = 'rental_contract_number',
  /** column name */
  RentalState = 'rental_state',
  /** column name */
  RequestNote = 'request_note',
  /** column name */
  RequestedVehicleTypeId = 'requested_vehicle_type_id',
  /** column name */
  SecondaryContactId = 'secondary_contact_id',
  /** column name */
  TermsChecked = 'terms_checked',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Rental_Contract_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rental_Contract_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_Contract_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_Contract_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rental_Contract_Var_Pop_Fields = {
  __typename?: 'rental_contract_var_pop_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rental_contract" */
export type Rental_Contract_Var_Pop_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rental_Contract_Var_Samp_Fields = {
  __typename?: 'rental_contract_var_samp_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rental_contract" */
export type Rental_Contract_Var_Samp_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rental_Contract_Variance_Fields = {
  __typename?: 'rental_contract_variance_fields';
  actual_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_duration?: Maybe<Scalars['Float']['output']>;
  calculation_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_accessories?: Maybe<Scalars['Float']['output']>;
  calculation_total_all_round_price?: Maybe<Scalars['Float']['output']>;
  calculation_total_car_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_contract_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_discount?: Maybe<Scalars['Float']['output']>;
  calculation_total_foreign_country_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_gross?: Maybe<Scalars['Float']['output']>;
  calculation_total_included_kilometer?: Maybe<Scalars['Float']['output']>;
  calculation_total_insurance?: Maybe<Scalars['Float']['output']>;
  calculation_total_net?: Maybe<Scalars['Float']['output']>;
  calculation_total_over_the_limit_kilometer_fee?: Maybe<Scalars['Float']['output']>;
  calculation_total_tax?: Maybe<Scalars['Float']['output']>;
  expected_kilometer?: Maybe<Scalars['Float']['output']>;
  fuel_end?: Maybe<Scalars['Float']['output']>;
  fuel_start?: Maybe<Scalars['Float']['output']>;
  insurance_retention?: Maybe<Scalars['Float']['output']>;
  mileage_end?: Maybe<Scalars['Float']['output']>;
  mileage_start?: Maybe<Scalars['Float']['output']>;
  minimum_deposit?: Maybe<Scalars['Float']['output']>;
  refueled?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rental_contract" */
export type Rental_Contract_Variance_Order_By = {
  actual_kilometer?: InputMaybe<Order_By>;
  calculation_duration?: InputMaybe<Order_By>;
  calculation_insurance?: InputMaybe<Order_By>;
  calculation_total_accessories?: InputMaybe<Order_By>;
  calculation_total_all_round_price?: InputMaybe<Order_By>;
  calculation_total_car_net?: InputMaybe<Order_By>;
  calculation_total_contract_fee?: InputMaybe<Order_By>;
  calculation_total_discount?: InputMaybe<Order_By>;
  calculation_total_foreign_country_fee?: InputMaybe<Order_By>;
  calculation_total_gross?: InputMaybe<Order_By>;
  calculation_total_included_kilometer?: InputMaybe<Order_By>;
  calculation_total_insurance?: InputMaybe<Order_By>;
  calculation_total_net?: InputMaybe<Order_By>;
  calculation_total_over_the_limit_kilometer_fee?: InputMaybe<Order_By>;
  calculation_total_tax?: InputMaybe<Order_By>;
  expected_kilometer?: InputMaybe<Order_By>;
  fuel_end?: InputMaybe<Order_By>;
  fuel_start?: InputMaybe<Order_By>;
  insurance_retention?: InputMaybe<Order_By>;
  mileage_end?: InputMaybe<Order_By>;
  mileage_start?: InputMaybe<Order_By>;
  minimum_deposit?: InputMaybe<Order_By>;
  refueled?: InputMaybe<Order_By>;
};

/** columns and relationships of "rental_state" */
export type Rental_State = {
  __typename?: 'rental_state';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "rental_state" */
export type Rental_State_Aggregate = {
  __typename?: 'rental_state_aggregate';
  aggregate?: Maybe<Rental_State_Aggregate_Fields>;
  nodes: Array<Rental_State>;
};

/** aggregate fields of "rental_state" */
export type Rental_State_Aggregate_Fields = {
  __typename?: 'rental_state_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Rental_State_Max_Fields>;
  min?: Maybe<Rental_State_Min_Fields>;
};


/** aggregate fields of "rental_state" */
export type Rental_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rental_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "rental_state". All fields are combined with a logical 'AND'. */
export type Rental_State_Bool_Exp = {
  _and?: InputMaybe<Array<Rental_State_Bool_Exp>>;
  _not?: InputMaybe<Rental_State_Bool_Exp>;
  _or?: InputMaybe<Array<Rental_State_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rental_state" */
export enum Rental_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  RentalStatePkey = 'rental_state_pkey'
}

export enum Rental_State_Enum {
  Billable = 'billable',
  Booked = 'booked',
  Canceled = 'canceled',
  Cleared = 'cleared',
  Leased = 'leased',
  Legacy = 'legacy',
  Processing = 'processing',
  Rejected = 'rejected',
  Request = 'request',
  Returned = 'returned'
}

/** Boolean expression to compare columns of type "rental_state_enum". All fields are combined with logical 'AND'. */
export type Rental_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Rental_State_Enum>;
  _in?: InputMaybe<Array<Rental_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Rental_State_Enum>;
  _nin?: InputMaybe<Array<Rental_State_Enum>>;
};

/** input type for inserting data into table "rental_state" */
export type Rental_State_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rental_State_Max_Fields = {
  __typename?: 'rental_state_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Rental_State_Min_Fields = {
  __typename?: 'rental_state_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "rental_state" */
export type Rental_State_Mutation_Response = {
  __typename?: 'rental_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rental_State>;
};

/** on_conflict condition type for table "rental_state" */
export type Rental_State_On_Conflict = {
  constraint: Rental_State_Constraint;
  update_columns?: Array<Rental_State_Update_Column>;
  where?: InputMaybe<Rental_State_Bool_Exp>;
};

/** Ordering options when selecting data from "rental_state". */
export type Rental_State_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rental_state */
export type Rental_State_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "rental_state" */
export enum Rental_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rental_state" */
export type Rental_State_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "rental_state" */
export type Rental_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rental_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rental_State_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "rental_state" */
export enum Rental_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Rental_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rental_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rental_State_Bool_Exp;
};

/** columns and relationships of "sequence" */
export type Sequence = {
  __typename?: 'sequence';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  next_seq_number: Scalars['Int']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  sequence_type: Sequence_Type_Enum;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_to: Scalars['date']['output'];
};

/** aggregated selection of "sequence" */
export type Sequence_Aggregate = {
  __typename?: 'sequence_aggregate';
  aggregate?: Maybe<Sequence_Aggregate_Fields>;
  nodes: Array<Sequence>;
};

/** aggregate fields of "sequence" */
export type Sequence_Aggregate_Fields = {
  __typename?: 'sequence_aggregate_fields';
  avg?: Maybe<Sequence_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sequence_Max_Fields>;
  min?: Maybe<Sequence_Min_Fields>;
  stddev?: Maybe<Sequence_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Sum_Fields>;
  var_pop?: Maybe<Sequence_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Variance_Fields>;
};


/** aggregate fields of "sequence" */
export type Sequence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sequence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Sequence_Avg_Fields = {
  __typename?: 'sequence_avg_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sequence". All fields are combined with a logical 'AND'. */
export type Sequence_Bool_Exp = {
  _and?: InputMaybe<Array<Sequence_Bool_Exp>>;
  _not?: InputMaybe<Sequence_Bool_Exp>;
  _or?: InputMaybe<Array<Sequence_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  next_seq_number?: InputMaybe<Int_Comparison_Exp>;
  prefix?: InputMaybe<String_Comparison_Exp>;
  sequence_type?: InputMaybe<Sequence_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  valid_to?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence" */
export enum Sequence_Constraint {
  /** unique or primary key constraint on columns "id" */
  SequencePkey = 'sequence_pkey'
}

/** input type for incrementing numeric columns in table "sequence" */
export type Sequence_Inc_Input = {
  next_seq_number?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sequence" */
export type Sequence_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  next_seq_number?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  sequence_type?: InputMaybe<Sequence_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate max on columns */
export type Sequence_Max_Fields = {
  __typename?: 'sequence_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_seq_number?: Maybe<Scalars['Int']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
};

/** aggregate min on columns */
export type Sequence_Min_Fields = {
  __typename?: 'sequence_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_seq_number?: Maybe<Scalars['Int']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  valid_to?: Maybe<Scalars['date']['output']>;
};

/** response of any mutation on the table "sequence" */
export type Sequence_Mutation_Response = {
  __typename?: 'sequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence>;
};

/** on_conflict condition type for table "sequence" */
export type Sequence_On_Conflict = {
  constraint: Sequence_Constraint;
  update_columns?: Array<Sequence_Update_Column>;
  where?: InputMaybe<Sequence_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence". */
export type Sequence_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_seq_number?: InputMaybe<Order_By>;
  prefix?: InputMaybe<Order_By>;
  sequence_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sequence */
export type Sequence_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "sequence" */
export enum Sequence_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NextSeqNumber = 'next_seq_number',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  SequenceType = 'sequence_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidTo = 'valid_to'
}

/** input type for updating data in table "sequence" */
export type Sequence_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  next_seq_number?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  sequence_type?: InputMaybe<Sequence_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate stddev on columns */
export type Sequence_Stddev_Fields = {
  __typename?: 'sequence_stddev_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Stddev_Pop_Fields = {
  __typename?: 'sequence_stddev_pop_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Stddev_Samp_Fields = {
  __typename?: 'sequence_stddev_samp_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sequence" */
export type Sequence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sequence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sequence_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  next_seq_number?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  sequence_type?: InputMaybe<Sequence_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  valid_to?: InputMaybe<Scalars['date']['input']>;
};

/** aggregate sum on columns */
export type Sequence_Sum_Fields = {
  __typename?: 'sequence_sum_fields';
  next_seq_number?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "sequence_type" */
export type Sequence_Type = {
  __typename?: 'sequence_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "sequence_type" */
export type Sequence_Type_Aggregate = {
  __typename?: 'sequence_type_aggregate';
  aggregate?: Maybe<Sequence_Type_Aggregate_Fields>;
  nodes: Array<Sequence_Type>;
};

/** aggregate fields of "sequence_type" */
export type Sequence_Type_Aggregate_Fields = {
  __typename?: 'sequence_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Sequence_Type_Max_Fields>;
  min?: Maybe<Sequence_Type_Min_Fields>;
};


/** aggregate fields of "sequence_type" */
export type Sequence_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sequence_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "sequence_type". All fields are combined with a logical 'AND'. */
export type Sequence_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Sequence_Type_Bool_Exp>>;
  _not?: InputMaybe<Sequence_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Sequence_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_type" */
export enum Sequence_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SequenceTypePkey = 'sequence_type_pkey'
}

export enum Sequence_Type_Enum {
  Contact = 'contact',
  Invoice = 'invoice',
  Lease = 'lease'
}

/** Boolean expression to compare columns of type "sequence_type_enum". All fields are combined with logical 'AND'. */
export type Sequence_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Sequence_Type_Enum>;
  _in?: InputMaybe<Array<Sequence_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Sequence_Type_Enum>;
  _nin?: InputMaybe<Array<Sequence_Type_Enum>>;
};

/** input type for inserting data into table "sequence_type" */
export type Sequence_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Sequence_Type_Max_Fields = {
  __typename?: 'sequence_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Sequence_Type_Min_Fields = {
  __typename?: 'sequence_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "sequence_type" */
export type Sequence_Type_Mutation_Response = {
  __typename?: 'sequence_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Type>;
};

/** on_conflict condition type for table "sequence_type" */
export type Sequence_Type_On_Conflict = {
  constraint: Sequence_Type_Constraint;
  update_columns?: Array<Sequence_Type_Update_Column>;
  where?: InputMaybe<Sequence_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_type". */
export type Sequence_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sequence_type */
export type Sequence_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "sequence_type" */
export enum Sequence_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sequence_type" */
export type Sequence_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "sequence_type" */
export type Sequence_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sequence_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sequence_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "sequence_type" */
export enum Sequence_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Sequence_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sequence_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sequence_Type_Bool_Exp;
};

/** update columns of table "sequence" */
export enum Sequence_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NextSeqNumber = 'next_seq_number',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  SequenceType = 'sequence_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  ValidTo = 'valid_to'
}

export type Sequence_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sequence_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sequence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sequence_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sequence_Var_Pop_Fields = {
  __typename?: 'sequence_var_pop_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Sequence_Var_Samp_Fields = {
  __typename?: 'sequence_var_samp_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Sequence_Variance_Fields = {
  __typename?: 'sequence_variance_fields';
  next_seq_number?: Maybe<Scalars['Float']['output']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequests_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.provider_requests" */
  authProviderRequests_stream: Array<AuthProviderRequests>;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProviders_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.providers" */
  authProviders_stream: Array<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypes_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
  authRefreshTokenTypes_stream: Array<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokens_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.roles" */
  authRoles_stream: Array<AuthRoles>;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProviders_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_providers" */
  authUserProviders_stream: Array<AuthUserProviders>;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_roles" */
  authUserRoles_stream: Array<AuthUserRoles>;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeys_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_security_keys" */
  authUserSecurityKeys_stream: Array<AuthUserSecurityKeys>;
  /** fetch data from the table: "base_category" */
  base_category: Array<Base_Category>;
  /** fetch aggregated fields from the table: "base_category" */
  base_category_aggregate: Base_Category_Aggregate;
  /** fetch data from the table: "base_category" using primary key columns */
  base_category_by_pk?: Maybe<Base_Category>;
  /** fetch data from the table in a streaming manner: "base_category" */
  base_category_stream: Array<Base_Category>;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table in a streaming manner: "storage.buckets" */
  buckets_stream: Array<Buckets>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<Contact>;
  /** fetch data from the table: "contact_type" */
  contact_type: Array<Contact_Type>;
  /** fetch aggregated fields from the table: "contact_type" */
  contact_type_aggregate: Contact_Type_Aggregate;
  /** fetch data from the table: "contact_type" using primary key columns */
  contact_type_by_pk?: Maybe<Contact_Type>;
  /** fetch data from the table in a streaming manner: "contact_type" */
  contact_type_stream: Array<Contact_Type>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "entity_type" */
  entity_type: Array<Entity_Type>;
  /** fetch aggregated fields from the table: "entity_type" */
  entity_type_aggregate: Entity_Type_Aggregate;
  /** fetch data from the table: "entity_type" using primary key columns */
  entity_type_by_pk?: Maybe<Entity_Type>;
  /** fetch data from the table in a streaming manner: "entity_type" */
  entity_type_stream: Array<Entity_Type>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table in a streaming manner: "storage.files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "fuel" */
  fuel: Array<Fuel>;
  /** fetch aggregated fields from the table: "fuel" */
  fuel_aggregate: Fuel_Aggregate;
  /** fetch data from the table: "fuel" using primary key columns */
  fuel_by_pk?: Maybe<Fuel>;
  /** fetch data from the table in a streaming manner: "fuel" */
  fuel_stream: Array<Fuel>;
  /** fetch data from the table: "gearshift" */
  gearshift: Array<Gearshift>;
  /** fetch aggregated fields from the table: "gearshift" */
  gearshift_aggregate: Gearshift_Aggregate;
  /** fetch data from the table: "gearshift" using primary key columns */
  gearshift_by_pk?: Maybe<Gearshift>;
  /** fetch data from the table in a streaming manner: "gearshift" */
  gearshift_stream: Array<Gearshift>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_item" */
  invoice_item: Array<Invoice_Item>;
  /** fetch aggregated fields from the table: "invoice_item" */
  invoice_item_aggregate: Invoice_Item_Aggregate;
  /** fetch data from the table: "invoice_item" using primary key columns */
  invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** fetch data from the table in a streaming manner: "invoice_item" */
  invoice_item_stream: Array<Invoice_Item>;
  /** fetch data from the table in a streaming manner: "invoice" */
  invoice_stream: Array<Invoice>;
  /** fetch data from the table: "kilometer_fee" */
  kilometer_fee: Array<Kilometer_Fee>;
  /** fetch aggregated fields from the table: "kilometer_fee" */
  kilometer_fee_aggregate: Kilometer_Fee_Aggregate;
  /** fetch data from the table: "kilometer_fee" using primary key columns */
  kilometer_fee_by_pk?: Maybe<Kilometer_Fee>;
  /** fetch data from the table: "kilometer_fee_entry" */
  kilometer_fee_entry: Array<Kilometer_Fee_Entry>;
  /** fetch aggregated fields from the table: "kilometer_fee_entry" */
  kilometer_fee_entry_aggregate: Kilometer_Fee_Entry_Aggregate;
  /** fetch data from the table: "kilometer_fee_entry" using primary key columns */
  kilometer_fee_entry_by_pk?: Maybe<Kilometer_Fee_Entry>;
  /** fetch data from the table in a streaming manner: "kilometer_fee_entry" */
  kilometer_fee_entry_stream: Array<Kilometer_Fee_Entry>;
  /** fetch data from the table in a streaming manner: "kilometer_fee" */
  kilometer_fee_stream: Array<Kilometer_Fee>;
  /** fetch data from the table: "kilometer_fee_vehicle_type" */
  kilometer_fee_vehicle_type: Array<Kilometer_Fee_Vehicle_Type>;
  /** fetch aggregated fields from the table: "kilometer_fee_vehicle_type" */
  kilometer_fee_vehicle_type_aggregate: Kilometer_Fee_Vehicle_Type_Aggregate;
  /** fetch data from the table: "kilometer_fee_vehicle_type" using primary key columns */
  kilometer_fee_vehicle_type_by_pk?: Maybe<Kilometer_Fee_Vehicle_Type>;
  /** fetch data from the table in a streaming manner: "kilometer_fee_vehicle_type" */
  kilometer_fee_vehicle_type_stream: Array<Kilometer_Fee_Vehicle_Type>;
  /** fetch data from the table: "log_book" */
  log_book: Array<Log_Book>;
  /** fetch aggregated fields from the table: "log_book" */
  log_book_aggregate: Log_Book_Aggregate;
  /** fetch data from the table: "log_book" using primary key columns */
  log_book_by_pk?: Maybe<Log_Book>;
  /** fetch data from the table in a streaming manner: "log_book" */
  log_book_stream: Array<Log_Book>;
  /** fetch data from the table: "note" */
  note: Array<Note>;
  /** fetch aggregated fields from the table: "note" */
  note_aggregate: Note_Aggregate;
  /** fetch data from the table: "note" using primary key columns */
  note_by_pk?: Maybe<Note>;
  /** fetch data from the table in a streaming manner: "note" */
  note_stream: Array<Note>;
  /** fetch data from the table: "payment_medium" */
  payment_medium: Array<Payment_Medium>;
  /** fetch aggregated fields from the table: "payment_medium" */
  payment_medium_aggregate: Payment_Medium_Aggregate;
  /** fetch data from the table: "payment_medium" using primary key columns */
  payment_medium_by_pk?: Maybe<Payment_Medium>;
  /** fetch data from the table in a streaming manner: "payment_medium" */
  payment_medium_stream: Array<Payment_Medium>;
  /** fetch data from the table: "price_definition" */
  price_definition: Array<Price_Definition>;
  /** fetch aggregated fields from the table: "price_definition" */
  price_definition_aggregate: Price_Definition_Aggregate;
  /** fetch data from the table: "price_definition" using primary key columns */
  price_definition_by_pk?: Maybe<Price_Definition>;
  /** fetch data from the table: "price_definition_entry" */
  price_definition_entry: Array<Price_Definition_Entry>;
  /** fetch aggregated fields from the table: "price_definition_entry" */
  price_definition_entry_aggregate: Price_Definition_Entry_Aggregate;
  /** fetch data from the table: "price_definition_entry" using primary key columns */
  price_definition_entry_by_pk?: Maybe<Price_Definition_Entry>;
  /** fetch data from the table in a streaming manner: "price_definition_entry" */
  price_definition_entry_stream: Array<Price_Definition_Entry>;
  /** fetch data from the table in a streaming manner: "price_definition" */
  price_definition_stream: Array<Price_Definition>;
  /** fetch data from the table: "price_definition_vehicle_type" */
  price_definition_vehicle_type: Array<Price_Definition_Vehicle_Type>;
  /** fetch aggregated fields from the table: "price_definition_vehicle_type" */
  price_definition_vehicle_type_aggregate: Price_Definition_Vehicle_Type_Aggregate;
  /** fetch data from the table: "price_definition_vehicle_type" using primary key columns */
  price_definition_vehicle_type_by_pk?: Maybe<Price_Definition_Vehicle_Type>;
  /** fetch data from the table in a streaming manner: "price_definition_vehicle_type" */
  price_definition_vehicle_type_stream: Array<Price_Definition_Vehicle_Type>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_eligible_for" */
  product_eligible_for: Array<Product_Eligible_For>;
  /** fetch aggregated fields from the table: "product_eligible_for" */
  product_eligible_for_aggregate: Product_Eligible_For_Aggregate;
  /** fetch data from the table: "product_eligible_for" using primary key columns */
  product_eligible_for_by_pk?: Maybe<Product_Eligible_For>;
  /** fetch data from the table in a streaming manner: "product_eligible_for" */
  product_eligible_for_stream: Array<Product_Eligible_For>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "product_type" */
  product_type: Array<Product_Type>;
  /** fetch aggregated fields from the table: "product_type" */
  product_type_aggregate: Product_Type_Aggregate;
  /** fetch data from the table: "product_type" using primary key columns */
  product_type_by_pk?: Maybe<Product_Type>;
  /** fetch data from the table in a streaming manner: "product_type" */
  product_type_stream: Array<Product_Type>;
  /** fetch data from the table: "rental_contract" */
  rental_contract: Array<Rental_Contract>;
  /** fetch data from the table: "rental_contract_accessory" */
  rental_contract_accessory: Array<Rental_Contract_Accessory>;
  /** fetch aggregated fields from the table: "rental_contract_accessory" */
  rental_contract_accessory_aggregate: Rental_Contract_Accessory_Aggregate;
  /** fetch data from the table: "rental_contract_accessory" using primary key columns */
  rental_contract_accessory_by_pk?: Maybe<Rental_Contract_Accessory>;
  /** fetch data from the table in a streaming manner: "rental_contract_accessory" */
  rental_contract_accessory_stream: Array<Rental_Contract_Accessory>;
  /** fetch aggregated fields from the table: "rental_contract" */
  rental_contract_aggregate: Rental_Contract_Aggregate;
  /** fetch data from the table: "rental_contract" using primary key columns */
  rental_contract_by_pk?: Maybe<Rental_Contract>;
  /** fetch data from the table: "rental_contract_deposit" */
  rental_contract_deposit: Array<Rental_Contract_Deposit>;
  /** fetch aggregated fields from the table: "rental_contract_deposit" */
  rental_contract_deposit_aggregate: Rental_Contract_Deposit_Aggregate;
  /** fetch data from the table: "rental_contract_deposit" using primary key columns */
  rental_contract_deposit_by_pk?: Maybe<Rental_Contract_Deposit>;
  /** fetch data from the table in a streaming manner: "rental_contract_deposit" */
  rental_contract_deposit_stream: Array<Rental_Contract_Deposit>;
  /** fetch data from the table: "rental_contract_driver" */
  rental_contract_driver: Array<Rental_Contract_Driver>;
  /** fetch aggregated fields from the table: "rental_contract_driver" */
  rental_contract_driver_aggregate: Rental_Contract_Driver_Aggregate;
  /** fetch data from the table: "rental_contract_driver" using primary key columns */
  rental_contract_driver_by_pk?: Maybe<Rental_Contract_Driver>;
  /** fetch data from the table in a streaming manner: "rental_contract_driver" */
  rental_contract_driver_stream: Array<Rental_Contract_Driver>;
  /** fetch data from the table: "rental_contract_file" */
  rental_contract_file: Array<Rental_Contract_File>;
  /** fetch aggregated fields from the table: "rental_contract_file" */
  rental_contract_file_aggregate: Rental_Contract_File_Aggregate;
  /** fetch data from the table: "rental_contract_file" using primary key columns */
  rental_contract_file_by_pk?: Maybe<Rental_Contract_File>;
  /** fetch data from the table in a streaming manner: "rental_contract_file" */
  rental_contract_file_stream: Array<Rental_Contract_File>;
  /** fetch data from the table: "rental_contract_payment" */
  rental_contract_payment: Array<Rental_Contract_Payment>;
  /** fetch aggregated fields from the table: "rental_contract_payment" */
  rental_contract_payment_aggregate: Rental_Contract_Payment_Aggregate;
  /** fetch data from the table: "rental_contract_payment" using primary key columns */
  rental_contract_payment_by_pk?: Maybe<Rental_Contract_Payment>;
  /** fetch data from the table in a streaming manner: "rental_contract_payment" */
  rental_contract_payment_stream: Array<Rental_Contract_Payment>;
  /** fetch data from the table in a streaming manner: "rental_contract" */
  rental_contract_stream: Array<Rental_Contract>;
  /** fetch data from the table: "rental_state" */
  rental_state: Array<Rental_State>;
  /** fetch aggregated fields from the table: "rental_state" */
  rental_state_aggregate: Rental_State_Aggregate;
  /** fetch data from the table: "rental_state" using primary key columns */
  rental_state_by_pk?: Maybe<Rental_State>;
  /** fetch data from the table in a streaming manner: "rental_state" */
  rental_state_stream: Array<Rental_State>;
  /** fetch data from the table: "sequence" */
  sequence: Array<Sequence>;
  /** fetch aggregated fields from the table: "sequence" */
  sequence_aggregate: Sequence_Aggregate;
  /** fetch data from the table: "sequence" using primary key columns */
  sequence_by_pk?: Maybe<Sequence>;
  /** fetch data from the table in a streaming manner: "sequence" */
  sequence_stream: Array<Sequence>;
  /** fetch data from the table: "sequence_type" */
  sequence_type: Array<Sequence_Type>;
  /** fetch aggregated fields from the table: "sequence_type" */
  sequence_type_aggregate: Sequence_Type_Aggregate;
  /** fetch data from the table: "sequence_type" using primary key columns */
  sequence_type_by_pk?: Maybe<Sequence_Type>;
  /** fetch data from the table in a streaming manner: "sequence_type" */
  sequence_type_stream: Array<Sequence_Type>;
  /** fetch data from the table: "template_document" */
  template_document: Array<Template_Document>;
  /** fetch aggregated fields from the table: "template_document" */
  template_document_aggregate: Template_Document_Aggregate;
  /** fetch data from the table: "template_document" using primary key columns */
  template_document_by_pk?: Maybe<Template_Document>;
  /** fetch data from the table in a streaming manner: "template_document" */
  template_document_stream: Array<Template_Document>;
  /** fetch data from the table: "template_email" */
  template_email: Array<Template_Email>;
  /** fetch aggregated fields from the table: "template_email" */
  template_email_aggregate: Template_Email_Aggregate;
  /** fetch data from the table: "template_email" using primary key columns */
  template_email_by_pk?: Maybe<Template_Email>;
  /** fetch data from the table in a streaming manner: "template_email" */
  template_email_stream: Array<Template_Email>;
  /** fetch data from the table: "template_group" */
  template_group: Array<Template_Group>;
  /** fetch aggregated fields from the table: "template_group" */
  template_group_aggregate: Template_Group_Aggregate;
  /** fetch data from the table: "template_group" using primary key columns */
  template_group_by_pk?: Maybe<Template_Group>;
  /** fetch data from the table in a streaming manner: "template_group" */
  template_group_stream: Array<Template_Group>;
  /** fetch data from the table: "unit" */
  unit: Array<Unit>;
  /** fetch aggregated fields from the table: "unit" */
  unit_aggregate: Unit_Aggregate;
  /** fetch data from the table: "unit" using primary key columns */
  unit_by_pk?: Maybe<Unit>;
  /** fetch data from the table in a streaming manner: "unit" */
  unit_stream: Array<Unit>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "value_type" */
  value_type: Array<Value_Type>;
  /** fetch aggregated fields from the table: "value_type" */
  value_type_aggregate: Value_Type_Aggregate;
  /** fetch data from the table: "value_type" using primary key columns */
  value_type_by_pk?: Maybe<Value_Type>;
  /** fetch data from the table in a streaming manner: "value_type" */
  value_type_stream: Array<Value_Type>;
  /** fetch data from the table: "vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicle" */
  vehicle_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "vehicle" using primary key columns */
  vehicle_by_pk?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_category" */
  vehicle_category: Array<Vehicle_Category>;
  /** fetch aggregated fields from the table: "vehicle_category" */
  vehicle_category_aggregate: Vehicle_Category_Aggregate;
  /** fetch data from the table: "vehicle_category" using primary key columns */
  vehicle_category_by_pk?: Maybe<Vehicle_Category>;
  /** fetch data from the table in a streaming manner: "vehicle_category" */
  vehicle_category_stream: Array<Vehicle_Category>;
  /** fetch data from the table in a streaming manner: "vehicle" */
  vehicle_stream: Array<Vehicle>;
  /** fetch data from the table: "vehicle_type" */
  vehicle_type: Array<Vehicle_Type>;
  /** fetch aggregated fields from the table: "vehicle_type" */
  vehicle_type_aggregate: Vehicle_Type_Aggregate;
  /** fetch data from the table: "vehicle_type" using primary key columns */
  vehicle_type_by_pk?: Maybe<Vehicle_Type>;
  /** An array relationship */
  vehicle_type_categories: Array<Vehicle_Type_Categories>;
  /** An aggregate relationship */
  vehicle_type_categories_aggregate: Vehicle_Type_Categories_Aggregate;
  /** fetch data from the table: "vehicle_type_categories" using primary key columns */
  vehicle_type_categories_by_pk?: Maybe<Vehicle_Type_Categories>;
  /** fetch data from the table in a streaming manner: "vehicle_type_categories" */
  vehicle_type_categories_stream: Array<Vehicle_Type_Categories>;
  /** fetch data from the table in a streaming manner: "vehicle_type" */
  vehicle_type_stream: Array<Vehicle_Type>;
};


export type Subscription_RootAuthProviderArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Subscription_RootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Subscription_RootAuthProviderRequests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviderRequests_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};


export type Subscription_RootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Subscription_RootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Subscription_RootAuthProviders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviders_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokenTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokenTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Subscription_RootAuthRefreshTokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokens_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


export type Subscription_RootAuthRoleArgs = {
  role: Scalars['String']['input'];
};


export type Subscription_RootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Subscription_RootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Subscription_RootAuthRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Subscription_RootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Subscription_RootAuthUserProviders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserProviders_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


export type Subscription_RootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


export type Subscription_RootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Subscription_RootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Subscription_RootAuthUserSecurityKeys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserSecurityKeys_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


export type Subscription_RootBase_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Base_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Category_Order_By>>;
  where?: InputMaybe<Base_Category_Bool_Exp>;
};


export type Subscription_RootBase_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Base_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Base_Category_Order_By>>;
  where?: InputMaybe<Base_Category_Bool_Exp>;
};


export type Subscription_RootBase_Category_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootBase_Category_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Base_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Base_Category_Bool_Exp>;
};


export type Subscription_RootBucketArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootBuckets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Buckets_Stream_Cursor_Input>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootContactArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootContact_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};


export type Subscription_RootContact_TypeArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Subscription_RootContact_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Subscription_RootContact_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootContact_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};


export type Subscription_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootEntity_TypeArgs = {
  distinct_on?: InputMaybe<Array<Entity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Type_Order_By>>;
  where?: InputMaybe<Entity_Type_Bool_Exp>;
};


export type Subscription_RootEntity_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Type_Order_By>>;
  where?: InputMaybe<Entity_Type_Bool_Exp>;
};


export type Subscription_RootEntity_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootEntity_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entity_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_Type_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFuelArgs = {
  distinct_on?: InputMaybe<Array<Fuel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fuel_Order_By>>;
  where?: InputMaybe<Fuel_Bool_Exp>;
};


export type Subscription_RootFuel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fuel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fuel_Order_By>>;
  where?: InputMaybe<Fuel_Bool_Exp>;
};


export type Subscription_RootFuel_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootFuel_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Fuel_Stream_Cursor_Input>>;
  where?: InputMaybe<Fuel_Bool_Exp>;
};


export type Subscription_RootGearshiftArgs = {
  distinct_on?: InputMaybe<Array<Gearshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gearshift_Order_By>>;
  where?: InputMaybe<Gearshift_Bool_Exp>;
};


export type Subscription_RootGearshift_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gearshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Gearshift_Order_By>>;
  where?: InputMaybe<Gearshift_Bool_Exp>;
};


export type Subscription_RootGearshift_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootGearshift_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Gearshift_Stream_Cursor_Input>>;
  where?: InputMaybe<Gearshift_Bool_Exp>;
};


export type Subscription_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootInvoice_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootInvoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};


export type Subscription_RootInvoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};


export type Subscription_RootInvoice_Item_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootInvoice_Item_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Invoice_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};


export type Subscription_RootInvoice_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};


export type Subscription_RootKilometer_FeeArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootKilometer_Fee_EntryArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Entry_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_Entry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Entry_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_Entry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootKilometer_Fee_Entry_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Kilometer_Fee_Entry_Stream_Cursor_Input>>;
  where?: InputMaybe<Kilometer_Fee_Entry_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Kilometer_Fee_Stream_Cursor_Input>>;
  where?: InputMaybe<Kilometer_Fee_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_Vehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_Vehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootKilometer_Fee_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootKilometer_Fee_Vehicle_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Kilometer_Fee_Vehicle_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootLog_BookArgs = {
  distinct_on?: InputMaybe<Array<Log_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Log_Book_Order_By>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};


export type Subscription_RootLog_Book_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Log_Book_Order_By>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};


export type Subscription_RootLog_Book_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLog_Book_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Log_Book_Stream_Cursor_Input>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};


export type Subscription_RootNoteArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Note_Order_By>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootNote_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNote_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Note_Stream_Cursor_Input>>;
  where?: InputMaybe<Note_Bool_Exp>;
};


export type Subscription_RootPayment_MediumArgs = {
  distinct_on?: InputMaybe<Array<Payment_Medium_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Medium_Order_By>>;
  where?: InputMaybe<Payment_Medium_Bool_Exp>;
};


export type Subscription_RootPayment_Medium_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Medium_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Payment_Medium_Order_By>>;
  where?: InputMaybe<Payment_Medium_Bool_Exp>;
};


export type Subscription_RootPayment_Medium_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootPayment_Medium_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Payment_Medium_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Medium_Bool_Exp>;
};


export type Subscription_RootPrice_DefinitionArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Order_By>>;
  where?: InputMaybe<Price_Definition_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Order_By>>;
  where?: InputMaybe<Price_Definition_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPrice_Definition_EntryArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Entry_Order_By>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_Entry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Entry_Order_By>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_Entry_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPrice_Definition_Entry_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Price_Definition_Entry_Stream_Cursor_Input>>;
  where?: InputMaybe<Price_Definition_Entry_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Price_Definition_Stream_Cursor_Input>>;
  where?: InputMaybe<Price_Definition_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_Vehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_Vehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootPrice_Definition_Vehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPrice_Definition_Vehicle_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Price_Definition_Vehicle_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProduct_Eligible_ForArgs = {
  distinct_on?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Eligible_For_Order_By>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};


export type Subscription_RootProduct_Eligible_For_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Eligible_For_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Eligible_For_Order_By>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};


export type Subscription_RootProduct_Eligible_For_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProduct_Eligible_For_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Eligible_For_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Eligible_For_Bool_Exp>;
};


export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_TypeArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type Subscription_RootProduct_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type Subscription_RootProduct_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootProduct_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Product_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type Subscription_RootRental_ContractArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


export type Subscription_RootRental_Contract_AccessoryArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Accessory_Order_By>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Accessory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Accessory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Accessory_Order_By>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Accessory_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRental_Contract_Accessory_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_Contract_Accessory_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_Contract_Accessory_Bool_Exp>;
};


export type Subscription_RootRental_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Order_By>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


export type Subscription_RootRental_Contract_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRental_Contract_DepositArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Deposit_Order_By>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Deposit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Deposit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Deposit_Order_By>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Deposit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRental_Contract_Deposit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_Contract_Deposit_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_Contract_Deposit_Bool_Exp>;
};


export type Subscription_RootRental_Contract_DriverArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Driver_Order_By>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Driver_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Driver_Order_By>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Driver_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRental_Contract_Driver_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_Contract_Driver_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_Contract_Driver_Bool_Exp>;
};


export type Subscription_RootRental_Contract_FileArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_File_Order_By>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


export type Subscription_RootRental_Contract_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_File_Order_By>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


export type Subscription_RootRental_Contract_File_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRental_Contract_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_Contract_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_Contract_File_Bool_Exp>;
};


export type Subscription_RootRental_Contract_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Payment_Order_By>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_Contract_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_Contract_Payment_Order_By>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};


export type Subscription_RootRental_Contract_Payment_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRental_Contract_Payment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_Contract_Payment_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_Contract_Payment_Bool_Exp>;
};


export type Subscription_RootRental_Contract_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_Contract_Bool_Exp>;
};


export type Subscription_RootRental_StateArgs = {
  distinct_on?: InputMaybe<Array<Rental_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_State_Order_By>>;
  where?: InputMaybe<Rental_State_Bool_Exp>;
};


export type Subscription_RootRental_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rental_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rental_State_Order_By>>;
  where?: InputMaybe<Rental_State_Bool_Exp>;
};


export type Subscription_RootRental_State_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootRental_State_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rental_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Rental_State_Bool_Exp>;
};


export type Subscription_RootSequenceArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Order_By>>;
  where?: InputMaybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Order_By>>;
  where?: InputMaybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSequence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sequence_Stream_Cursor_Input>>;
  where?: InputMaybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_TypeArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Type_Order_By>>;
  where?: InputMaybe<Sequence_Type_Bool_Exp>;
};


export type Subscription_RootSequence_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sequence_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sequence_Type_Order_By>>;
  where?: InputMaybe<Sequence_Type_Bool_Exp>;
};


export type Subscription_RootSequence_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootSequence_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sequence_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Sequence_Type_Bool_Exp>;
};


export type Subscription_RootTemplate_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Template_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Document_Order_By>>;
  where?: InputMaybe<Template_Document_Bool_Exp>;
};


export type Subscription_RootTemplate_Document_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Document_Order_By>>;
  where?: InputMaybe<Template_Document_Bool_Exp>;
};


export type Subscription_RootTemplate_Document_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTemplate_Document_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Document_Bool_Exp>;
};


export type Subscription_RootTemplate_EmailArgs = {
  distinct_on?: InputMaybe<Array<Template_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Email_Order_By>>;
  where?: InputMaybe<Template_Email_Bool_Exp>;
};


export type Subscription_RootTemplate_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Email_Order_By>>;
  where?: InputMaybe<Template_Email_Bool_Exp>;
};


export type Subscription_RootTemplate_Email_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTemplate_Email_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Email_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Email_Bool_Exp>;
};


export type Subscription_RootTemplate_GroupArgs = {
  distinct_on?: InputMaybe<Array<Template_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Group_Order_By>>;
  where?: InputMaybe<Template_Group_Bool_Exp>;
};


export type Subscription_RootTemplate_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Group_Order_By>>;
  where?: InputMaybe<Template_Group_Bool_Exp>;
};


export type Subscription_RootTemplate_Group_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTemplate_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Group_Bool_Exp>;
};


export type Subscription_RootUnitArgs = {
  distinct_on?: InputMaybe<Array<Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unit_Order_By>>;
  where?: InputMaybe<Unit_Bool_Exp>;
};


export type Subscription_RootUnit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unit_Order_By>>;
  where?: InputMaybe<Unit_Bool_Exp>;
};


export type Subscription_RootUnit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUnit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Unit_Stream_Cursor_Input>>;
  where?: InputMaybe<Unit_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootValue_TypeArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootValue_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootValue_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootValue_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Value_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootVehicleArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};


export type Subscription_RootVehicle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};


export type Subscription_RootVehicle_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVehicle_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Category_Order_By>>;
  where?: InputMaybe<Vehicle_Category_Bool_Exp>;
};


export type Subscription_RootVehicle_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Category_Order_By>>;
  where?: InputMaybe<Vehicle_Category_Bool_Exp>;
};


export type Subscription_RootVehicle_Category_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVehicle_Category_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vehicle_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Vehicle_Category_Bool_Exp>;
};


export type Subscription_RootVehicle_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vehicle_Stream_Cursor_Input>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};


export type Subscription_RootVehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootVehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};


export type Subscription_RootVehicle_Type_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVehicle_Type_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


export type Subscription_RootVehicle_Type_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


export type Subscription_RootVehicle_Type_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVehicle_Type_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vehicle_Type_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


export type Subscription_RootVehicle_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vehicle_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};

/** Doc Templates */
export type Template_Document = {
  __typename?: 'template_document';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  entity_type: Entity_Type_Enum;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  group?: Maybe<Template_Group>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  template_group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "template_document" */
export type Template_Document_Aggregate = {
  __typename?: 'template_document_aggregate';
  aggregate?: Maybe<Template_Document_Aggregate_Fields>;
  nodes: Array<Template_Document>;
};

/** aggregate fields of "template_document" */
export type Template_Document_Aggregate_Fields = {
  __typename?: 'template_document_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Document_Max_Fields>;
  min?: Maybe<Template_Document_Min_Fields>;
};


/** aggregate fields of "template_document" */
export type Template_Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "template_document". All fields are combined with a logical 'AND'. */
export type Template_Document_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Document_Bool_Exp>>;
  _not?: InputMaybe<Template_Document_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Document_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  entity_type?: InputMaybe<Entity_Type_Enum_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Template_Group_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  template_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "template_document" */
export enum Template_Document_Constraint {
  /** unique or primary key constraint on columns "name" */
  TemplaceDocumentNameKey = 'templace_document_name_key',
  /** unique or primary key constraint on columns "id" */
  TemplaceDocumentPkey = 'templace_document_pkey'
}

/** input type for inserting data into table "template_document" */
export type Template_Document_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  group?: InputMaybe<Template_Group_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  template_group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Template_Document_Max_Fields = {
  __typename?: 'template_document_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  template_group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Template_Document_Min_Fields = {
  __typename?: 'template_document_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  template_group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "template_document" */
export type Template_Document_Mutation_Response = {
  __typename?: 'template_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Document>;
};

/** on_conflict condition type for table "template_document" */
export type Template_Document_On_Conflict = {
  constraint: Template_Document_Constraint;
  update_columns?: Array<Template_Document_Update_Column>;
  where?: InputMaybe<Template_Document_Bool_Exp>;
};

/** Ordering options when selecting data from "template_document". */
export type Template_Document_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Template_Group_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  template_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: template_document */
export type Template_Document_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "template_document" */
export enum Template_Document_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TemplateGroupId = 'template_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "template_document" */
export type Template_Document_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  template_group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "template_document" */
export type Template_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Document_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  template_group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "template_document" */
export enum Template_Document_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TemplateGroupId = 'template_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Template_Document_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Document_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Document_Bool_Exp;
};

/** References to postmark templates */
export type Template_Email = {
  __typename?: 'template_email';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  entity_type: Entity_Type_Enum;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  postmark_template_alias: Scalars['String']['output'];
  /** An object relationship */
  template_group?: Maybe<Template_Group>;
  template_group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "template_email" */
export type Template_Email_Aggregate = {
  __typename?: 'template_email_aggregate';
  aggregate?: Maybe<Template_Email_Aggregate_Fields>;
  nodes: Array<Template_Email>;
};

/** aggregate fields of "template_email" */
export type Template_Email_Aggregate_Fields = {
  __typename?: 'template_email_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Email_Max_Fields>;
  min?: Maybe<Template_Email_Min_Fields>;
};


/** aggregate fields of "template_email" */
export type Template_Email_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Email_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "template_email". All fields are combined with a logical 'AND'. */
export type Template_Email_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Email_Bool_Exp>>;
  _not?: InputMaybe<Template_Email_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Email_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  entity_type?: InputMaybe<Entity_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  postmark_template_alias?: InputMaybe<String_Comparison_Exp>;
  template_group?: InputMaybe<Template_Group_Bool_Exp>;
  template_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "template_email" */
export enum Template_Email_Constraint {
  /** unique or primary key constraint on columns "name" */
  EmailTemplateNameKey = 'email_template_name_key',
  /** unique or primary key constraint on columns "id" */
  EmailTemplatePkey = 'email_template_pkey'
}

/** input type for inserting data into table "template_email" */
export type Template_Email_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postmark_template_alias?: InputMaybe<Scalars['String']['input']>;
  template_group?: InputMaybe<Template_Group_Obj_Rel_Insert_Input>;
  template_group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Template_Email_Max_Fields = {
  __typename?: 'template_email_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postmark_template_alias?: Maybe<Scalars['String']['output']>;
  template_group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Template_Email_Min_Fields = {
  __typename?: 'template_email_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postmark_template_alias?: Maybe<Scalars['String']['output']>;
  template_group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "template_email" */
export type Template_Email_Mutation_Response = {
  __typename?: 'template_email_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Email>;
};

/** on_conflict condition type for table "template_email" */
export type Template_Email_On_Conflict = {
  constraint: Template_Email_Constraint;
  update_columns?: Array<Template_Email_Update_Column>;
  where?: InputMaybe<Template_Email_Bool_Exp>;
};

/** Ordering options when selecting data from "template_email". */
export type Template_Email_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  postmark_template_alias?: InputMaybe<Order_By>;
  template_group?: InputMaybe<Template_Group_Order_By>;
  template_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: template_email */
export type Template_Email_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "template_email" */
export enum Template_Email_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PostmarkTemplateAlias = 'postmark_template_alias',
  /** column name */
  TemplateGroupId = 'template_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "template_email" */
export type Template_Email_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postmark_template_alias?: InputMaybe<Scalars['String']['input']>;
  template_group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "template_email" */
export type Template_Email_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Email_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Email_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Entity_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postmark_template_alias?: InputMaybe<Scalars['String']['input']>;
  template_group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "template_email" */
export enum Template_Email_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PostmarkTemplateAlias = 'postmark_template_alias',
  /** column name */
  TemplateGroupId = 'template_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Template_Email_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Email_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Email_Bool_Exp;
};

/** Gruppierung zur Auswahl im UI */
export type Template_Group = {
  __typename?: 'template_group';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "template_group" */
export type Template_Group_Aggregate = {
  __typename?: 'template_group_aggregate';
  aggregate?: Maybe<Template_Group_Aggregate_Fields>;
  nodes: Array<Template_Group>;
};

/** aggregate fields of "template_group" */
export type Template_Group_Aggregate_Fields = {
  __typename?: 'template_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Group_Max_Fields>;
  min?: Maybe<Template_Group_Min_Fields>;
};


/** aggregate fields of "template_group" */
export type Template_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "template_group". All fields are combined with a logical 'AND'. */
export type Template_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Group_Bool_Exp>>;
  _not?: InputMaybe<Template_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Group_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "template_group" */
export enum Template_Group_Constraint {
  /** unique or primary key constraint on columns "name" */
  TemplateGroupNameKey = 'template_group_name_key',
  /** unique or primary key constraint on columns "id" */
  TemplateGroupPkey = 'template_group_pkey'
}

/** input type for inserting data into table "template_group" */
export type Template_Group_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Template_Group_Max_Fields = {
  __typename?: 'template_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Template_Group_Min_Fields = {
  __typename?: 'template_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "template_group" */
export type Template_Group_Mutation_Response = {
  __typename?: 'template_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Group>;
};

/** input type for inserting object relation for remote table "template_group" */
export type Template_Group_Obj_Rel_Insert_Input = {
  data: Template_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Group_On_Conflict>;
};

/** on_conflict condition type for table "template_group" */
export type Template_Group_On_Conflict = {
  constraint: Template_Group_Constraint;
  update_columns?: Array<Template_Group_Update_Column>;
  where?: InputMaybe<Template_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "template_group". */
export type Template_Group_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: template_group */
export type Template_Group_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "template_group" */
export enum Template_Group_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "template_group" */
export type Template_Group_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "template_group" */
export type Template_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Group_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "template_group" */
export enum Template_Group_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Template_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Group_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Einheiten für Produkte */
export type Unit = {
  __typename?: 'unit';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "unit" */
export type Unit_Aggregate = {
  __typename?: 'unit_aggregate';
  aggregate?: Maybe<Unit_Aggregate_Fields>;
  nodes: Array<Unit>;
};

/** aggregate fields of "unit" */
export type Unit_Aggregate_Fields = {
  __typename?: 'unit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Unit_Max_Fields>;
  min?: Maybe<Unit_Min_Fields>;
};


/** aggregate fields of "unit" */
export type Unit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "unit". All fields are combined with a logical 'AND'. */
export type Unit_Bool_Exp = {
  _and?: InputMaybe<Array<Unit_Bool_Exp>>;
  _not?: InputMaybe<Unit_Bool_Exp>;
  _or?: InputMaybe<Array<Unit_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "unit" */
export enum Unit_Constraint {
  /** unique or primary key constraint on columns "name" */
  UnitNameKey = 'unit_name_key',
  /** unique or primary key constraint on columns "id" */
  UnitPkey = 'unit_pkey'
}

/** input type for inserting data into table "unit" */
export type Unit_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Unit_Max_Fields = {
  __typename?: 'unit_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Unit_Min_Fields = {
  __typename?: 'unit_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "unit" */
export type Unit_Mutation_Response = {
  __typename?: 'unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Unit>;
};

/** input type for inserting object relation for remote table "unit" */
export type Unit_Obj_Rel_Insert_Input = {
  data: Unit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Unit_On_Conflict>;
};

/** on_conflict condition type for table "unit" */
export type Unit_On_Conflict = {
  constraint: Unit_Constraint;
  update_columns?: Array<Unit_Update_Column>;
  where?: InputMaybe<Unit_Bool_Exp>;
};

/** Ordering options when selecting data from "unit". */
export type Unit_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: unit */
export type Unit_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "unit" */
export enum Unit_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "unit" */
export type Unit_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "unit" */
export type Unit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unit_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "unit" */
export enum Unit_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Unit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unit_Bool_Exp;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  __typename?: 'users';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole: Scalars['String']['output'];
  /** An object relationship */
  defaultRoleByRole: AuthRoles;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['citext']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  isAnonymous: Scalars['Boolean']['output'];
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale: Scalars['String']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt: Scalars['timestamptz']['output'];
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokens_Aggregate;
  /** An array relationship */
  roles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  roles_aggregate: AuthUserRoles_Aggregate;
  /** An array relationship */
  securityKeys: Array<AuthUserSecurityKeys>;
  /** An aggregate relationship */
  securityKeys_aggregate: AuthUserSecurityKeys_Aggregate;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt: Scalars['timestamptz']['output'];
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProviders_Aggregate;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** aggregated selection of "auth.users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "auth.users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  activeMfaType?: InputMaybe<String_Comparison_Exp>;
  avatarUrl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currentChallenge?: InputMaybe<String_Comparison_Exp>;
  defaultRole?: InputMaybe<String_Comparison_Exp>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Bool_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  emailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isAnonymous?: InputMaybe<Boolean_Comparison_Exp>;
  lastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  newEmail?: InputMaybe<Citext_Comparison_Exp>;
  otpHash?: InputMaybe<String_Comparison_Exp>;
  otpHashExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  otpMethodLastUsed?: InputMaybe<String_Comparison_Exp>;
  passwordHash?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  phoneNumberVerified?: InputMaybe<Boolean_Comparison_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  roles?: InputMaybe<AuthUserRoles_Bool_Exp>;
  roles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp>;
  securityKeys?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeys_Aggregate_Bool_Exp>;
  ticket?: InputMaybe<String_Comparison_Exp>;
  ticketExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  totpSecret?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userProviders?: InputMaybe<AuthUserProviders_Bool_Exp>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.users" */
export type Users_Insert_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Obj_Rel_Insert_Input>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<AuthUserRoles_Arr_Rel_Insert_Input>;
  securityKeys?: InputMaybe<AuthUserSecurityKeys_Arr_Rel_Insert_Input>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<AuthUserProviders_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "auth.users" */
export type Users_Max_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "auth.users" */
export type Users_Min_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "auth.users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "auth.users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Users_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Order_By>;
  disabled?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailVerified?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isAnonymous?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  phoneNumberVerified?: InputMaybe<Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Order_By>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeys_Aggregate_Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.users" */
export enum Users_Select_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified'
}

/** input type for updating data in table "auth.users" */
export type Users_Set_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "auth.users" */
export enum Users_Update_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "value_type" */
export type Value_Type = {
  __typename?: 'value_type';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "value_type" */
export type Value_Type_Aggregate = {
  __typename?: 'value_type_aggregate';
  aggregate?: Maybe<Value_Type_Aggregate_Fields>;
  nodes: Array<Value_Type>;
};

/** aggregate fields of "value_type" */
export type Value_Type_Aggregate_Fields = {
  __typename?: 'value_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Value_Type_Max_Fields>;
  min?: Maybe<Value_Type_Min_Fields>;
};


/** aggregate fields of "value_type" */
export type Value_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Value_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "value_type". All fields are combined with a logical 'AND'. */
export type Value_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Value_Type_Bool_Exp>>;
  _not?: InputMaybe<Value_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Value_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "value_type" */
export enum Value_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ValueTypePkey = 'value_type_pkey'
}

export enum Value_Type_Enum {
  Number = 'number',
  Percent = 'percent'
}

/** Boolean expression to compare columns of type "value_type_enum". All fields are combined with logical 'AND'. */
export type Value_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Value_Type_Enum>;
  _in?: InputMaybe<Array<Value_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Value_Type_Enum>;
  _nin?: InputMaybe<Array<Value_Type_Enum>>;
};

/** input type for inserting data into table "value_type" */
export type Value_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Value_Type_Max_Fields = {
  __typename?: 'value_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Value_Type_Min_Fields = {
  __typename?: 'value_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "value_type" */
export type Value_Type_Mutation_Response = {
  __typename?: 'value_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Value_Type>;
};

/** on_conflict condition type for table "value_type" */
export type Value_Type_On_Conflict = {
  constraint: Value_Type_Constraint;
  update_columns?: Array<Value_Type_Update_Column>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "value_type". */
export type Value_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: value_type */
export type Value_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "value_type" */
export enum Value_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "value_type" */
export type Value_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "value_type" */
export type Value_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Value_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Value_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "value_type" */
export enum Value_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Value_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Value_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Value_Type_Bool_Exp;
};

/** columns and relationships of "vehicle" */
export type Vehicle = {
  __typename?: 'vehicle';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  foil_applied?: Maybe<Scalars['Boolean']['output']>;
  fuel: Fuel_Enum;
  has_coupling_device?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_leasing?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_source?: Maybe<Scalars['String']['output']>;
  license_plate: Scalars['String']['output'];
  /** An array relationship */
  log_book_entries: Array<Log_Book>;
  /** An aggregate relationship */
  log_book_entries_aggregate: Log_Book_Aggregate;
  notes?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vehicle_type: Vehicle_Type;
  vehicle_type_id: Scalars['uuid']['output'];
};


/** columns and relationships of "vehicle" */
export type VehicleLog_Book_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Log_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Log_Book_Order_By>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};


/** columns and relationships of "vehicle" */
export type VehicleLog_Book_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Log_Book_Order_By>>;
  where?: InputMaybe<Log_Book_Bool_Exp>;
};

/** aggregated selection of "vehicle" */
export type Vehicle_Aggregate = {
  __typename?: 'vehicle_aggregate';
  aggregate?: Maybe<Vehicle_Aggregate_Fields>;
  nodes: Array<Vehicle>;
};

export type Vehicle_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Vehicle_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Vehicle_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Vehicle_Aggregate_Bool_Exp_Count>;
};

export type Vehicle_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vehicle_Select_Column_Vehicle_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vehicle_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicle_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vehicle_Select_Column_Vehicle_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vehicle_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicle_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vehicle_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vehicle_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vehicle" */
export type Vehicle_Aggregate_Fields = {
  __typename?: 'vehicle_aggregate_fields';
  avg?: Maybe<Vehicle_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vehicle_Max_Fields>;
  min?: Maybe<Vehicle_Min_Fields>;
  stddev?: Maybe<Vehicle_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Variance_Fields>;
};


/** aggregate fields of "vehicle" */
export type Vehicle_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vehicle" */
export type Vehicle_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Max_Order_By>;
  min?: InputMaybe<Vehicle_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle" */
export type Vehicle_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicle_Avg_Fields = {
  __typename?: 'vehicle_avg_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "vehicle" */
export type Vehicle_Avg_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle". All fields are combined with a logical 'AND'. */
export type Vehicle_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  doors?: InputMaybe<Int_Comparison_Exp>;
  foil_applied?: InputMaybe<Boolean_Comparison_Exp>;
  fuel?: InputMaybe<Fuel_Enum_Comparison_Exp>;
  has_coupling_device?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_leasing?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  legacy_source?: InputMaybe<String_Comparison_Exp>;
  license_plate?: InputMaybe<String_Comparison_Exp>;
  log_book_entries?: InputMaybe<Log_Book_Bool_Exp>;
  log_book_entries_aggregate?: InputMaybe<Log_Book_Aggregate_Bool_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle_type?: InputMaybe<Vehicle_Type_Bool_Exp>;
  vehicle_type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "vehicle_category" */
export type Vehicle_Category = {
  __typename?: 'vehicle_category';
  base_category: Base_Category_Enum;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_group?: Maybe<Scalars['Boolean']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sort_order: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  vehicle_type_categories: Array<Vehicle_Type_Categories>;
  /** An aggregate relationship */
  vehicle_type_categories_aggregate: Vehicle_Type_Categories_Aggregate;
};


/** columns and relationships of "vehicle_category" */
export type Vehicle_CategoryVehicle_Type_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


/** columns and relationships of "vehicle_category" */
export type Vehicle_CategoryVehicle_Type_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};

/** aggregated selection of "vehicle_category" */
export type Vehicle_Category_Aggregate = {
  __typename?: 'vehicle_category_aggregate';
  aggregate?: Maybe<Vehicle_Category_Aggregate_Fields>;
  nodes: Array<Vehicle_Category>;
};

/** aggregate fields of "vehicle_category" */
export type Vehicle_Category_Aggregate_Fields = {
  __typename?: 'vehicle_category_aggregate_fields';
  avg?: Maybe<Vehicle_Category_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vehicle_Category_Max_Fields>;
  min?: Maybe<Vehicle_Category_Min_Fields>;
  stddev?: Maybe<Vehicle_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Category_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Category_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Category_Variance_Fields>;
};


/** aggregate fields of "vehicle_category" */
export type Vehicle_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Vehicle_Category_Avg_Fields = {
  __typename?: 'vehicle_category_avg_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "vehicle_category". All fields are combined with a logical 'AND'. */
export type Vehicle_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Category_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Category_Bool_Exp>>;
  base_category?: InputMaybe<Base_Category_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_group?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle_type_categories?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
  vehicle_type_categories_aggregate?: InputMaybe<Vehicle_Type_Categories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vehicle_category" */
export enum Vehicle_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleCategoryPkey = 'vehicle_category_pkey'
}

/** input type for incrementing numeric columns in table "vehicle_category" */
export type Vehicle_Category_Inc_Input = {
  sort_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "vehicle_category" */
export type Vehicle_Category_Insert_Input = {
  base_category?: InputMaybe<Base_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_group?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_categories?: InputMaybe<Vehicle_Type_Categories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vehicle_Category_Max_Fields = {
  __typename?: 'vehicle_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Vehicle_Category_Min_Fields = {
  __typename?: 'vehicle_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "vehicle_category" */
export type Vehicle_Category_Mutation_Response = {
  __typename?: 'vehicle_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Category>;
};

/** input type for inserting object relation for remote table "vehicle_category" */
export type Vehicle_Category_Obj_Rel_Insert_Input = {
  data: Vehicle_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Category_On_Conflict>;
};

/** on_conflict condition type for table "vehicle_category" */
export type Vehicle_Category_On_Conflict = {
  constraint: Vehicle_Category_Constraint;
  update_columns?: Array<Vehicle_Category_Update_Column>;
  where?: InputMaybe<Vehicle_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_category". */
export type Vehicle_Category_Order_By = {
  base_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_group?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_categories_aggregate?: InputMaybe<Vehicle_Type_Categories_Aggregate_Order_By>;
};

/** primary key columns input for table: vehicle_category */
export type Vehicle_Category_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vehicle_category" */
export enum Vehicle_Category_Select_Column {
  /** column name */
  BaseCategory = 'base_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsGroup = 'is_group',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "vehicle_category" */
export type Vehicle_Category_Set_Input = {
  base_category?: InputMaybe<Base_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_group?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Vehicle_Category_Stddev_Fields = {
  __typename?: 'vehicle_category_stddev_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Category_Stddev_Pop_Fields = {
  __typename?: 'vehicle_category_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Category_Stddev_Samp_Fields = {
  __typename?: 'vehicle_category_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "vehicle_category" */
export type Vehicle_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Category_Stream_Cursor_Value_Input = {
  base_category?: InputMaybe<Base_Category_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_group?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Vehicle_Category_Sum_Fields = {
  __typename?: 'vehicle_category_sum_fields';
  sort_order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "vehicle_category" */
export enum Vehicle_Category_Update_Column {
  /** column name */
  BaseCategory = 'base_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsGroup = 'is_group',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Vehicle_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vehicle_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vehicle_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicle_Category_Var_Pop_Fields = {
  __typename?: 'vehicle_category_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Vehicle_Category_Var_Samp_Fields = {
  __typename?: 'vehicle_category_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Vehicle_Category_Variance_Fields = {
  __typename?: 'vehicle_category_variance_fields';
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "vehicle" */
export enum Vehicle_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehiclePkey = 'vehicle_pkey'
}

/** input type for incrementing numeric columns in table "vehicle" */
export type Vehicle_Inc_Input = {
  doors?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "vehicle" */
export type Vehicle_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  foil_applied?: InputMaybe<Scalars['Boolean']['input']>;
  fuel?: InputMaybe<Fuel_Enum>;
  has_coupling_device?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_leasing?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_source?: InputMaybe<Scalars['String']['input']>;
  license_plate?: InputMaybe<Scalars['String']['input']>;
  log_book_entries?: InputMaybe<Log_Book_Arr_Rel_Insert_Input>;
  notes?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Vehicle_Type_Obj_Rel_Insert_Input>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vehicle_Max_Fields = {
  __typename?: 'vehicle_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_source?: Maybe<Scalars['String']['output']>;
  license_plate?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vehicle" */
export type Vehicle_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  doors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_source?: InputMaybe<Order_By>;
  license_plate?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Min_Fields = {
  __typename?: 'vehicle_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  legacy_source?: Maybe<Scalars['String']['output']>;
  license_plate?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vehicle" */
export type Vehicle_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  doors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_source?: InputMaybe<Order_By>;
  license_plate?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle" */
export type Vehicle_Mutation_Response = {
  __typename?: 'vehicle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle>;
};

/** input type for inserting object relation for remote table "vehicle" */
export type Vehicle_Obj_Rel_Insert_Input = {
  data: Vehicle_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};

/** on_conflict condition type for table "vehicle" */
export type Vehicle_On_Conflict = {
  constraint: Vehicle_Constraint;
  update_columns?: Array<Vehicle_Update_Column>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle". */
export type Vehicle_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  doors?: InputMaybe<Order_By>;
  foil_applied?: InputMaybe<Order_By>;
  fuel?: InputMaybe<Order_By>;
  has_coupling_device?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_leasing?: InputMaybe<Order_By>;
  legacy_id?: InputMaybe<Order_By>;
  legacy_source?: InputMaybe<Order_By>;
  license_plate?: InputMaybe<Order_By>;
  log_book_entries_aggregate?: InputMaybe<Log_Book_Aggregate_Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type?: InputMaybe<Vehicle_Type_Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vehicle */
export type Vehicle_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vehicle" */
export enum Vehicle_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Doors = 'doors',
  /** column name */
  FoilApplied = 'foil_applied',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  HasCouplingDevice = 'has_coupling_device',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsLeasing = 'is_leasing',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacySource = 'legacy_source',
  /** column name */
  LicensePlate = 'license_plate',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

/** select "vehicle_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vehicle" */
export enum Vehicle_Select_Column_Vehicle_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FoilApplied = 'foil_applied',
  /** column name */
  HasCouplingDevice = 'has_coupling_device',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsLeasing = 'is_leasing'
}

/** select "vehicle_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vehicle" */
export enum Vehicle_Select_Column_Vehicle_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FoilApplied = 'foil_applied',
  /** column name */
  HasCouplingDevice = 'has_coupling_device',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsLeasing = 'is_leasing'
}

/** input type for updating data in table "vehicle" */
export type Vehicle_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  foil_applied?: InputMaybe<Scalars['Boolean']['input']>;
  fuel?: InputMaybe<Fuel_Enum>;
  has_coupling_device?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_leasing?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_source?: InputMaybe<Scalars['String']['input']>;
  license_plate?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Vehicle_Stddev_Fields = {
  __typename?: 'vehicle_stddev_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "vehicle" */
export type Vehicle_Stddev_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Stddev_Pop_Fields = {
  __typename?: 'vehicle_stddev_pop_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "vehicle" */
export type Vehicle_Stddev_Pop_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Stddev_Samp_Fields = {
  __typename?: 'vehicle_stddev_samp_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "vehicle" */
export type Vehicle_Stddev_Samp_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vehicle" */
export type Vehicle_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  foil_applied?: InputMaybe<Scalars['Boolean']['input']>;
  fuel?: InputMaybe<Fuel_Enum>;
  has_coupling_device?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_leasing?: InputMaybe<Scalars['Boolean']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  legacy_source?: InputMaybe<Scalars['String']['input']>;
  license_plate?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Vehicle_Sum_Fields = {
  __typename?: 'vehicle_sum_fields';
  doors?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "vehicle" */
export type Vehicle_Sum_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_Type = {
  __typename?: 'vehicle_type';
  abbreviation?: Maybe<Scalars['String']['output']>;
  base_category: Base_Category_Enum;
  cargo_height?: Maybe<Scalars['numeric']['output']>;
  cargo_length?: Maybe<Scalars['numeric']['output']>;
  cargo_max?: Maybe<Scalars['numeric']['output']>;
  cargo_width?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  gearshift: Gearshift_Enum;
  has_air_conditioner?: Maybe<Scalars['Boolean']['output']>;
  has_coupling_device?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  image?: Maybe<Files>;
  image_file_id?: Maybe<Scalars['uuid']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_internal?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  kilometer_fees: Array<Kilometer_Fee_Vehicle_Type>;
  /** An aggregate relationship */
  kilometer_fees_aggregate: Kilometer_Fee_Vehicle_Type_Aggregate;
  luggage_minor?: Maybe<Scalars['Int']['output']>;
  luggage_normal?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  price_definitions: Array<Price_Definition_Vehicle_Type>;
  /** An aggregate relationship */
  price_definitions_aggregate: Price_Definition_Vehicle_Type_Aggregate;
  seats?: Maybe<Scalars['Int']['output']>;
  sort_order: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  vehicle_type_categories: Array<Vehicle_Type_Categories>;
  /** An aggregate relationship */
  vehicle_type_categories_aggregate: Vehicle_Type_Categories_Aggregate;
  /** An array relationship */
  vehicles: Array<Vehicle>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicle_Aggregate;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeKilometer_FeesArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeKilometer_Fees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Kilometer_Fee_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypePrice_DefinitionsArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypePrice_Definitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Definition_Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Price_Definition_Vehicle_Type_Order_By>>;
  where?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehicle_Type_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehicle_Type_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Categories_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};


/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** aggregated selection of "vehicle_type" */
export type Vehicle_Type_Aggregate = {
  __typename?: 'vehicle_type_aggregate';
  aggregate?: Maybe<Vehicle_Type_Aggregate_Fields>;
  nodes: Array<Vehicle_Type>;
};

/** aggregate fields of "vehicle_type" */
export type Vehicle_Type_Aggregate_Fields = {
  __typename?: 'vehicle_type_aggregate_fields';
  avg?: Maybe<Vehicle_Type_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vehicle_Type_Max_Fields>;
  min?: Maybe<Vehicle_Type_Min_Fields>;
  stddev?: Maybe<Vehicle_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Type_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Type_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Type_Variance_Fields>;
};


/** aggregate fields of "vehicle_type" */
export type Vehicle_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Vehicle_Type_Avg_Fields = {
  __typename?: 'vehicle_type_avg_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "vehicle_type". All fields are combined with a logical 'AND'. */
export type Vehicle_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Type_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Type_Bool_Exp>>;
  abbreviation?: InputMaybe<String_Comparison_Exp>;
  base_category?: InputMaybe<Base_Category_Enum_Comparison_Exp>;
  cargo_height?: InputMaybe<Numeric_Comparison_Exp>;
  cargo_length?: InputMaybe<Numeric_Comparison_Exp>;
  cargo_max?: InputMaybe<Numeric_Comparison_Exp>;
  cargo_width?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  doors?: InputMaybe<Int_Comparison_Exp>;
  gearshift?: InputMaybe<Gearshift_Enum_Comparison_Exp>;
  has_air_conditioner?: InputMaybe<Boolean_Comparison_Exp>;
  has_coupling_device?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<Files_Bool_Exp>;
  image_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_internal?: InputMaybe<Boolean_Comparison_Exp>;
  kilometer_fees?: InputMaybe<Kilometer_Fee_Vehicle_Type_Bool_Exp>;
  kilometer_fees_aggregate?: InputMaybe<Kilometer_Fee_Vehicle_Type_Aggregate_Bool_Exp>;
  luggage_minor?: InputMaybe<Int_Comparison_Exp>;
  luggage_normal?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  price_definitions?: InputMaybe<Price_Definition_Vehicle_Type_Bool_Exp>;
  price_definitions_aggregate?: InputMaybe<Price_Definition_Vehicle_Type_Aggregate_Bool_Exp>;
  seats?: InputMaybe<Int_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle_type_categories?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
  vehicle_type_categories_aggregate?: InputMaybe<Vehicle_Type_Categories_Aggregate_Bool_Exp>;
  vehicles?: InputMaybe<Vehicle_Bool_Exp>;
  vehicles_aggregate?: InputMaybe<Vehicle_Aggregate_Bool_Exp>;
};

/** columns and relationships of "vehicle_type_categories" */
export type Vehicle_Type_Categories = {
  __typename?: 'vehicle_type_categories';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vehicle_category: Vehicle_Category;
  vehicle_category_id: Scalars['uuid']['output'];
  /** An object relationship */
  vehicle_type: Vehicle_Type;
  vehicle_type_id: Scalars['uuid']['output'];
};

/** aggregated selection of "vehicle_type_categories" */
export type Vehicle_Type_Categories_Aggregate = {
  __typename?: 'vehicle_type_categories_aggregate';
  aggregate?: Maybe<Vehicle_Type_Categories_Aggregate_Fields>;
  nodes: Array<Vehicle_Type_Categories>;
};

export type Vehicle_Type_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vehicle_Type_Categories_Aggregate_Bool_Exp_Count>;
};

export type Vehicle_Type_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vehicle_type_categories" */
export type Vehicle_Type_Categories_Aggregate_Fields = {
  __typename?: 'vehicle_type_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vehicle_Type_Categories_Max_Fields>;
  min?: Maybe<Vehicle_Type_Categories_Min_Fields>;
};


/** aggregate fields of "vehicle_type_categories" */
export type Vehicle_Type_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Type_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Type_Categories_Max_Order_By>;
  min?: InputMaybe<Vehicle_Type_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Type_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Type_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vehicle_type_categories". All fields are combined with a logical 'AND'. */
export type Vehicle_Type_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Vehicle_Type_Categories_Bool_Exp>>;
  _not?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Vehicle_Type_Categories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vehicle_category?: InputMaybe<Vehicle_Category_Bool_Exp>;
  vehicle_category_id?: InputMaybe<Uuid_Comparison_Exp>;
  vehicle_type?: InputMaybe<Vehicle_Type_Bool_Exp>;
  vehicle_type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle_type_categories" */
export enum Vehicle_Type_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleTypeCategoriesPkey = 'vehicle_type_categories_pkey',
  /** unique or primary key constraint on columns "vehicle_category_id", "vehicle_type_id" */
  VehicleTypeCategoriesVehicleTypeIdVehicleCategoryIdKey = 'vehicle_type_categories_vehicle_type_id_vehicle_category_id_key'
}

/** input type for inserting data into table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_category?: InputMaybe<Vehicle_Category_Obj_Rel_Insert_Input>;
  vehicle_category_id?: InputMaybe<Scalars['uuid']['input']>;
  vehicle_type?: InputMaybe<Vehicle_Type_Obj_Rel_Insert_Input>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vehicle_Type_Categories_Max_Fields = {
  __typename?: 'vehicle_type_categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_category_id?: Maybe<Scalars['uuid']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_category_id?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Type_Categories_Min_Fields = {
  __typename?: 'vehicle_type_categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vehicle_category_id?: Maybe<Scalars['uuid']['output']>;
  vehicle_type_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_category_id?: InputMaybe<Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Mutation_Response = {
  __typename?: 'vehicle_type_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Type_Categories>;
};

/** on_conflict condition type for table "vehicle_type_categories" */
export type Vehicle_Type_Categories_On_Conflict = {
  constraint: Vehicle_Type_Categories_Constraint;
  update_columns?: Array<Vehicle_Type_Categories_Update_Column>;
  where?: InputMaybe<Vehicle_Type_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_type_categories". */
export type Vehicle_Type_Categories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_category?: InputMaybe<Vehicle_Category_Order_By>;
  vehicle_category_id?: InputMaybe<Order_By>;
  vehicle_type?: InputMaybe<Vehicle_Type_Order_By>;
  vehicle_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vehicle_type_categories */
export type Vehicle_Type_Categories_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vehicle_type_categories" */
export enum Vehicle_Type_Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleCategoryId = 'vehicle_category_id',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

/** input type for updating data in table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_category_id?: InputMaybe<Scalars['uuid']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vehicle_type_categories" */
export type Vehicle_Type_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Type_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Type_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_category_id?: InputMaybe<Scalars['uuid']['input']>;
  vehicle_type_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vehicle_type_categories" */
export enum Vehicle_Type_Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleCategoryId = 'vehicle_category_id',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

export type Vehicle_Type_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vehicle_Type_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Type_Categories_Bool_Exp;
};

/** unique or primary key constraints on table "vehicle_type" */
export enum Vehicle_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehicleTypePkey = 'vehicle_type_pkey'
}

/** input type for incrementing numeric columns in table "vehicle_type" */
export type Vehicle_Type_Inc_Input = {
  cargo_height?: InputMaybe<Scalars['numeric']['input']>;
  cargo_length?: InputMaybe<Scalars['numeric']['input']>;
  cargo_max?: InputMaybe<Scalars['numeric']['input']>;
  cargo_width?: InputMaybe<Scalars['numeric']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  luggage_minor?: InputMaybe<Scalars['Int']['input']>;
  luggage_normal?: InputMaybe<Scalars['Int']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "vehicle_type" */
export type Vehicle_Type_Insert_Input = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  base_category?: InputMaybe<Base_Category_Enum>;
  cargo_height?: InputMaybe<Scalars['numeric']['input']>;
  cargo_length?: InputMaybe<Scalars['numeric']['input']>;
  cargo_max?: InputMaybe<Scalars['numeric']['input']>;
  cargo_width?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  gearshift?: InputMaybe<Gearshift_Enum>;
  has_air_conditioner?: InputMaybe<Scalars['Boolean']['input']>;
  has_coupling_device?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  image_file_id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  kilometer_fees?: InputMaybe<Kilometer_Fee_Vehicle_Type_Arr_Rel_Insert_Input>;
  luggage_minor?: InputMaybe<Scalars['Int']['input']>;
  luggage_normal?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  price_definitions?: InputMaybe<Price_Definition_Vehicle_Type_Arr_Rel_Insert_Input>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  vehicle_type_categories?: InputMaybe<Vehicle_Type_Categories_Arr_Rel_Insert_Input>;
  vehicles?: InputMaybe<Vehicle_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vehicle_Type_Max_Fields = {
  __typename?: 'vehicle_type_max_fields';
  abbreviation?: Maybe<Scalars['String']['output']>;
  cargo_height?: Maybe<Scalars['numeric']['output']>;
  cargo_length?: Maybe<Scalars['numeric']['output']>;
  cargo_max?: Maybe<Scalars['numeric']['output']>;
  cargo_width?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image_file_id?: Maybe<Scalars['uuid']['output']>;
  luggage_minor?: Maybe<Scalars['Int']['output']>;
  luggage_normal?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Vehicle_Type_Min_Fields = {
  __typename?: 'vehicle_type_min_fields';
  abbreviation?: Maybe<Scalars['String']['output']>;
  cargo_height?: Maybe<Scalars['numeric']['output']>;
  cargo_length?: Maybe<Scalars['numeric']['output']>;
  cargo_max?: Maybe<Scalars['numeric']['output']>;
  cargo_width?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image_file_id?: Maybe<Scalars['uuid']['output']>;
  luggage_minor?: Maybe<Scalars['Int']['output']>;
  luggage_normal?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "vehicle_type" */
export type Vehicle_Type_Mutation_Response = {
  __typename?: 'vehicle_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle_Type>;
};

/** input type for inserting object relation for remote table "vehicle_type" */
export type Vehicle_Type_Obj_Rel_Insert_Input = {
  data: Vehicle_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vehicle_Type_On_Conflict>;
};

/** on_conflict condition type for table "vehicle_type" */
export type Vehicle_Type_On_Conflict = {
  constraint: Vehicle_Type_Constraint;
  update_columns?: Array<Vehicle_Type_Update_Column>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicle_type". */
export type Vehicle_Type_Order_By = {
  abbreviation?: InputMaybe<Order_By>;
  base_category?: InputMaybe<Order_By>;
  cargo_height?: InputMaybe<Order_By>;
  cargo_length?: InputMaybe<Order_By>;
  cargo_max?: InputMaybe<Order_By>;
  cargo_width?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  doors?: InputMaybe<Order_By>;
  gearshift?: InputMaybe<Order_By>;
  has_air_conditioner?: InputMaybe<Order_By>;
  has_coupling_device?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Files_Order_By>;
  image_file_id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_internal?: InputMaybe<Order_By>;
  kilometer_fees_aggregate?: InputMaybe<Kilometer_Fee_Vehicle_Type_Aggregate_Order_By>;
  luggage_minor?: InputMaybe<Order_By>;
  luggage_normal?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  price_definitions_aggregate?: InputMaybe<Price_Definition_Vehicle_Type_Aggregate_Order_By>;
  seats?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vehicle_type_categories_aggregate?: InputMaybe<Vehicle_Type_Categories_Aggregate_Order_By>;
  vehicles_aggregate?: InputMaybe<Vehicle_Aggregate_Order_By>;
};

/** primary key columns input for table: vehicle_type */
export type Vehicle_Type_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vehicle_type" */
export enum Vehicle_Type_Select_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  BaseCategory = 'base_category',
  /** column name */
  CargoHeight = 'cargo_height',
  /** column name */
  CargoLength = 'cargo_length',
  /** column name */
  CargoMax = 'cargo_max',
  /** column name */
  CargoWidth = 'cargo_width',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Doors = 'doors',
  /** column name */
  Gearshift = 'gearshift',
  /** column name */
  HasAirConditioner = 'has_air_conditioner',
  /** column name */
  HasCouplingDevice = 'has_coupling_device',
  /** column name */
  Id = 'id',
  /** column name */
  ImageFileId = 'image_file_id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  LuggageMinor = 'luggage_minor',
  /** column name */
  LuggageNormal = 'luggage_normal',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Seats = 'seats',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "vehicle_type" */
export type Vehicle_Type_Set_Input = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  base_category?: InputMaybe<Base_Category_Enum>;
  cargo_height?: InputMaybe<Scalars['numeric']['input']>;
  cargo_length?: InputMaybe<Scalars['numeric']['input']>;
  cargo_max?: InputMaybe<Scalars['numeric']['input']>;
  cargo_width?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  gearshift?: InputMaybe<Gearshift_Enum>;
  has_air_conditioner?: InputMaybe<Scalars['Boolean']['input']>;
  has_coupling_device?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image_file_id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  luggage_minor?: InputMaybe<Scalars['Int']['input']>;
  luggage_normal?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Vehicle_Type_Stddev_Fields = {
  __typename?: 'vehicle_type_stddev_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Type_Stddev_Pop_Fields = {
  __typename?: 'vehicle_type_stddev_pop_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Type_Stddev_Samp_Fields = {
  __typename?: 'vehicle_type_stddev_samp_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "vehicle_type" */
export type Vehicle_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicle_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicle_Type_Stream_Cursor_Value_Input = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  base_category?: InputMaybe<Base_Category_Enum>;
  cargo_height?: InputMaybe<Scalars['numeric']['input']>;
  cargo_length?: InputMaybe<Scalars['numeric']['input']>;
  cargo_max?: InputMaybe<Scalars['numeric']['input']>;
  cargo_width?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  doors?: InputMaybe<Scalars['Int']['input']>;
  gearshift?: InputMaybe<Gearshift_Enum>;
  has_air_conditioner?: InputMaybe<Scalars['Boolean']['input']>;
  has_coupling_device?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image_file_id?: InputMaybe<Scalars['uuid']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  luggage_minor?: InputMaybe<Scalars['Int']['input']>;
  luggage_normal?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Vehicle_Type_Sum_Fields = {
  __typename?: 'vehicle_type_sum_fields';
  cargo_height?: Maybe<Scalars['numeric']['output']>;
  cargo_length?: Maybe<Scalars['numeric']['output']>;
  cargo_max?: Maybe<Scalars['numeric']['output']>;
  cargo_width?: Maybe<Scalars['numeric']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  luggage_minor?: Maybe<Scalars['Int']['output']>;
  luggage_normal?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "vehicle_type" */
export enum Vehicle_Type_Update_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  BaseCategory = 'base_category',
  /** column name */
  CargoHeight = 'cargo_height',
  /** column name */
  CargoLength = 'cargo_length',
  /** column name */
  CargoMax = 'cargo_max',
  /** column name */
  CargoWidth = 'cargo_width',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Doors = 'doors',
  /** column name */
  Gearshift = 'gearshift',
  /** column name */
  HasAirConditioner = 'has_air_conditioner',
  /** column name */
  HasCouplingDevice = 'has_coupling_device',
  /** column name */
  Id = 'id',
  /** column name */
  ImageFileId = 'image_file_id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  LuggageMinor = 'luggage_minor',
  /** column name */
  LuggageNormal = 'luggage_normal',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Seats = 'seats',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Vehicle_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vehicle_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vehicle_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicle_Type_Var_Pop_Fields = {
  __typename?: 'vehicle_type_var_pop_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Vehicle_Type_Var_Samp_Fields = {
  __typename?: 'vehicle_type_var_samp_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Vehicle_Type_Variance_Fields = {
  __typename?: 'vehicle_type_variance_fields';
  cargo_height?: Maybe<Scalars['Float']['output']>;
  cargo_length?: Maybe<Scalars['Float']['output']>;
  cargo_max?: Maybe<Scalars['Float']['output']>;
  cargo_width?: Maybe<Scalars['Float']['output']>;
  doors?: Maybe<Scalars['Float']['output']>;
  luggage_minor?: Maybe<Scalars['Float']['output']>;
  luggage_normal?: Maybe<Scalars['Float']['output']>;
  seats?: Maybe<Scalars['Float']['output']>;
  sort_order?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "vehicle" */
export enum Vehicle_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Doors = 'doors',
  /** column name */
  FoilApplied = 'foil_applied',
  /** column name */
  Fuel = 'fuel',
  /** column name */
  HasCouplingDevice = 'has_coupling_device',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsLeasing = 'is_leasing',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacySource = 'legacy_source',
  /** column name */
  LicensePlate = 'license_plate',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VehicleTypeId = 'vehicle_type_id'
}

export type Vehicle_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vehicle_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vehicle_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicle_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicle_Var_Pop_Fields = {
  __typename?: 'vehicle_var_pop_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "vehicle" */
export type Vehicle_Var_Pop_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Var_Samp_Fields = {
  __typename?: 'vehicle_var_samp_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "vehicle" */
export type Vehicle_Var_Samp_Order_By = {
  doors?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Variance_Fields = {
  __typename?: 'vehicle_variance_fields';
  doors?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "vehicle" */
export type Vehicle_Variance_Order_By = {
  doors?: InputMaybe<Order_By>;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'query_root', country: Array<{ __typename?: 'country', id: string, name: string }> };

export type GetContactByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetContactByEmailQuery = { __typename?: 'query_root', contact: Array<{ __typename?: 'contact', id: string }>, contact_aggregate: { __typename?: 'contact_aggregate', aggregate?: { __typename?: 'contact_aggregate_fields', count: number } | null } };

export type InsertRentalContractMutationVariables = Exact<{
  rental_contract: Rental_Contract_Insert_Input;
}>;


export type InsertRentalContractMutation = { __typename?: 'mutation_root', insert_rental_contract_one?: { __typename?: 'rental_contract', id: string } | null };

export type InsertContactMutationVariables = Exact<{
  contact: Contact_Insert_Input;
}>;


export type InsertContactMutation = { __typename?: 'mutation_root', insert_contact_one?: { __typename?: 'contact', id: string } | null };

export type GetProductsForBaseCategoryQueryVariables = Exact<{
  check_date: Scalars['date']['input'];
  base_category: Base_Category_Enum;
}>;


export type GetProductsForBaseCategoryQuery = { __typename?: 'query_root', discounts: Array<{ __typename?: 'product', id: string, name: string, value: number, is_default?: boolean | null, is_active?: boolean | null, is_selectable?: boolean | null }>, insurances: Array<{ __typename?: 'product', id: string, name: string, value: number, secondary_value: number, is_default?: boolean | null }>, accessories: Array<{ __typename?: 'product', id: string, name: string, value: number, is_default?: boolean | null, allow_amount?: boolean | null }> };

export type GetFilterDataQueryVariables = Exact<{
  base_category: Base_Category_Enum;
}>;


export type GetFilterDataQuery = { __typename?: 'query_root', vehicle_category: Array<{ __typename?: 'vehicle_category', id: string, name: string }>, gearshift: Array<{ __typename?: 'gearshift', value: string }>, vehicle: Array<{ __typename?: 'vehicle', fuel: Fuel_Enum }> };

export type VehicleTypesQueryVariables = Exact<{
  base_category: Base_Category_Enum;
}>;


export type VehicleTypesQuery = { __typename?: 'query_root', vehicle_type: Array<{ __typename?: 'vehicle_type', id: string, name: string, seats?: number | null, luggage_normal?: number | null, luggage_minor?: number | null, gearshift: Gearshift_Enum, doors?: number | null, has_air_conditioner?: boolean | null, cargo_max?: number | null, cargo_length?: number | null, cargo_height?: number | null, cargo_width?: number | null, image_file_id?: string | null, vehicle_type_categories: Array<{ __typename?: 'vehicle_type_categories', vehicle_category: { __typename?: 'vehicle_category', id: string, name: string, is_group?: boolean | null } }>, vehicles: Array<{ __typename?: 'vehicle', fuel: Fuel_Enum }> }> };

export type CalculatePriceReferenceDataQueryVariables = Exact<{
  vehicle_type_id: Scalars['uuid']['input'];
  insurance_id: Scalars['uuid']['input'];
}>;


export type CalculatePriceReferenceDataQuery = { __typename?: 'query_root', vehicle_type_by_pk?: { __typename?: 'vehicle_type', id: string, kilometer_fees: Array<{ __typename?: 'kilometer_fee_vehicle_type', kilometer_fee: { __typename?: 'kilometer_fee', valid_to?: string | null, valid_from?: string | null, sort_order: number, entries: Array<{ __typename?: 'kilometer_fee_entry', kilometer: number, value: number }> } }>, vehicle_type_categories: Array<{ __typename?: 'vehicle_type_categories', vehicle_category: { __typename?: 'vehicle_category', base_category: Base_Category_Enum } }>, price_definitions: Array<{ __typename?: 'price_definition_vehicle_type', price_definition: { __typename?: 'price_definition', valid_to?: string | null, valid_from?: string | null, sort_order: number, entries: Array<{ __typename?: 'price_definition_entry', value: number, included_kilometer: number, days?: number | null }> } }> } | null, insurance?: { __typename?: 'product', id: string, value: number, value_type: Value_Type_Enum, secondary_value: number } | null };

export type CalculatePriceProductQueryQueryVariables = Exact<{
  where: Product_Bool_Exp;
}>;


export type CalculatePriceProductQueryQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: string, value: number, value_type: Value_Type_Enum, product_type: Product_Type_Enum }> };

export type GetVehicleTypeByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetVehicleTypeByIdQuery = { __typename?: 'query_root', vehicle_type_by_pk?: { __typename?: 'vehicle_type', id: string, name: string, is_active?: boolean | null, seats?: number | null, luggage_normal?: number | null, luggage_minor?: number | null, gearshift: Gearshift_Enum, doors?: number | null, has_air_conditioner?: boolean | null, cargo_max?: number | null, cargo_length?: number | null, cargo_height?: number | null, cargo_width?: number | null, image_file_id?: string | null, vehicle_type_categories: Array<{ __typename?: 'vehicle_type_categories', vehicle_category: { __typename?: 'vehicle_category', id: string, name: string, base_category: Base_Category_Enum } }>, vehicles: Array<{ __typename?: 'vehicle', fuel: Fuel_Enum }> } | null };

export type RentalContractDataFormEmailQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type RentalContractDataFormEmailQuery = { __typename?: 'query_root', rental_contract_by_pk?: { __typename?: 'rental_contract', id: string, from: string, expected_to?: string | null, is_ride_to_foreign_country?: boolean | null, calculation_duration?: number | null, calculation_total_all_round_price?: number | null, terms_checked: boolean, newsletter?: boolean | null, request_note?: string | null, primary_contact?: { __typename?: 'contact', id: string, person_firstname?: string | null, person_lastname?: string | null, address_street?: string | null, address_zipcode?: string | null, address_city?: string | null, email?: string | null, mobile?: string | null, person_dateofbirth?: string | null, address_country?: { __typename?: 'country', id: string, name: string } | null } | null, requested_vehicle_type?: { __typename?: 'vehicle_type', id: string, name: string } | null, insurance?: { __typename?: 'product', id: string, name: string } | null, discount?: { __typename?: 'product', id: string, name: string } | null, foreign_country?: { __typename?: 'country', id: string, name: string } | null, accessories: Array<{ __typename?: 'rental_contract_accessory', id: string, amount?: number | null, product: { __typename?: 'product', id: string, name: string } }> } | null };
